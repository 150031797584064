export default {
  start_date: {
    label: 'Start Date',
    type: 'date',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  end_date: {
    label: 'End Date',
    type: 'date',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  leave_type: {
    label: 'Leave Type',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      {label: 'LOP', value: 'lop'},
    ],
    isMultiSelect: false
  },
  hours: {
    label: 'No of Hours',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4, min: 4},
  },
  reason: {
    label: 'Reason',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
}
