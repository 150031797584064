import React, { useState, useEffect, useMemo } from 'react'
import { PINNED_ANALYTICS } from 'services/url'
import { BaseChart } from 'sections/chart'
import { Grid, Stack, Tabs, Tab, Box } from '@mui/material'
import useAxios from 'hooks/useAxios'
import QuickAccessComponent from './QuickAccess'

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState('quickAccess')
  const [charts, setCharts] = useState(null)

  const analyticRequest = useAxios()

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(() => {
    if (analyticRequest.data) {
      setCharts(analyticRequest.data)
    } else if (analyticRequest.error) {
      console.error('Something is not correct with Analytic')
    }
  }, [analyticRequest.data, analyticRequest.error])

  useEffect(() => {
    analyticRequest.sendRequest(PINNED_ANALYTICS)
  }, [])

  const tabStyles = useMemo(() => ({
    textAlign: 'center',
    borderRadius: 1,
    border: '1px solid',
    minWidth: '50%',
    fontSize: '17px',
    '&:hover': {
      bgcolor: 'grey.300',
    }
  }), [])

  return (
    <Box sx={{ borderRadius: 2, display: 'flex', justifyContent: 'center', backgroundColor: 'white', p: 1 }}>
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            label="Quick Access"
            value="quickAccess"
            sx={{
              ...tabStyles,
              bgcolor: activeTab === 'quickAccess' ? 'primary.main' : 'transparent',
              color: activeTab === 'quickAccess' ? 'white !important' : 'black',
              borderColor: activeTab === 'quickAccess' ? 'primary.main' : 'grey.300',
            }}
          />
          <Tab
            label="Statistics"
            value="statistics"
            sx={{
              ...tabStyles,
              bgcolor: activeTab === 'statistics' ? 'primary.main' : 'transparent',
              color: activeTab === 'statistics' ? 'white !important' : 'black',
              borderColor: activeTab === 'statistics' ? 'primary.main' : 'grey.300',
            }}
          />
        </Tabs>
        <Stack>
          {activeTab === 'quickAccess' && <QuickAccessComponent />}
          {activeTab === 'statistics' && (
            <Grid container spacing={1}>
              {charts && charts.map((value) => (
                <Grid key={value.id} item xs={12} md={6}>
                  <BaseChart id={value.id} />
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default TabsComponent
