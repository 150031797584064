export default {
  title: {
    label: 'Title',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  approved_by: {
    label: 'Approved By',
    type: 'string',
    isRequired: false,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  recommended_by: {
    label: 'Recommended By',
    type: 'string',
    isRequired: false,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  rejected_by: {
    label: 'Rejected By',
    type: 'string',
    isRequired: false,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  category: {
    label: 'Category',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'Software',
        value: 'software',
      },
      {
        label: 'Hardware',
        value: 'hardware',
      },
      {
        label: 'Others',
        value: 'others',
      },
    ],
    isMultiSelect: false,
  },
  reason: {
    label: 'Reason',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  rate: {
    label: 'Rate',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
}
