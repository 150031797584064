import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export function listClient() {
  return makeAPIRequest('/clients', buildOptions(), true)
}

export function pullJdOption() {
  return makeAPIRequest('/jd-requirements/active', buildOptions(), true)
}
