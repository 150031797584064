import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export function createAnalytic(body) {
  return makeAPIRequest('/analytic/create', buildOptions('POST', body))
}

export function updateAnalyticById(id, body) {
  return makeAPIRequest(`/analytic/${id}`, buildOptions('PUT', body))
}

export function getCohortList() {
  return makeAPIRequest('/analytic/cohort', buildOptions())
}

export function getCohortDataList(cohort) {
  return makeAPIRequest(`/analytic/cohort/${cohort}`, buildOptions())
}

export function getBreakDownList(breakDown) {
  return makeAPIRequest(`/analytic/break-down/${breakDown}`, buildOptions())
}

export function getFilterValueList(table, column) {
  return makeAPIRequest(`/analytic/${table}/${column}`, buildOptions())
}
