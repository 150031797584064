import { useSearchParams } from 'react-router-dom'

const useQueryParamsState = (initialState) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getQueryParamsState = () => {
    const newState = {}
    Object.keys(initialState).forEach((key) => {
      newState[key] = searchParams.get(key) ?? initialState[key]
    })
    return newState
  }

  const state = getQueryParamsState()

  const updateState = (updater) => {
    const updatedParams = new URLSearchParams(searchParams)
    const currentState = getQueryParamsState()

    let newState
    if (typeof updater === 'function') {
      newState = updater(currentState)
    } else {
      newState = { ...currentState, ...updater }
    }

    Object.keys(newState).forEach((key) => {
      updatedParams.set(key, newState[key])
    })

    setSearchParams(updatedParams, { replace: true })
  }

  return [state, updateState]
}

export default useQueryParamsState
