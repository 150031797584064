import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
// @mui
import { Card, CardHeader, Box } from '@mui/material'
// components
import { useChart } from '../../components/chart'

// ----------------------------------------------------------------------

LineChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
}

export default function LineChart({ title, subheader, chartData, ...other }) {
  const chartLabels = chartData.map((i) => i.label?.replace(/_/g, ' ').toUpperCase())
  const chartValue = chartData.map((i) => i.data)

  const [isValidChart, setIsValidChart] = useState(true)
  const [data, setData] = useState(null)

  const isValidDate = (dateString) => {
    const parsedDate = new Date(dateString)
    return !Number.isNaN(parsedDate.getTime())
  }

  useEffect(() => {
    const valid = chartLabels.map((dateString) => isValidDate(dateString))
    setIsValidChart(!valid.some((value) => !value))
  }, [chartLabels])
  useEffect(() => {
    const d = [
      {
        name: chartData[0]?.name,
        type: chartData[0]?.type,
        fill: chartData[0]?.fill,
        data: chartValue,
      },
    ]
    setData(d)
  }, [])

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`
          }
          return y
        },
      },
    },
  })

  return (
    <>
      {data && isValidChart ? (
        <Card {...other}>
          <CardHeader title={title} subheader={subheader} />

          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart type="line" series={data} options={chartOptions} height={364} />
          </Box>
        </Card>
      ) : (
        <Card sx={{ padding: 5, textAlign: 'center' }}>NOT A VALID CHART, Doesn`t have valid date breakdown</Card>
      )}
    </>
  )
}
