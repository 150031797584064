import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import Iconify from 'components/iconify'
//
import { getProfile } from 'services/user'
import { resetPassword } from 'services/auth'
import { updateUserData } from 'redux/dispatcher/userDispatch'
import { showAlertModal } from 'utils'
import { isValidEmail } from 'utils/inputValidations'
import useAxios from 'hooks/useAxios'
import navConfig from 'layouts/dashboard/nav/config'
import { user } from 'redux/userSlice'
import { useSelector } from 'react-redux'
import { MENU } from 'services/url'
import { combinedMenu } from 'utils/common'
// FIXME: BELOW IMPORT SHOULD BE FIXED
import { emailSignIn } from '../../firebase/auth-service'
// ----------------------------------------------------------------------

export default function LoginForm() {
  const users = useSelector(user)
  const menu = useAxios()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = async (method = 'login') => {
    try {
      setLoading(true)
      if (!isValidEmail(email, ['triwavesolutions.com', 'mailinator.com'])) {
        showAlertModal({ message: `Email not Valid` })
      }
      if (method === 'resetPassword') {
        await resetPassword(email)
      } else {
        await emailSignIn(email, password)
        const response = await getProfile()
        if (response?.success) {
          const userState = { ...response?.data, avatar: Math.ceil(Math.random() * 4) }
          updateUserData(userState)
          menu.sendRequest(MENU)
        }
      }
    } catch (error) {
      if (error.message) {
        const err = error.message.split(' (')[1].replace(')', '')
        showAlertModal({ message: `Given Credentials not valid - ${err}` })
      } else console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (menu.data) {
      const removeIcon = navConfig.map(({ icon, ...rest }) => rest)
      const preparedMenu = combinedMenu(removeIcon, menu.data)
      updateUserData({ ...users, menu: preparedMenu })
      navigate('/', { replace: true })
    } else if (menu.error) {
      console.error(menu.error)
    }
  }, [menu.response])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {isValidEmail(email, ['triwavesolutions.com', 'mailinator.com']) && (
          <Link
            variant="subtitle2"
            underline="hover"
            style={{ cursor: 'pointer' }}
            onClick={() => handleClick('resetPassword')}
          >
            Forgot password?
          </Link>
        )}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} onClick={handleClick} onKeyUpCapture={handleKeyDown}>
        Login
      </LoadingButton>
    </>
  )
}
