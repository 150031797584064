import React, { useState, useEffect, useCallback } from 'react'
import { WORK_AUTH_CHOICE, formStyle } from 'pages/Consultant/utils'
import RenderTextField from 'pages/Common/RenderTextField'

const formFields = [
  ['general_search', 'General Search'],
  ['job_title', 'Job Title'],
  ['work_authorization', 'Work Auth'],
  ['state', 'State'],
  ['certification', 'Certification'],
]

function FilterForm({ setQuery, jobTitle, certification }) {
  const [filterField, setFilterField] = useState(() =>
    formFields.reduce((acc, [key]) => {
      acc[key] = ''
      return acc
    }, {})
  )

  const handleChange = useCallback((event) => {
    const { name, value } = event.target
    setFilterField((prev) => ({ ...prev, [name]: value }))
  }, [])

  useEffect(() => {
    setQuery((prev) => ({ ...prev, ...filterField }))
  }, [filterField])

  return (
    <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
      {formFields.map(([name, label]) => (
        <RenderTextField
          key={name}
          name={name}
          label={label}
          formValues={filterField}
          handleInputChange={handleChange}
          fields={{
            required: [],
            multiSelect: [],
            option: { job_title: jobTitle, work_authorization: WORK_AUTH_CHOICE, certification },
          }}
        />
      ))}
    </form>
  )
}

export default FilterForm
