import { Helmet } from 'react-helmet-async'
// @mui
import { Grid, Container } from '@mui/material'
// sections
import { CoreStrength } from 'sections/app'
// hooks
import { HOME } from 'utils/helmet'
import StatsCount from 'sections/app/StatsCount'
import TabsComponent from 'sections/app/TabsComponent'
// ----------------------------------------------------------------------

export default function DashboardAppPage({ access }) {

  return (
    <>
      <Helmet>
        <title> {HOME} </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* Stats Counts Section */}
        <Grid container spacing={1}>
          <Grid spacing={1}>
            <StatsCount />
          </Grid>
        </Grid>
        {/* Core Strength Section */}
        <Grid container spacing={1} justifyContent={'center'}>
          <Grid item xs={12}>
            <CoreStrength />
          </Grid>
        </Grid>
        <Grid spacing={1}>
          <TabsComponent/>
        </Grid>
      </Container>
    </>
  )
}
