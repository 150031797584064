import React, { useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { LIST_CONSULTANT } from 'services/url'
import { capitalizeEachWord, redirectToConsultant } from 'utils/common'
import { CATEGORY_APPROVED } from 'pages/Consultant/utils'
import { Box, Card, CardContent, Divider, List, ListItem, Typography } from '@mui/material'

const LatestConsultant = () => {
  const consultantRequest = useAxios()

  useEffect(() => {
    consultantRequest.sendRequest(LIST_CONSULTANT(5, 1, CATEGORY_APPROVED))
  }, [])

  return (
    <>
      <Card variant="outlined" style={{ borderRadius: 5 }}>
        <Typography variant="body1" sx={{ textAlign: 'center', mt: 1, fontWeight: 800 }}>
          RECENT CONSULTANTS
        </Typography>
        <CardContent>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="subtitle2" color="textSecondary">
              Consultant Name
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Labour Category
            </Typography>
          </Box>
          <Divider />
          <List>
            {Array.isArray(consultantRequest.data?.results) &&
              consultantRequest.data.results.map((consultant, index) => (
                <ListItem key={index} divider>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => redirectToConsultant(consultant.full_name)}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      {capitalizeEachWord(consultant.full_name)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {capitalizeEachWord(consultant.labour_category.replace(/_/g, ' '))}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
          </List>
        </CardContent>
      </Card>
    </>
  )
}

export default LatestConsultant
