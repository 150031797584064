import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { LoadingButton } from '@mui/lab'
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material'

import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'

import useAxios from 'hooks/useAxios'
import { hasAccess } from 'utils'

import { LIST_SUPPORT_TICKET } from 'services/url'
import DetailView from 'pages/SupportHub/DetailView'
import { SUPPORT_TICKET } from 'utils/helmet'
import CustomInput from 'sections/common/CustomInput'
import model from 'sections/models/support_ticket'
import { objectToQueryString } from 'utils/common'
import useUser from 'hooks/useUser'

const queryInit = {assignee: '', status: '', priority: '', id: ''}

export default function SupportTicketPage({ access }) {
  // FIXME: Need to check how to update values to useParams on useEffect
  const { page, rowsPerPage, filter } = useParams()
  const userData = useUser()
  const filterUser = useMemo(() => {
    return hasAccess(access, 'admin') ? '' : userData.email || ''
  }, [])
  const [query, setQuery] = useState(queryInit)
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: page || 1, rowsPerPage: rowsPerPage || 10, totalCount: 0 })

  const supportTicketRequest = useAxios()

  const columns = useMemo(() => [
    { id: 'id', label: 'Ticket No', alignRight: true, align: 'center' },
    { id: 'title', label: 'Title', alignRight: false },
    // { id: 'assignee', label: 'Assignee', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'priority', label: 'Priority', alignRight: false }
  ], [])

  useEffect(() => {
    fetchAll()
  }, [paginator.page, paginator.rowsPerPage])

  const fetchAll = (clear=false) => {
    const getList = LIST_SUPPORT_TICKET(paginator.rowsPerPage, paginator.page, !clear ? objectToQueryString(query) : '', filterUser)
    supportTicketRequest.sendRequest(getList)
  }

  useEffect(() => {
    if (supportTicketRequest.data) {
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: supportTicketRequest.data.count }))
    } else if (supportTicketRequest.error) {
      alert('Something is not correct with the support ticket request')
    }
  }, [supportTicketRequest.response])

  const resetFilter = () => {
    setQuery({...queryInit,  filter_user: hasAccess(access, 'admin') ? '' : userData.email || ''})
    fetchAll(true)
  }

  const handleChange = (e) => {
    const field = e.target.name
    const value = e.target.value
    setQuery(prev => ({...prev, [field]: value}))
  }

  const renderSearchButtons = useMemo(() => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2} gap={2}>
      <CustomInput
        key={'id'}
        name={'id'}
        formValue={query.id}
        {...model.id}
        handleInputChange={handleChange}
      />
      <CustomInput
        key={'assignee'}
        name={'assignee'}
        formValue={query.assignee}
        {...model.assignee}
        handleInputChange={handleChange}
      />
      <CustomInput
        key={'priority'}
        name={'priority'}
        formValue={query.priority}
        {...model.priority}
        handleInputChange={handleChange}
      />
      <CustomInput
        key={'status'}
        name={'status'}
        formValue={query.status}
        {...model.status}
        handleInputChange={handleChange}
      />
      <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          onClick={() => fetchAll()}
          startIcon={<Iconify icon="eva:search-fill" />}
        >
          Search
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={resetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  ), [query])

  const renderMainContent = useMemo(() => {
    if (supportTicketRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (supportTicketRequest.data?.results.length > 0) {
      return (
        <TableView
          data={supportTicketRequest.data.results}
          columns={columns}
          selected={selected}
          handleSelect={setSelected}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }, [supportTicketRequest])

  return (
    <>
      <Helmet>
        <title>{SUPPORT_TICKET}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Support Hub</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={supportTicketRequest.loading}
              variant="contained"
              onClick={() => fetchAll()}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setSelectedData({ title: '' })}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Ticket
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons}</Card>

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent}
        </Card>

        <DetailView key={selectedData?.title} access={access} data={selectedData} fetchData={fetchAll} close={() => setSelectedData({})} />
      </Container>
    </>
  )
}
