export const linkRegex = /^https?:\/\/.*$/i

export const capCommaValidation = (event, update) => {
  const { name, value } = event.target

  // Remove spaces and convert to uppercase
  const sanitizedValue = value.replace(/\s/g, '').toUpperCase()
  // Only allow letters and hyphens
  const restrictedValue = sanitizedValue.replace(/[^A-Z,-]/g, '')

  update(name, restrictedValue)
}

export const capHyphenValidation = (event, update) => {
  const { name, value } = event.target

  // Remove spaces and convert to uppercase
  const sanitizedValue = value.replace(/\s+/g, '-').toUpperCase()
  // Only allow letters and hyphens
  const restrictedValue = sanitizedValue.replace(/[^A-Z,-]/g, '')

  update(name, restrictedValue)
}

export const clearFieldValue = (name, update, value = null) => {
  if (name && update) update(name, value)
}

export const linkedPasteValidation = (event, update) => {
  // eslint-disable-next-line
  const { name, value } = event.target
  let pastedText = ''

  if (event.clipboardData && event.clipboardData.getData) {
    pastedText = event.clipboardData.getData('text/plain')
  } else if (window.clipboardData && window.clipboardData.getData) {
    pastedText = window.clipboardData.getData('Text')
  }

  if (linkRegex.test(pastedText)) update(name, pastedText)
}

export const formatDate = (date = new Date()) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  const formattedDateTime = date.toLocaleString('en-US', options).replace(',', 'T')
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0')

  return `${formattedDateTime.replace(/\//g, '-')}.${milliseconds}`.replace(' ', '')
}

export const isValidEmail = (email, allowDomains = []) => {
  const pattern = /^[\w.-]+@[\w.-]+\.\w+$/
  const valid = pattern.test(email)
  if (valid && allowDomains.length > 0) {
    return allowDomains.includes(email.split('@')[1])
  }
  return valid
}

export function isValidDateFormat(value) {
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/
  return dateFormat.test(value)
}
