import React from 'react'
import { Fab } from '@mui/material'
import SvgColor from 'components/svg-color'

const SupportFloatingIcon = () => {
  const handleClick = () => {
    window.location.href = '/support'
  }

  return (
    <Fab
      color="primary"
      aria-label="support"
      onClick={handleClick}
      sx={{
        position: 'fixed',
        bottom: 24,
        right: 24,
      }}
    >
      <SvgColor src={`/assets/icons/navbar/ic_support_outlined.svg`} />
    </Fab>
  )
}

export default SupportFloatingIcon
