import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export function verifyUser(body) {
  return makeAPIRequest('/verify/user', buildOptions('POST', body))
}

export function resetPassword(email) {
  return makeAPIRequest(`/user/resetpass/${email}`, buildOptions(), false)
}

export function trackUserEngagement(timeSpent) {
  return makeAPIRequest('/track/user', buildOptions('PUT', {time_spent: timeSpent}), true)
}
