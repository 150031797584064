import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Paper, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DetailView from 'sections/common/DetailView'
import { hasAccess } from 'utils'
import { getBreakDownList, createAnalytic, updateAnalyticById } from 'services/analytic'
import RenderTextField from 'pages/Common/RenderTextField'

import {
  formStyle,
  ANALYTIC_FIELD_TYPES,
  FORM_FIELDS,
  ANALYTIC_FIELD_DEFINITION,
  INITIAL_FORM_VALUES,
} from 'pages/Analytic/utils'
import useUser from 'hooks/useUser'
//
import ManageFilters from './ManageFilters'
//

const EditPage = ({ access, data, cohort, fetchData, close }) => {
  const isEditMode = !!data?.id
  const userData = useUser()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [breakDown, setBreakDown] = useState([])
  const [formValues, setFormValues] = useState({})
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [fieldType, setFieldType] = useState(ANALYTIC_FIELD_TYPES)

  const initFormData = () => {
    setDirty(false)
    setErrors({})
    setFormValues(INITIAL_FORM_VALUES({ ...data }, ANALYTIC_FIELD_DEFINITION))
    if (data?.filter) {
      const newData = Object.fromEntries(
        Object.entries(data.filter).map(([key, value]) => [
          key,
          { ...value,
            variant: value.included ? 'include' : 'exclude'
          }
        ])
      )
      setFilter(newData)
    }
  }

  useEffect(() => {
    setFieldType((prevData) => ({
      ...prevData,
      option: {
        ...prevData.option,
        cohort: cohort.map((c) => ({ label: c.toUpperCase(), value: c })),
      },
    }))
  }, [cohort])

  useEffect(() => {
    setFieldType((prevData) => ({
      ...prevData,
      option: {
        ...prevData.option,
        break_down: breakDown?.map((b) => ({ label: b.toUpperCase(), value: b })),
      },
    }))
  }, [breakDown])

  useEffect(() => {
    if (formValues?.cohort) {
      getBreakDownList(formValues.cohort).then((res) => {
        setBreakDown(res.data)
      })
    }
  }, [formValues?.cohort])

  const validateForm = () => {
    const formErrors = {}
    let isValid = true

    ANALYTIC_FIELD_TYPES.required.forEach((field) => {
      if (!formValues[field]) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`
        isValid = false
      }
    })

    setErrors(formErrors)
    return isValid
  }

  useEffect(() => {
    initFormData()
  }, [data])

  const reqFunc = async () => {
    setDirty(true)
    setLoading(true)

    const santizedFilter = JSON.parse(JSON.stringify(Object.values(filter)))
    // NOTE: updating value on original array
    santizedFilter.map(ele => {
      ele.included = ele.variant === "include"
      delete ele.option
      delete ele.variant
      return ele
    })

    const isValid = validateForm()
    if (isValid) {
      if (isEditMode) {
        // FIXME: Need to check filters Object and check for changes
        // For now its always going to update the Analytics Table
        const shouldUpdate = Object.keys(formValues).some((key) => data[key] !== formValues[key]) || true
        if (shouldUpdate) {
          try {
            await updateAnalyticById(data.id, {...formValues, filter: santizedFilter})
            fetchData()
          } catch (error) {
            console.error(error)
            alert('Sorry couldn`t update analytics now, please try again later')
          }
        }
      } else {
        try {
          await createAnalytic({ ...formValues, author: userData.email })
          fetchData()
        } catch (error) {
          console.error(error)
          alert('Sorry couldn`t create analytics now, please try again later')
        }
      }
    }
    setLoading(false)
  }

  const handleChange = useCallback((e) => {
    const { name, value, checked } = e.target
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: name === 'pinned' || name === 'is_published' ? checked : value,
    }))
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    reqFunc()
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Analytic Form"
      body={
        <>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
            <form style={formStyle}>
              {FORM_FIELDS.map((field) => (
                <RenderTextField
                  key={field[0]}
                  name={field[0]}
                  label={field[1]}
                  formValues={formValues}
                  handleInputChange={handleChange}
                  errors={errors}
                  fields={fieldType}
                />
              ))}
            </form>
          </Paper>
          {
            isEditMode && formValues?.cohort &&
            <ManageFilters
              cohort={formValues?.cohort}
              filter={filter}
              setFilter={setFilter}
            />
          }
        </>
      }
      actions={<>{isValidAccess() && renderActions()}</>}
    />
  )

  function isValidAccess() {
    return hasAccess(access, 'edit')
  }

  function renderActions() {
    return (
      <>
        {dirty && Object.keys(errors).length > 1 && <Alert severity="error">Add Required Fields</Alert>}
        {(!isEditMode || isValidAdminAccess() || isAuthor()) && (
          <LoadingButton loading={loading} onClick={handleSubmit} type="submit" variant="contained" color="secondary">
            SAVE
          </LoadingButton>
        )}
      </>
    )
  }

  function isValidAdminAccess() {
    return hasAccess(access, 'admin')
  }

  function isAuthor() {
    return data.author === userData.email
  }
}

EditPage.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default EditPage
