import { configureStore } from '@reduxjs/toolkit'
import throttle from 'lodash.throttle'
import userSlice from 'redux/userSlice' // eslint-disable-line
import modalSlice from 'redux/modalSlice' // eslint-disable-line
import { loadState, saveState } from 'redux/localStorage'

const preloadedState = loadState()

export const store = configureStore({
  reducer: {
    user: userSlice,
    modal: modalSlice,
  },
  preloadedState,
})

store.subscribe(
  throttle(() => {
    saveState({
      user: store.getState().user,
      // modal: store.getState().modal,
      todo: store.getState().todo,
    })
  }, 500)
)
