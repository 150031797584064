import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, ToggleButtonGroup, ToggleButton } from '@mui/material'
import useAxios from 'hooks/useAxios'
import { LIST_ROLE } from 'services/url'
import { ROLE } from 'utils'

const rowStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 2,
  padding: 1,
  border: '1px solid grey',
  borderRadius: 2,
}

const container = { maxWidth: '600px', width: '100%', margin: 'auto' }

const RoleAccess = ({ id, data, setData }) => {
  const roleRequest = useAxios()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (roleRequest.data) {
      setRoles(roleRequest.data)
    } else if (roleRequest.error) {
      console.error('Something is not correct with role request')
    }
  }, [roleRequest.response])

  useEffect(() => {
    roleRequest.sendRequest(LIST_ROLE(id))
  }, [])

  const handleRoleChange = (identifier, value) => {
    const updatedRoles = roles.map((item) => {
      if (item.identifier === identifier) {
        return { ...item, access: value }
      }
      return item
    })
    setRoles(updatedRoles)
    setData({ ...data, roles: updatedRoles })
  }

  return (
    <Stack sx={container}>
      {roles.map((item) => (
        item.label &&
        <Stack key={item.identifier} sx={rowStyle}>
          <Stack>{item.label.toUpperCase()}</Stack>
          <ToggleButtonGroup
            exclusive
            color="error"
            value={item.access}
            onChange={(event, value) => handleRoleChange(item.identifier, value)}
          >
            {ROLE.map((option) => (
              <ToggleButton key={option.value} value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      ))}
    </Stack>
  )
}

RoleAccess.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  setData: PropTypes.func.isRequired,
}

export default RoleAccess
