import React from 'react'
import { Container, Typography } from '@mui/material'

export default function FooterSection() {
  const currentYear = new Date().getFullYear()

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '12px auto' }}>
      <Typography variant="caption" color="text.secondary" align="center">
        &copy; {currentYear} Triwavesolutions <br /> All rights reserved.
      </Typography>
    </Container>
  )
}
