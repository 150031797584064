import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import DetailView from 'sections/common/DetailView'
import { Paper, Stack, Button, Alert } from '@mui/material'
import useAxios from 'hooks/useAxios'
import CustomInput from 'sections/common/CustomInput'
import baseEvaluationModel from 'sections/models/evaluation'
import { LoadingButton } from '@mui/lab'
import { INITIAL_FORM_VALUES } from 'pages/Consultant/utils'
import { hasAccess } from 'utils'

import { CREATE_EVALUATOR, GET_EVALUATION_DETAIL, SUBMIT_EVALUATION } from 'services/url'

const Evaluation = ({ access, isOpen, id, close, jobTitle, pipelineNo }) => {
  const [formValues, setFormValues] = useState([[INITIAL_FORM_VALUES({}, baseEvaluationModel)]])
  const [errors, setErrors] = useState([])
  const [dirty, setDirty] = useState(false)
  const [evaluationModel, _] = useState(baseEvaluationModel)
  const { sendRequest, data } = useAxios()

  useEffect(() => {
    setDirty(false)
    setErrors([{}])
    sendRequest(GET_EVALUATION_DETAIL(pipelineNo))
    evaluationModel.job_title.optionList = jobTitle
  }, [pipelineNo, jobTitle])

  useEffect(() => {
    if (Array.isArray(data)) {
      setFormValues(data)
    }
  }, [data])

  const addFormValue = () => {
    setFormValues([...data, INITIAL_FORM_VALUES({}, evaluationModel)])
  }

  const handleInputChange = useCallback((index, e) => {
    const { name, value } = e.target
    setFormValues((prevFormValues) =>
      prevFormValues.map((prevFormValue, ind) => (index === ind ? { ...prevFormValue, [name]: value } : prevFormValue))
    )
  }, [])

  const validateForm = () => {
    const formErrors = []
    let isValid = true

    formValues.forEach((row, index) => {
      const rowErrors = {}
      Object.keys(evaluationModel).forEach((field) => {
        if (evaluationModel[field].isRequired && !row[field]) {
          rowErrors[field] = `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`
          isValid = false
        }
      })
      formErrors[index] = rowErrors
    })
    setErrors(formErrors)
    return isValid
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setDirty(true)
    if (validateForm()) {
      const updatedFormValues = formValues.map((row) => {
        const updatedFormValue = {}
        Object.keys(row).forEach((key) => {
          updatedFormValue[key] = row[key]
        })
        return updatedFormValue
      })

      updatedFormValues.forEach((row) => {
        if (row?.id) {
          sendRequest(SUBMIT_EVALUATION(row.id), true, 'PUT', {
            ...row,
            id: row.id,
          })
            .then(() => {
              alert('Operation Successful')
            })
            .catch((error) => {
              console.error('Error updating Evaluation:', error)
            })
        } else {
          const formattedFormValue = { ...row }
          delete formattedFormValue.id
          sendRequest(CREATE_EVALUATOR, true, 'POST', { ...formattedFormValue, consultant_id: pipelineNo })
            .then(() => {
              alert('Operation Successful')
              close()
            })
            .catch((error) => {
              console.error('Error creating Evaluation:', error)
            })
        }
      })
    }
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(isOpen).length}
      onClose={close}
      title="Evaluation"
      body={
        <Paper elevation={3} sx={{ padding: '20px', marginBottom: 1 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={addFormValue}>
              Add
            </Button>
          </Stack>

          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={2}>
              {formValues.map((formData, index) => (
                <>
                  <Stack direction={'row'} spacing={2} sx={{ alignItems: 'baseline' }}>
                    {Object.keys(evaluationModel).map((fieldName) => (
                      <CustomInput
                        key={fieldName}
                        name={fieldName}
                        label={evaluationModel[fieldName].label}
                        {...evaluationModel[fieldName]}
                        formValue={formData[fieldName]}
                        handleInputChange={(e) => handleInputChange(index, e)}
                        errors={errors[index]}
                      />
                    ))}
                  </Stack>

                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    {dirty && Object.keys(errors[index]).length > 0 && (
                      <Alert severity="error">"Job-Title, Comments, Rating" are required</Alert>
                    )}
                    <Button variant="outlined" onClick={close} size="medium">
                      Cancel
                    </Button>
                    {hasAccess(access, 'edit') && (
                      <LoadingButton
                        type="submit"
                        size={'medium'}
                        variant={'contained'}
                        loading={sendRequest.loading}
                        onClick={handleSubmit}
                      >
                        Save
                      </LoadingButton>
                    )}
                  </Stack>
                </>
              ))}
            </Stack>
          </form>
        </Paper>
      }
    />
  )
}

Evaluation.propTypes = {
  access: PropTypes.number,
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  jobTitle: PropTypes.array.isRequired,
  pipelineNo: PropTypes.string.isRequired,
}

export default Evaluation
