import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getPieChartData } from 'services/chart'
import { formatValue } from 'utils'
import { BarChart, PieChart, LineChart, AreaChart } from './index'

function BaseChart({ id }) {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [subheader, setSubheader] = useState('')
  const [chart, setChart] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [breakDown, setBreakDown] = useState(null)
  const [error, setError] = useState(null)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getPieChartData(id)
      setTitle(response.data.name)
      setSubheader(response.data.description)
      setChartData(formatValue(response.chart_data))
      setChart(response.data.chart)
      setBreakDown(response.data.break_down)
    } catch (e) {
      setError('Something went wrong. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (loading) {
    return null
  }

  if (error) {
    return <div>{error}</div>
  }

  let ChartComponent
  switch (chart) {
    case 'Pie_Chart':
      ChartComponent = PieChart
      break
    case 'Bar_Chart':
      ChartComponent = BarChart
      break
    case 'Line_Chart':
      ChartComponent = LineChart
      break
    case 'Area_Chart':
      ChartComponent = AreaChart
      break
    default:
      return null
  }

  return (
    <ChartComponent
      title={title}
      subheader={subheader}
      chartData={chartData}
      { ...(chart === 'Pie_Chart' && { breakDown }) }
    />
  )
}

BaseChart.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(BaseChart)
