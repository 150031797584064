import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Box, Link } from '@mui/material'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src="/assets/logo.png" alt="logo" />
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    // <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
    //   {logo}
    // </Link>
    <>{logo}</>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
}

export default Logo
