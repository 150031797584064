import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Stack } from '@mui/material'
import useAxios from 'hooks/useAxios'
import { WIDGET_SUMMARY } from 'services/url'
import AppWidgetSummary from './AppWidgetSummary'

StatsCount.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.object,
}

export default function StatsCount({ color = 'primary', sx, ...other }) {
  const { data: widgetData, error, sendRequest } = useAxios()

  useEffect(() => {
    if (error) {
      console.error('Something is not correct with Widget')
    }
  }, [error])

  useEffect(() => {
    sendRequest(WIDGET_SUMMARY)
  }, [])

  if (!widgetData) {
    return <div>Loading...</div>
  }

  // Separate the data into two parts for rendering
  const firstThreeStats = widgetData.slice(0, 3)
  const remainingStats = widgetData.slice(3)

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ...sx }} {...other}>
      <Grid container spacing={1} sx={{ height: '100%' }}>
        <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack direction="row" sx={{ flex: 1, justifyContent: 'space-around' }}>
            {/* Render the first three stats */}
            {firstThreeStats.map((stat) => (
              <AppWidgetSummary
                key={stat.label}
                data={stat}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack direction="row" gap={1} flexWrap="wrap" sx={{ flex: 1 }}>
            {/* Render the remaining stats */}
            {remainingStats.map((stat) => (
              <AppWidgetSummary
                key={stat.label}
                data={stat}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 4
                }}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
