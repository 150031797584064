export default {
  job_title: {
    label: 'Job Title',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  comments: {
    label: 'Comments',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  rating: {
    label: 'Rating',
    type: 'rating',
    isRequired: true,
    isDisabled: false,
    min: 0,
    max: 5
  }
}
