import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Table, Typography, TableBody, TableContainer, MenuItem, TextField, Stack } from '@mui/material'
import TableRow from 'sections/table/Row'
import Header from 'sections/table/Header'
import TableNoData from 'sections/table/NoData'
import Iconify from 'components/iconify'

const TableView = ({
  data,
  columns,
  selected = [],
  handleSelect = () => {},
  setPaginator = () => {},
  handleEdit = () => {},
  paginator = { page: 1, rowsPerPage: 10, totalCount: 0 },
  customHandler,
  showEdit = true,
  basicTable = true,
  hidePagination = false,
}) => {
  const onSelectAllClick = useCallback(() => handleSelect(data.map((row) => row.id)), [data, handleSelect])
  const handleChangePage = useCallback(
    (newPage) => {
      setPaginator((prevPaginator) => ({
        ...prevPaginator,
        page:
          newPage === '+' ? prevPaginator.page + 1 : newPage === '-' ? prevPaginator.page - 1 : parseInt(newPage, 10),
      }))
    },
    [paginator, setPaginator]
  )
  const handleChangeRowsPerPage = useCallback(
    (event) => setPaginator({ page: 1, rowsPerPage: parseInt(event.target.value, 10) }),
    [setPaginator]
  )
  const totalPages = Math.ceil(paginator.totalCount / paginator.rowsPerPage || 1)

  return (
    <>
      <TableContainer>
        <Table>
          <Header
            basicTable={basicTable}
            headLabel={columns}
            onSelectAllClick={onSelectAllClick}
            numSelected={selected.length}
            rowCount={data.length}
          />
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => (
                <TableRow
                  basicTable={basicTable}
                  key={row.id}
                  row={row}
                  showEdit={showEdit}
                  handleClick={handleSelect}
                  handleEdit={handleEdit}
                  selected={selected.includes(row.id)}
                  columns={columns.map((column) => ({
                    id: column.id,
                    align: column.align,
                  }))}
                  customHandler={customHandler}
                />
              ))
            ) : (
              <TableNoData columns={columns.map((column) => column.id)} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {hidePagination ? (
        <></>
      ) : (
        <Stack
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'normal',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <TextField select size="small" onChange={handleChangeRowsPerPage} value={paginator.rowsPerPage}>
            {Array.from([10, 25, 50, 200], (option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ mx: 1 }}>
            {paginator.page} of {totalPages}
          </Typography>

          <Iconify
            sx={{ mx: 1 }}
            icon={`icon-park-${paginator.page !== 1 ? 'solid' : 'outline'}:left-c`}
            onClick={() => paginator.page !== 1 && handleChangePage('-')}
          />

          <Iconify
            sx={{ mx: 1 }}
            icon={`icon-park-${paginator.page < totalPages ? 'solid' : 'outline'}:right-c`}
            onClick={() => paginator.page < totalPages && handleChangePage('+')}
          />
        </Stack>
      )}
    </>
  )
}

TableView.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleEdit: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  paginator: PropTypes.object.isRequired,
  setPaginator: PropTypes.func.isRequired,
  customHandler: PropTypes.func,
  showEdit: PropTypes.bool,
  basicTable: PropTypes.bool,
  hidePagination: PropTypes.bool,
}

export default TableView
