import contactModel from '../models/contact'
import { isValidEmail } from '../../utils/inputValidations'

function validateField(fieldName, value, { isRequired, type, min, max }) {
  const isEmail = type === 'email' || fieldName.toLowerCase().includes('email')
  const isRating = type === 'rating'

  return isRequired
    ? (isEmail ? isValidEmail(value) : (isRating ? value >= min && value <= max : value.trim() !== ''))
    : true
}

export function validateData(data, model) {
  const errors = {}

  Object.entries(model).forEach(([fieldName, fieldData]) => {
    const fieldValue = data[fieldName]
    if (!validateField(fieldName, fieldValue, fieldData)) {
      errors[fieldName] = `${fieldData.label} is required`
    }
  })

  if (Array.isArray(data.contacts)) {
    data.contacts.forEach((contact, index) => {
      Object.entries(contactModel).forEach(([field, fieldValue]) => {
        const fieldValueData = contact[field]
        if (!validateField(field, fieldValueData, fieldValue)) {
          if (!errors.contacts) errors.contacts = []
          if (!errors.contacts[index]) errors.contacts[index] = {}
          errors.contacts[index][field] = `${fieldValue.label} is required`
        }
      })
    })
  // } else {
  //   errors.contacts = ['At least one contact is required'];
  }

  return [errors, Object.keys(errors).length === 0]
}
