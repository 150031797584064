export const WIDGET_SUMMARY = '/stats-count'
export const CONSULTANT_CORE_STRENGTH = '/core-strength'
export const LIST_ANALYTICS = '/analytic/list'
export const PINNED_ANALYTICS = `${LIST_ANALYTICS}?pinned=true&is_published=true`
export const MENU = '/auth/menu'
export const PROFILE = '/profile'
export const LIST_USER = (pageSize, page) => `/user/all?page_size=${pageSize}&page=${page}`
export const LIST_ROLE = (id) => `/auth/${id}/roles`
export const CREATE_USER = '/user/create'
export const UPDATE_ROLE = (id) => `/auth/${id}/assign-role`
export const UPDATE_USER = (id) => `/user/${id}`
export const LIST_CONSULTANT = (pageSize = 10, page = 1, filterField = '') =>
  `/consultant/all?page_size=${pageSize}&page=${page}&${filterField}`
export const CREATE_CLIENT = '/client'
export const UPDATE_CLIENT = (id) => `/client/${id}`
export const LIST_CLIENT = (pageSize, page, query = '') => `/client?page_size=${pageSize}&page=${page}${query}`
export const CREATE_CONSULTANT = '/consultant'
export const UPDATE_CONSULTANT = (id) => `/consultant/${id}`
export const GET_CONSULTANT_SUBMISSION = (id) => `/submissions/all/${id}`
export const GET_CONSULTANT_VENDOR = (id) => `/vendor-consultant?consultant_id=${id}`
export const GET_JOB_TITLE = '/job_titles'
export const GET_CERTIFICATION = '/certifications'
export const GET_CLIENT = '/clients'
export const GET_LATEST_CLIENT = '/widget/client'
export const GET_VENDOR = '/vendors'
export const CREATE_VENDOR = '/vendor'
export const UPDATE_VENDOR = (id) => `/vendor/${id}`
export const LIST_VENDOR = (pageSize, page, query = '') => `/vendor?page_size=${pageSize}&page=${page}${query}`
export const CREATE_SUBMISSION = '/submissions/consultant'
export const SUBMIT_SUBMISSION = (id) => `/submissions/consultant/${id}`
export const CREATE_VENDOR_CONSULTANT = '/vendor-consultant'
export const SUBMIT_VENDOR_CONSULTANT = (id) => `/vendor-consultant/${id}`
export const JD_REQUIREMENTS_LIST = (pageSize, page, query = '') =>
  `/jd/requirements?page_size=${pageSize}&page=${page}&${query}`
export const JD_REQUIREMENTS_UPDATE = (id) => `/jd/requirement/${id}`
export const JD_REQUIREMENTS_CREATE = '/jd/requirement/create'
export const JD_REQUIREMENTS_WIDGET = '/widget/jd-requirements'
export const ANALYTIC_LIST = '/analytic/list'
export const PROJECT_LIST = '/project/list'
export const GET_ASSET = (pageSize, page, query = '') => `/assets?page_size=${pageSize}&page=${page}${query}`
export const CREATE_ASSET = '/assets'
export const UPDATE_ASSET = (id) => `/assets/${id}`
export const GET_USAGE_HISTORY = (assetId) => `/usage-history?asset=${assetId}`
export const UPDATE_USAGE_HISTORY = (assetId) => `/asset/usage/${assetId}`
export const LIST_USER_LOG = (pageSize, page, email) =>
  `/track/user/log?page_size=${pageSize}&page=${page}&user_email=${email}`
export const LIST_SUPPORT_TICKET = (pageSize, page, query = '', filterUser = '') =>
  `/tickets?page_size=${pageSize}&page=${page}&${query}&filter_user=${filterUser}`
export const SUPPORT_TICKET_UPDATE = (id) => `/tickets/${id}`
export const SUPPORT_TICKET_CREATE = '/tickets'
export const LIST_LMS_USER = (year) => `/list_lms_details/${year}`
export const LIST_USER_LEAVE_REQUEST = (lmsUserId) => `/leave_request/${lmsUserId}`
export const LIST_COMPENSATION = (lmsUserId) => `/l/compensation/${lmsUserId}`
export const LIST_PUBLIC_HOLIDAYS = (year) => `/public_holidays/${year}`
export const CREATE_EVALUATOR = '/evaluation/create'
export const GET_EVALUATION_DETAIL = (id) => `/evaluation/detail/${id}`
export const SUBMIT_EVALUATION = (id) => `/evaluation/update/${id}`
export const ASSET_DECALARATION = (id) => `/asset/acknowledgement${id ? `/${id}` : ''}`
export const MS_LICENSE = () => `/ms-license`
export const LIST_REIMBURSEMENT = (pageSize, page, category = '', user = '') =>
  `/reimb?page_size=${pageSize}&page=${page}${category ? `&category=${category}` : ''}${user ? `&user=${user}` : ''}`
export const CREATE_UPDATE_REIMBURSEMENT = () => `/reimb`
