import { useCallback } from 'react'

const useHandleSelect = (setSelected) => {
  const handleSelect = useCallback(
    (ids) => {
      setSelected((prevSelected) =>
        Array.isArray(ids)
          ? prevSelected.length && prevSelected.length === ids.length
            ? []
            : ids
          : prevSelected.includes(ids)
          ? prevSelected.filter((item) => item !== ids)
          : [...prevSelected, ids]
      )
    },
    [setSelected]
  )

  return handleSelect
}

export default useHandleSelect
