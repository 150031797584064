import { Helmet } from 'react-helmet-async'
import { useEffect, useState, useCallback } from 'react'
import { Grid, Button, Container, Stack, Typography } from '@mui/material'
import { PMT } from 'utils/helmet'
import useAxios from 'hooks/useAxios'
import { PROJECT_LIST } from 'services/url'
import { getWeekDropDown } from 'services/pmt'
import PmtCard from 'sections/pmt/PmtCard'
import { hasAccess } from 'utils'
import Iconify from 'components/iconify'
import DetailView from 'pages/Pmt/DetailView'
import MonthlyReport from 'pages/Common/MonthlyReport'
import TimeSheet from './TimeSheet'

export default function PmtPage({ access }) {
  const { data: pmt, error, sendRequest } = useAxios()
  const [selectedData, setSelectedData] = useState({})
  const [showTimeSheet, setShowTimeSheet] = useState({show:false, self: false})
  const [weekDropDown, setWeekDropDown] = useState([])

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
  }, [])

  const fetchData = useCallback(() => {
    sendRequest(PROJECT_LIST)
  }, [sendRequest])

  const createNewEntry = useCallback(() => {
    setSelectedData({ name: '' })
  }, [])

  useEffect(() => {
    if (error) {
      console.error('Error fetching projects:', error)
    }
  }, [error])

  useEffect(() => {
    fetchData()
    getWeekDropDown()
      .then(res => setWeekDropDown(res.data))
  }, [])

  return (
    <>
      <Helmet>
        <title>{PMT}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Project List
          </Typography>
          <Stack direction={'row'} spacing={1}>
          <MonthlyReport access={access}/>
            {pmt && (
              <>
                <Button
                  onClick={() => setShowTimeSheet({show: true, self: false})}
                  color="warning"
                  variant="contained"
                  startIcon={<Iconify icon="hugeicons:note-edit" />}
                >
                  Manage TS
                </Button>
                <Button
                  onClick={() => setShowTimeSheet({show: true, self: true})}
                  variant="contained"
                  startIcon={<Iconify icon="akar-icons:edit" />}
                >
                  My Time Sheet
                </Button>
              </>
            )}
            {hasAccess(access, 'admin') && (
              <Button
                onClick={createNewEntry}
                color="inherit"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Project
              </Button>
            )}
          </Stack>
        </Stack>
        <Grid container spacing={3}>
          {pmt?.map((data) => (
            <PmtCard key={data.id} data={data} setSelectedData={setSelectedData} />
          ))}
        </Grid>
      </Container>
      <DetailView
        key={selectedData?.id}
        access={access}
        data={selectedData}
        fetchData={fetchData}
        close={handleCloseDetailPage}
      />
      {showTimeSheet.show && (
        <TimeSheet
          key={`show-${showTimeSheet}`}
          access={access}
          projects={pmt}
          isSelf={showTimeSheet.self}
          weekDropDown={weekDropDown}
          close={() => setShowTimeSheet({show: false, self: false})}
        />
      )}
    </>
  )
}
