import React from 'react'
import {
  Box,
} from '@mui/material'
import Departments from './Departments'
import LatestClient from './LatestClient'
import LatestConsultant from './LatestConsultant'

const QuickAccessComponent = () => {
  return (
    <Box display="flex" gap={2}>
      <Box flex={1}>
        <Box my={2}>
          <LatestConsultant />
        </Box>
        <Box>
          <LatestClient />
        </Box>
      </Box>
      <Box flex={1} mb-2>
        <Departments />
      </Box>
    </Box>
  )
}

export default QuickAccessComponent
