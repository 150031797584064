import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles'
import { Button, Box, Divider, Typography, Stack, MenuItem, IconButton, Popover, Avatar } from '@mui/material'
// hooks
import useUser from 'hooks/useUser'
// component
import { DelegateAccess } from 'sections/delegate'
// redux
import { signOut } from 'redux/dispatcher/userDispatch'
// firebase
import { signOutUser } from '../../../firebase/auth-service'
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate()
  const userData = useUser()
  const [avatar, setAvatar] = useState(null)
  const [openDelegate, setOpenDelegate] = useState(false)

  const [open, setOpen] = useState(null)
  useEffect(() => {
    setAvatar(`/assets/images/avatars/${userData?.gender?.toLowerCase()}/avatar_${userData?.avatar}.jpg`)
  }, [userData])

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = (url) => {
    setOpen(null)
    navigate(url)
  }
  const handleLogout = async () => {
    try {
      await signOutUser()
      signOut()
      window.location.href = '/login'
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={avatar} alt={userData?.first_name} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.first_name} {userData?.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData?.email}
          </Typography>
        </Box>


        {
          userData.is_super_admin &&
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack sx={{ p: 1 }}>
              {/* <MenuItem onClick={() => handleClose('/home')}>Home</MenuItem> */}
              {/* <MenuItem onClick={() => handleClose(`/user/edit/${userData?.id}`)}>Profile</MenuItem> */}
              <MenuItem onClick={() => setOpenDelegate(true)}>Delegate Access</MenuItem>
            </Stack>
          </>
        }

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout}>
          <Button fullWidth variant='contained' color='error'>LOGOUT</Button>
        </MenuItem>
      </Popover>
      {
        userData &&
        <DelegateAccess
          open={openDelegate}
          onClose={() => setOpenDelegate(false)}
        />
      }
    </>
  )
}
