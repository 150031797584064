import { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Stack, Button, Typography, IconButton } from '@mui/material'
// components
import Iconify from 'components/iconify'

function Comment({ author, comments, setComments }) {
  const [newComment, setNewComment] = useState('')

  const handleCommentSubmit = () => {
    if (newComment.trim() !== '') {
      const newCommentObject = {
        author,
        id: comments.length + 1,
        text: newComment,
        createdAt: new Date().toISOString(),
        likes: [],
      }
      setComments([newCommentObject, ...comments])
      setNewComment('')
    }
  }

  const handleLikeClick = (id, userEmail) => {
    const updatedComments = comments.map(comment => {
      if (comment.id === id) {
        if (comment.likes.includes(userEmail)) {
          return { ...comment, likes: comment.likes.filter(like => like !== userEmail) }
        }
        return { ...comment, likes: [...comment.likes, userEmail] }
      }
      return comment
    })
    setComments(updatedComments)
  }

  return (
    <div>
      <TextField
        fullWidth
        multiline
        value={newComment}
        onChange={e => setNewComment(e.target.value)}
        sx={{ mb: 5 }}
        InputProps={{
          endAdornment: (
            <Button
              disabled={newComment.trim() === ''}
              variant="contained"
              color="primary"
              onClick={handleCommentSubmit}
            >
              Comment
            </Button>
          ),
        }}
      />
      <Stack spacing={2}>
      {comments.slice().reverse().map(comment => (
        <TextField
          readOnly
          multiline
          key={comment.id}
          label={comment.author}
          value={comment.text}
          sx={{ mb: 2, position: 'relative' }} // Added position: 'relative' to the TextField
          InputProps={{
            endAdornment: (
              <div style={{ position: 'absolute', top: 1, right: 20 }}>
                <Stack direction="column">
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      onClick={() => handleLikeClick(comment.id, author)}
                      color={comment.likes.includes(author) ? 'error' : 'default'}
                    >
                      <Iconify icon="mdi:like-outline" />
                    </IconButton>
                    <Typography variant="caption" component="p">{comment.likes.length}</Typography>
                  </Stack>
                  <Typography variant="caption" component="p">
                    {new Date(comment.createdAt).toLocaleDateString()}
                  </Typography>
                </Stack>
              </div>
            ),
          }}
        />
      ))}
      </Stack>
    </div>
  )
}

Comment.propTypes = {
  author: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  setComments: PropTypes.func.isRequired,
}

export default Comment
