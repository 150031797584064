import PropTypes from 'prop-types'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Link, Card, Grid, Typography, CardContent } from '@mui/material'
// utils
import { fDate } from 'utils/formatTime'
//

// ----------------------------------------------------------------------

const StyledTitle = styled(Link)({
  height: 20,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
})

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}))

// ----------------------------------------------------------------------

AnalyticCard.propTypes = {
  analytic: PropTypes.object.isRequired,
  setSelectedData: PropTypes.func.isRequired,
}

export default function AnalyticCard({ analytic, setSelectedData }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ position: 'relative' }} onClick={() => setSelectedData(analytic)}>
        <CardContent sx={{ pt: 4 }}>
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            <b>{analytic.author}</b>
            <br />
            {fDate(analytic.created)}
          </Typography>

          <StyledTitle color="inherit" variant="subtitle2">
            {analytic.name}
          </StyledTitle>
          <Typography component="span" variant="body2" sx={{ color: 'black' }}>
            {analytic.description}
          </Typography>

          <StyledInfo>
            <Box
              sx={{
                display: 'flex',
                ml: 1.5,
                flexDirection: 'column',
              }}
            >
              <Typography variant="caption">Cohort: {analytic.cohort.toUpperCase()}</Typography>
              <Typography variant="caption">
                Break Down: {analytic.break_down.split('_').join(' ').toUpperCase()}
              </Typography>
            </Box>
          </StyledInfo>
        </CardContent>
      </Card>
    </Grid>
  )
}
