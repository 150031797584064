import { useNavigate } from 'react-router-dom'
// @mui
import PropTypes from 'prop-types'
import { Card, Typography } from '@mui/material'
// utils
import { fShortenNumber } from '../../utils/formatNumber'

// ----------------------------------------------------------------------
AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  sx: PropTypes.object,
}

export default function AppWidgetSummary({ color = 'primary', data, sx, ...other }) {
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        boxShadow: 0,
        textAlign: 'center',
        width: '200px',
        color: '#051B64',
        bgcolor: '#D2E9FC',
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
      onClick={() => navigate(data.redirect_to, { replace: true })}
    >
      <Typography variant="h3" color="#051B64">{fShortenNumber(data.value)}</Typography>
      <Typography variant="subtitle2" sx={{
          color: "#051B64",
          fontSize: "20px"

      }}>
        {data.label}
      </Typography>
    </Card>
  )
}
