import { initialState } from 'redux/modalSlice'

export const reducers = {
  updateShow: (state, action) => {
    const newState = { ...state }
    newState.show = action.payload.show
    if (!action.payload.show) {
      newState.title = initialState.title
      newState.body = initialState.body
    }
    return newState
  },
  updateModal: (state, action) => {
    const newState = { ...state }
    newState.title = action.payload.title
    newState.body = action.payload.body
    return newState
  },
}
