import { useEffect, useState, useCallback } from 'react'
import { LoadingButton } from '@mui/lab'
import { Grid, Alert, Paper, Typography } from '@mui/material'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import { initialFormValues } from 'utils/common'
import { CREATE_UPDATE_REIMBURSEMENT } from 'services/url'
import { validateData } from 'sections/validator/supportTicket'
import model from 'sections/models/reimbursement'
import CustomInput from 'sections/common/CustomInput'

const EditPage = ({ access, selectedData, fetchData, close }) => {
  const isEditMode = !!selectedData?.id
  const { sendRequest, data, error, loading, method } = useAxios()
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState(() => initialFormValues(selectedData || {}, model))

  const initFormData = useCallback(() => {
    setDirty(false)
    setErrors({})
    setFormValues(initialFormValues(selectedData || {}, model))
  }, [selectedData])

  useEffect(() => {
    initFormData()
  }, [selectedData, initFormData])

  const handleChange = (field, value) => {
    setFormValues((prevData) => ({ ...prevData, [field]: value }))
    setDirty(true)
  }

  useEffect(() => {
    if (method !== 'GET') {
      fetchData()
      alert('Operation Successful')
      close()
    }
    if (error) {
      alert('Something went wrong, please contact Admin')
    }
  }, [method, error, fetchData, close])

  const reqFunc = () => {
    if (loading) return
    const [err, valid] = validateData(formValues, model)
    setErrors(err)
    if (valid) {
      const payload = isEditMode ? { ...selectedData, ...formValues } : formValues
      sendRequest(
        CREATE_UPDATE_REIMBURSEMENT(isEditMode ? selectedData.id : ''),
        true,
        isEditMode ? 'PUT' : 'POST',
        payload
      )
    }
  }

  const status = selectedData.approved_by
    ? 'APPROVED'
    : selectedData.rejected_by
    ? 'REJECTED'
    : selectedData.recommended_by
    ? 'PENDING APPROVAL'
    : 'NEW REQUEST'

  const color = selectedData.approved_by ? 'green' : selectedData.rejected_by ? 'red' : 'blue'

  return (
    <DetailView
      access={access}
      open={!!Object.keys(selectedData).length}
      onClose={close}
      title="Reimbusement Detail View"
      body={
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography textAlign={'center'} variant="h6" component="h2" color={color}>
                Reimbursement Status: {status}
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={8}>
                {['title', 'reason'].map((field) => (
                  <CustomInput
                    key={field}
                    name={field}
                    formValue={formValues[field]}
                    {...model[field]}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                ))}
              </Grid>
              <Grid item xs={12} sm={4}>
                {['rate', 'category'].map((field) => (
                  <CustomInput
                    key={field}
                    name={field}
                    formValue={formValues[field]}
                    {...model[field]}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              {['approved_by', 'recommended_by'].map(
                (field) =>
                  selectedData[field] && (
                    <CustomInput
                      key={field}
                      name={field}
                      formValue={formValues[field]}
                      {...model[field]}
                      errors={errors}
                    />
                  )
              )}
            </Grid>
          </Grid>
        </Paper>
      }
      actions={
        <>
          {dirty && Object.keys(errors).length > 1 && <Alert severity="error">Fill in all required fields</Alert>}
          <LoadingButton loading={loading} onClick={reqFunc} color="primary" variant="contained">
            Submit
          </LoadingButton>
        </>
      }
    />
  )
}

export default EditPage
