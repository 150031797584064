export function combinedMenu(navConfig, menu) {
  const formattedMenu = navConfig
    .map((menuItem) => {
      const accessObject = menu.find((accessItem) => accessItem.identifier === menuItem.identifier)
      return accessObject && accessObject.access !== null ? { ...menuItem, access: accessObject.access } : null
    })
    .filter(Boolean) // Filter out null values

  return formattedMenu
}

export function objectToQueryString(obj) {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}

export function initialFormValues(data, definition) {
  const defaultValueMap = {
    string: '',
    boolean: false,
    integer: 0,
    number: 0,
    date: '',
    array: [],
  }

  return Object.keys(definition).reduce((acc, fieldName) => {
    const fieldType = definition[fieldName].type
    acc[fieldName] = data[fieldName] ?? defaultValueMap[fieldType] ?? ''
    return acc
  }, {})
}

export const convertEmailToUsername = (email) =>
  email?.split('@')[0]?.charAt(0).toUpperCase() + email?.split('@')[0]?.slice(1)

export const isValidUUID = (str) => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return uuidRegex.test(str)
}

export const MIL_SECONDS_IN_DAY = 24 * 60 * 60 * 1000

export const getWeekDates = (weeks = 4, startDate = new Date()) => {
  const weekIndices = Array.from({ length: weeks }, (_, index) => index)

  return weekIndices
    .map((weekIndex) => {
      const sunday = new Date(
        startDate.getTime() - startDate.getDay() * MIL_SECONDS_IN_DAY - weekIndex * 7 * MIL_SECONDS_IN_DAY
      )
      return Array.from({ length: 7 }, (_, i) => {
        const day = new Date(sunday.getTime() + i * MIL_SECONDS_IN_DAY)
        return day.toISOString().split('T')[0]
      })
    })
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
}

export const getDatesBetween = (str) => {
  const fromDateMatch = str.match(/from=([\d-]+)/)
  const toDateMatch = str.match(/to=([\d-]+)/)

  if (fromDateMatch && toDateMatch) {
    const fromDate = new Date(fromDateMatch[1])
    const toDate = new Date(toDateMatch[1])
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const dates = []

    for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = `${date.getDate()}-${months[date.getMonth()]}`
      dates.push(formattedDate)
    }

    return dates
  }

  return null
}

export const capitalizeEachWord = (str) =>
  str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('-')

export const redirectToConsultant = (search) =>
  window.location.replace(`/consultants?filter=ALL&general_search=${search}`)
