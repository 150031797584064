export const FORM_FIELDS = [
  ['pinned', 'Pinned'],
  ['is_published', 'Published'],
  ['start_date', 'Start Date'],
  ['end_date', 'End Date'],
  ['name', 'Name'],
  ['description', 'Description'],
  ['date_depends', 'Date Depends'],
  ['chart', 'Chart'],
  ['cohort', 'Cohort'],
  ['break_down', 'Break Down'],
]

export const ANALYTIC_FIELD_DEFINITION = {
  name: 'string',
  description: 'string',
  pinned: 'boolean',
  is_published: 'boolean',
  cohort: 'array',
  break_down: 'array',
  chart: 'array',
  start_date: 'date',
  end_date: 'date',
  date_depends: 'array',
  filter: 'array',
}

export const ANALYTIC_FIELD_TYPES = {
  disable: [],
  boolean: ['pinned', 'is_published'],
  datePicker: ['start_date', 'end_date'],
  option: {
    cohort: [],
    break_down: [],
    chart: [
      {
        label: 'Pie',
        value: 'Pie_Chart',
      },
      {
        label: 'Line',
        value: 'Line_Chart',
      },
      {
        label: 'Bar',
        value: 'Bar_Chart',
      },
      {
        label: 'Area',
        value: 'Area_Chart',
      },
    ],
    date_depends: [
      {
        label: 'Created Date',
        value: 'created_date',
      },
      {
        label: 'Updated Date',
        value: 'updated_date',
      },
    ],
  },
  required: ['start_date', 'end_date', 'name', 'description', 'date_depends', 'chart', 'cohort', 'break_down'],
}

export const INITIAL_FORM_VALUES = (data, definition) =>
  Object.keys(definition).reduce((acc, fieldName) => {
    const fieldType = definition[fieldName]

    switch (fieldType) {
      case 'string':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'boolean':
        acc[fieldName] = data[fieldName] || false
        break
      case 'integer':
        acc[fieldName] = data[fieldName] || 0
        break
      case 'date':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'array':
        acc[fieldName] = data[fieldName] || ''
        break
      default:
        acc[fieldName] = data[fieldName] || ''
    }

    return acc
  }, {})

export const formStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  gap: '0px 16px',
  alignItems: 'center',
}
