import { createSlice } from '@reduxjs/toolkit'

import { reducers } from 'redux/reducers/modalReducer'

export const initialState = {
  show: false,
  title: '',
  body: '',
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers,
})

export const { updateShow, updateModal } = modalSlice.actions

export const modal = (state) => state.modal

export default modalSlice.reducer
