import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select, Button, Stack, OutlinedInput } from '@mui/material'
import Iconify from 'components/iconify'
import { hasAccess } from 'utils'
import { downloadMonthlyHrReport } from 'services/report'
import * as XLSX from 'xlsx'

export default function MonthlyReport({ access }) {
  const isAdmin = hasAccess(access, 'admin')
  const [selectedMonth, setSelectedMonth] = useState('')

  const MONTHS = useMemo(
    () => Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'long' })),
    []
  )

  useEffect(() => {
    const currentMonth = new Date().getMonth()
    setSelectedMonth(MONTHS[currentMonth])
  }, [])

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value)
  }

  const handleDownload = async () => {
    if (selectedMonth) {
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1
      try {
        const response = await downloadMonthlyHrReport(monthIndex)
        const formattedData = response.data
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(formattedData)
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')
        XLSX.writeFile(workbook, `MonthlyReport_${monthIndex}_2024.xlsx`)
        alert(`Monthly report for ${monthIndex} downloaded.`)
      } catch (error) {
        console.error('Error downloading report:', error)
      }
    } else {
      alert('Please select a month first.')
    }
  }

  if (!isAdmin) return null

  return (
    <>
      {isAdmin && (
        <Stack direction="row" spacing={2} alignItems="center">
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            input={<OutlinedInput label="Select Month" />}
            sx={{ height: 40 }}
          >
            {MONTHS.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            startIcon={<Iconify icon="eva:download-fill" />}
          >
            Download Report
          </Button>
        </Stack>
      )}
    </>
  )
}

MonthlyReport.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
}
