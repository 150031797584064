import { useState, useEffect } from 'react'
import axios from 'axios'
import { loadRefreshToken } from 'services'
import { endPoint } from 'config'
import { showAlertModal } from 'utils'

const useAxios = () => {
  const [requestConfig, setRequestConfig] = useState(null)
  const [response, setResponse] = useState({ data: null, error: null })
  const [loading, setLoading] = useState(false)
  const [method, setMethod] = useState('GET')

  const fetchData = async () => {
    try {
      setLoading(true)
      const axiosResponse = await axios(requestConfig)
      if (!axiosResponse?.success) handleErrorResponse(axiosResponse.error || axiosResponse.message)
      else setResponse({ data: axiosResponse.data || axiosResponse, error: null })
    } catch (error) {
      console.log(error)
      handleErrorResponse(error)
    } finally {
      setLoading(false)
    }
  }

  const handleErrorResponse = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        showAlertModal({ message: 'Unauthorized Activity' })
      }
      const err = error.response.data
      if (!err.success) {
        console.log(err.success)
        showAlertModal(err)
      }
      setResponse({ data: null, error: err })
    } else if (error.request) {
      setResponse({ data: null, error: { message: 'Network error. Please try again.' } })
    } else {
      setResponse({ data: null, error: { message: 'An unexpected error occurred.' } })
    }
  }

  const sendRequest = async (url, authReq = true, method = 'GET', requestData = null) => {
    const headers = authReq ? await loadRefreshToken() : {}
    const config = { url: endPoint + url, method, headers, data: requestData }
    setMethod(method)
    setRequestConfig(config)
  }

  useEffect(() => {
    if (requestConfig) {
      fetchData()
    }
  }, [requestConfig])

  return { data: response.data, error: response.error, method, response, loading, sendRequest }
}

export default useAxios
