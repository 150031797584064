import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
// @mui
import { styled } from '@mui/material/styles'
import { Link, Container, Typography, Divider } from '@mui/material'
// hooks
import useResponsive from 'hooks/useResponsive'
// components
import Logo from 'components/logo'
// sections
import { LoginForm } from 'sections/forms'
import { BaseModal } from 'components/modal'
import { AUTH } from 'utils/helmet'

// ----------------------------------------------------------------------
import { signOutUser } from '../../firebase/auth-service'
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}))

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md')

  useEffect(() => {
    // sign out users from firebase lib
    signOutUser()
    window.localStorage.clear()
  }, [])

  return (
    <>
      <Helmet>
        <title> {AUTH} </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <BaseModal />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              TS CCMS Sign IN
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Contact Admin</Link>
            </Typography>

            <Divider sx={{ my: 3 }} />

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  )
}
