import { LoadingButton } from '@mui/lab'
import { Paper, Stack } from '@mui/material'
import contactModel from 'sections/models/contact'
import CustomInput from 'sections/common/CustomInput'

const CONTACT_FIELDS = Object.entries(contactModel)

export default function ContactSection ({ formValues, handleChange, handleRemoveContact, request, errors }) {
  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
      {formValues?.map((contact, index) => (
        <Stack
          key={index}
          sx={{
            border: '1px solid grey',
            padding: '10px',
            marginBottom: 2,
            borderRadius: 2,
          }}
          direction="column"
          spacing={2}
        >
          {CONTACT_FIELDS.map(([field, fieldDetail]) => (
            <CustomInput
              key={field}
              name={field}
              {...fieldDetail}
              formValue={contact[field]}
              handleInputChange={(e) => handleChange('contacts', { field: e.target.name, value: e.target.value }, index)}
              errors={errors?.contacts?.[index] || {}} // You need to implement error handling
              index={index}
            />
          ))}
          <LoadingButton
            loading={request.loading}
            onClick={() => handleRemoveContact(index)}
            type="button"
            variant="contained"
            color="secondary"
          >
            REMOVE
          </LoadingButton>
        </Stack>
      ))}
    </Paper>
  )
}
