export default {
  id: {
    label: 'Ticket No',
    type: 'string',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  author: {
    label: 'Author',
    type: 'email',
    isRequired: false,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false
  },
  assignee: {
    label: 'Assignee',
    type: 'email',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  cc: {
    label: 'CC',
    type: 'array',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: true
  },
  title: {
    label: 'Title',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  description: {
    label: 'Description',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  priority: {
    label: 'Priority',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      {
        label: 'Low',
        value: 'low'
      },
      {
        label: 'Medium',
        value: 'medium'
      },
      {
        label: 'High',
        value: 'high'
      }
    ],
    isMultiSelect: false
  },
  status: {
    label: 'Status',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      {
        label: 'To Do',
        value: 'todo'
      },
      {
        label: 'In Progress',
        value: 'in-progress'
      },
      {
        label: 'On Hold',
        value: 'on-hold'
      },
      {
        label: 'Completed',
        value: 'completed'
      }
    ],
    isMultiSelect: false
  }
}
