import { showHideModal, updateModalData } from 'redux/dispatcher/modalDispatch'

export const DEFAULT_OPTIONS = { data: '', method: 'GET' }
export const BACK_URL_COUNT = 'countBack'

/**
 * Create Axios Options obj
 *
 * @param {string} method
 * @param {Object} body
 * @return object
 */
export const buildOptions = (method = 'GET', body = '') => {
  const options = DEFAULT_OPTIONS
  options.method = method
  options.data = body
  return options
}

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)

export const applySortFilter = (array, comparator, query) => {
  if (!array) {
    return []
  }

  let filteredData = null

  if (query && typeof query === 'string' && query.length) {
    const searchValues = query
      .trim()
      .toLowerCase()
      .split(',')
      .map((value) => value.trim())
    const formattedSearchValues = searchValues.map((value) => value.replace(/ /g, '_'))

    filteredData = array.filter((data) =>
      // eslint-disable-next-line
      Object.entries(data).some(([key, value]) => {
        if (typeof value === 'string') {
          const formattedValue = value.toString().toLowerCase().replace(' ', '_')
          return formattedSearchValues.some((searchValue) => formattedValue.includes(searchValue))
        }
        if (typeof value === 'boolean') {
          const booleanValue = value ? 'Yes' : 'No'
          return searchValues.includes(booleanValue.toLowerCase())
        }
        return false
      })
    )
  }

  const stabilizedThis = filteredData?.map((el, index) => [el, index]) || array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const currentTime = () => {
  const time = new Date()
  let hour = time.getHours()
  let min = time.getMinutes()
  let sec = time.getSeconds()
  let amPm = 'AM'

  if (hour > 12) {
    hour -= 12
    amPm = 'PM'
  }
  if (hour === 0) {
    hour = 12
    amPm = 'AM'
  }

  hour = hour < 10 ? `0${hour}` : hour
  min = min < 10 ? `0${min}` : min
  sec = sec < 10 ? `0${sec}` : sec

  return `${hour}:${min}:${sec} ${amPm}`
}

export const navigateBackURL = (count = 0) => {
  if (count) {
    window.sessionStorage.setItem(BACK_URL_COUNT, count)
    return 0
  }
  const backURLCount = window.sessionStorage.getItem(BACK_URL_COUNT)
  if (backURLCount) {
    window.sessionStorage.removeItem(BACK_URL_COUNT)
    return parseInt(backURLCount, 10)
  }
  return 0
}

export const formatErrorMessage = (errors) => {
  let errorMessage = ''

  const formatError = (error) => {
    if (typeof error === 'string') {
      errorMessage += `* ${error}<br /><hr />`
    } else if (typeof error === 'object') {
      Object.entries(error).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          errorMessage += `* <b>${key.toUpperCase()}</b>: ${value.join(', ')}<br />`
        } else if (typeof value === 'object') {
          errorMessage += formatErrorMessage(value)
        } else {
          errorMessage += `* <b>${key.toUpperCase()}</b>: ${value}<br />`
        }
      })
      errorMessage += '<hr />'
    }
  }

  if (typeof errors === 'string') {
    return `* ${errors}`
  }

  if (typeof errors === 'object') {
    if (Array.isArray(errors)) {
      errors.forEach((error) => formatError(error))
    } else {
      formatError(errors)
    }
  }

  return errorMessage
}

export const showAlertModal = (err) => {
  const modalData = {}
  if (err?.data || err?.message) {
    modalData.show = true
    modalData.title = err?.title || 'Request Failed, See Below'
    modalData.body = formatErrorMessage(err?.data || err?.message)
    updateModalData(modalData)
    showHideModal(true)
  }
}

export const objectToQueryParams = (obj) => {
  const queryParams = []
  Object.keys(obj).forEach((key) => {
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(obj[key])
    queryParams.push(`${encodedKey}=${encodedValue}`)
  })

  return queryParams.join('&')
}

export const formatValue = (array) =>
  array.map((obj) => {
    const updatedObj = {}

    Object.entries(obj).forEach(([key, value]) => {
      if (key === 'label') {
        updatedObj[key] = value === ' ' ? 'empty' : value
      } else {
        updatedObj[key] = value === true ? 'yes' : value === false ? 'no' : value
      }
    })

    return updatedObj
  })

export const getUserCountry = () => {
  try {
    const locale = navigator.language || navigator.userLanguage
    const formatter = new Intl.DateTimeFormat(locale)
    const [country] = formatter.resolvedOptions().timeZone.split('/')

    return country
  } catch (error) {
    console.error('Failed to determine user country:', error)
    return null
  }
}

export const objectToCsv = (data) => {
  const csvRows = []
  const headers = Object.keys(data[0])

  // Generate CSV rows
  csvRows.push(headers.join(','))
  data.forEach((obj) => {
    const row = headers.reduce((acc, header) => {
      const value = obj[header]
      const escapedValue = value === null || value === undefined ? '' : String(value).replace(/"/g, '""')
      return [...acc, `"${escapedValue}"`]
    }, [])
    csvRows.push(row.join(','))
  })

  // Join all CSV rows
  return csvRows.join('\n')
}

function getCurrentDate() {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0')
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`
}

export const downloadCsv = (data, filename = `${getCurrentDate()}.csv`) => {
  const csvString = objectToCsv(data)
  const blob = new Blob([csvString], { type: 'text/csvcharset=utf-8' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.display = 'none'
  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export const loopWithInLimit = (value, limit = 10) => {
  if (value <= limit) return value
  return loopWithInLimit(value - limit)
}

export function hasAccess(access, level, exact = false) {
  const ROLES = ROLE.filter((item) => item.value !== null)
  const ACCESS = ROLES.map((item) => item.label.toLowerCase())
  if (!ACCESS.includes(level)) return false
  return exact ? access === ACCESS.indexOf(level) : access >= ACCESS.indexOf(level)
}

export const ROLE = [
  { label: 'Not-Allowed', value: null },
  { label: 'Read', value: 0 },
  { label: 'Edit', value: 1 },
  { label: 'Admin', value: 2 },
]

export const PAGE_IDS = {
  HOME: 'ts63v92w6dpage',
  KeyNote: 'ts63v21w6dpage',
  MsLicense: 'ts63v34w6dpage',
  Consultant: 'tst4jkqxtepage',
  Client: 'tsp2ftpi3apage',
  Vendor: 'tsa9c2x2nwpage',
  JDs: 'tsmyps91zvpage',
  User: 'ts11si1hpzpage',
  'User Logs': 'tsw79b5nxspage',
  'Asset Manager': 'tsh56k47hopage',
  Analytics: 'ts86m1imjrpage',
  'Support Ticket': 'ts32cg5drbpage',
  'Manage Leave': 'tsh12k98hopage',
  PMT: 'tsh12k22hopage',
  REIMBURSEMENT: 'tsh12k23hopage',
}
