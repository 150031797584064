import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Card, CardHeader } from '@mui/material'
import { yellow } from '@mui/material/colors'
// components
import { useChart } from '../../components/chart'

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392

const LEGEND_HEIGHT = 72

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}))

// ----------------------------------------------------------------------

AreaChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
}

export default function AreaChart({ title, subheader, chartData, ...other }) {
  const theme = useTheme()

  const chartLabels = chartData.map((i) => i.label?.replace(/_/g, ' ').toUpperCase())
  const chartValue = chartData.map((i) => i.data)

  const [data, setData] = useState(null)

  useEffect(() => {
    setData([{ name: chartData[0].name, data: chartValue }])
  }, [])

  const chartOptions = useChart({
    stroke: { width: 1 },
    fill: { opacity: 0.48 },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories: chartLabels,
    },
  })

  return (
    <>
      {data && (
        <Card {...other}>
          <CardHeader title={title} subheader={subheader} />

          <StyledChartWrapper dir="ltr" style={{ marginTop: '-40px' }}>
            <ReactApexChart type="radar" series={data} options={chartOptions} height={460} />
          </StyledChartWrapper>
        </Card>
      )}
    </>
  )
}
