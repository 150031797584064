import { useEffect, useState } from 'react'
// @mui
import {
  Box,
  Badge,
  Button,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  capitalize,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
// components
import Iconify from 'components/iconify'
// services
import { getNotifications } from 'services/notification'

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  // below eslint line should be removed when notifications feature is out
  // eslint-disable-next-line
  const [notifications, setNotifications] = useState(null)
  const [totalUnRead, setTotalUnRead] = useState(0)

  useEffect(() => {
    if (notifications) {
      setTotalUnRead(Object.keys(notifications).length)
    } else {
      setTotalUnRead(0)
    }
  }, [notifications])

  const [open, setOpen] = useState(null)

  const pullNotification = async () => {
    try {
      const { data } = await getNotifications()
      setNotifications(data)
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    pullNotification()
  }, [])

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleMarkAllAsRead = () => {
    setNotifications(null)
  }

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {notifications && (
          <>
            {Object.keys(notifications).map((notificationKey) => (
              <>
                <Box key={notificationKey} sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">{capitalize(notificationKey.replace(/_/g, ' '))}</Typography>
                    <List component="ul">
                      {notifications[notificationKey].map((message, index) => (
                        <ListItem component="li" key={index} sx={{ p: 0 }}>
                          <ListItemText
                            primary={
                              <>
                                {message.years_experience &&
                                  `${capitalize(message.firstName)} is celebrating their ${
                                    message.years_experience
                                  } year(s) with us.`}
                                {message.birthday &&
                                  `${capitalize(message.firstName)}'s birthday is on ${
                                    message.birthday
                                  } of this month.`}
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </>
            ))}
          </>
        )}

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Great Job
          </Button>
        </Box>
      </Popover>
    </>
  )
}
