import { Helmet } from 'react-helmet-async'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import useAxios from 'hooks/useAxios'
import { GET_ASSET } from 'services/url'
import DetailView from 'pages/AssetManager/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { LoadingButton } from '@mui/lab'
import { ASSETMANAGER } from 'utils/helmet'
import Acknowledgement from 'pages/AssetManager/Acknowledgement'
import { hasAccess } from 'utils'

export function AssetPage({ access }) {
  const [data, setData] = useState([])
  const [query, setQuery] = useState('')
  const [purchaseYear, setPurchaseYear] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })
  const [isAckOpen, setIsAckOpen] = useState(false)

  const handleAckOpen = (val) => {
    if (hasAccess(access, 'read', true)) {
      if (!val) {
        window.history.back()
      }
      setIsAckOpen(true)
    } else {
      setIsAckOpen(val)
    }
  }

  const clientRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  useEffect(() => {
    if (query === '' && purchaseYear === '') {
      fetchAllAssets()
    }
  }, [query, purchaseYear])

  useEffect(() => {
    fetchAllAssets()
  }, [paginator.page, paginator.rowsPerPage])

  const fetchAllAssets = () => {
    if (hasAccess(access, 'read', true)) {
      // NOTE: open acknowledge modal on page load
      setIsAckOpen(true)
      return
    }
    const startDate = `${purchaseYear}-01-01`
    const endDate = `${purchaseYear}-12-31`
    const getAssetList = GET_ASSET(
      paginator.rowsPerPage,
      paginator.page,
      query
        ? `&serial_number=${query}`
        : purchaseYear
        ? `&date_of_purchase_after=${startDate}&date_of_purchase_before=${endDate}`
        : ''
    )
    clientRequest.sendRequest(getAssetList)
  }

  const handleNewAsset = useCallback(() => {
    setSelectedData({ serial_number: '' })
  }, [])

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
  }, [])

  useEffect(() => {
    if (clientRequest.data) {
      setData(clientRequest.data.results)
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: clientRequest.data.count }))
    } else if (clientRequest.error) {
      console.error('Something is not correct with the client request')
    }
  }, [clientRequest.response])

  const resetFilter = () => {
    setQuery('')
    setPurchaseYear('')
  }

  const handleSearch = () => {
    fetchAllAssets()
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  // FIXME: NEED TO UPDATE SEARCH FUNCTIONALITY TO SEPARATE FILTER COMPONENT-
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  const renderSearchButtons = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2}>
      <Stack sx={{ display: 'flex', flexDirection: 'row' }} gap={2}>
        <TextField
          name="serial_number"
          label="Serial Number"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <TextField
          label="Purchase Year"
          value={purchaseYear}
          onChange={(e) => e.target.value.length <= 4 && setPurchaseYear(e.target.value)}
          type="number"
        />
      </Stack>
      <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          onClick={handleSearch}
          startIcon={<Iconify icon="eva:search-fill" />}
        >
          Search
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={resetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  )

  const renderMainContent = () => {
    if (clientRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length > 0) {
      return (
        <TableView
          data={data}
          columns={columns}
          selected={selected}
          handleSelect={handleSelect}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }

  const columns = useMemo(
    () => [
      { id: 'model_name', label: 'Model', alignRight: false },
      { id: 'serial_number', label: 'Serial Number', alignRight: false },
      { id: 'is_physical', label: 'Physical Asset', alignRight: true },
      { id: 'is_knocked_off', label: 'Knocked OFF', alignRight: true },
    ],
    []
  )

  return (
    <>
      <Helmet>
        <title> {ASSETMANAGER} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Assets</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={clientRequest.loading}
              variant="contained"
              onClick={() => handleAckOpen(true)}
            >
              Open Acknowledgement
            </LoadingButton>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={clientRequest.loading}
              variant="contained"
              onClick={fetchAllAssets}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleNewAsset}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Asset
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons()}</Card>

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent()}
        </Card>
        {
          // mount DetailView Component
          // only if selectedData is present
          Object.keys(selectedData).length > 0 && (
            <DetailView access={access} data={selectedData} fetchData={fetchAllAssets} close={handleCloseDetailPage} />
          )
        }
        {isAckOpen && <Acknowledgement open={isAckOpen} close={() => handleAckOpen(false)} />}
      </Container>
    </>
  )
}

AssetPage.prototype = {
  access: PropTypes.number.isRequired,
}

export default AssetPage
