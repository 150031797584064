import contact from "./contact"

export default {
  name: {
    label: 'Vendor Name',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  contacts: [contact],
  location: {
    label: 'Location',
    type: 'string',
  },
  sector: {
    label: 'Sector',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      {
        label: 'Private',
        value: 'private'
      },
      {
        label: 'Government',
        value: 'government'
      }
    ],
    isMultiSelect: false
  },
  rating: {
    label: 'Rating',
    type: 'rating',
    isRequired: true,
    isDisabled: false,
    min: 0,
    max: 5
  }
}
