export default {
  name: {
    label: 'Project Name',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  description: {
    label: 'Description',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  owner: {
    label: 'Project Owner',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
  type: {
    label: 'Project Type',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [{label: "External", value: "external"}, {label: "Internal", value: "internal"}],
    isMultiSelect: false
  },
}
