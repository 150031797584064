import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export function updateLeaveRequest(id, body) {
  return makeAPIRequest(`/update_leave_request/${id}`, buildOptions('PUT', body), true)
}

export function getLmsDetails(year) {
  return makeAPIRequest(`/my_lms_details/${year}`, buildOptions(), true)
}

export function putLmsPtoLock(id) {
  return makeAPIRequest(`/u/pto_lock/${id}`, buildOptions('PUT', {}), true)
}

export function createLeaveRequest(body) {
  return makeAPIRequest('/create_leave_request', buildOptions('POST', body), true)
}

export function createCompensation(id, body) {
  return makeAPIRequest(`/c/compensation/${id}`, buildOptions('POST', body), true)
}

export function uploadPublicHolidays(year, body) {
  return makeAPIRequest(`/public_holidays/${year}`, buildOptions('POST', body), true)
}

export function downloadMonthlyReport(month) {
  return makeAPIRequest(`/api/lms-report?month=${month}&year=2024`, buildOptions('GET'), true)
}
