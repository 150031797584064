import PropTypes from 'prop-types'
import { Card, Grid, Typography, CardContent, Stack, Divider } from '@mui/material'

// Define styles outside the component
const cardStyle = {
  position: 'relative',
  border: '1px solid #2065D1',
  p: 0,
  cursor: 'pointer',
}

const headerStackStyle = {
  backgroundColor: '#2065D1',
  p: 1,
  color: 'white',
}

const bodyStackStyle = {
  p: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const internalTagStyle = {
  backgroundColor: '#2065D1',
  color: 'white',
  p: 0.5,
  borderRadius: 1,
}

const descriptionStyle = {
  p: 1,
  color: '#004df6',
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  setSelectedData: PropTypes.func.isRequired,
}

function PmtCard({ data, setSelectedData }) {
  const handleClick = () => {
    setSelectedData(data)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={cardStyle} onClick={handleClick}>
        <CardContent sx={{ p: 0, m: 0 }}>
          <Stack sx={headerStackStyle}>
            <Typography variant="h5">{data.name}</Typography>
          </Stack>
          <Stack sx={bodyStackStyle}>
            <Typography variant="body2">{data.owner}</Typography>
            <Typography sx={internalTagStyle} variant="caption">{data.type.toUpperCase()}</Typography>
          </Stack>
          <Divider />
          <Stack sx={descriptionStyle}>
            <Typography component="span" variant="body2">
              {data.description.slice(0, 80)}...
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PmtCard
