import React, { useState, useEffect, useCallback } from 'react'
import PropTypes, { object } from 'prop-types'
import DetailView from 'sections/common/DetailView'
import { Paper, Typography, Alert } from '@mui/material'
import { hasAccess } from 'utils'
import useAxios from 'hooks/useAxios'
import { CREATE_CONSULTANT, UPDATE_CONSULTANT } from 'services/url'
import { LoadingButton } from '@mui/lab'
import { isValidEmail } from 'utils/inputValidations'
import RenderTextField from 'pages/Common/RenderTextField'
import {
  formStyle,
  CONSULTANT_FIELD_TYPES,
  FORM_FIELDS,
  FIELD_DEFINITION,
  INITIAL_FORM_VALUES,
} from 'pages/Consultant/utils'

const EditPage = ({ access, isSelfRecord, data, jobTitle, certification, fetchData, close }) => {
  const isEditMode = !!data?.id
  const [formValues, setFormValues] = useState({})
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const consultantRequest = useAxios()

  useEffect(() => {
    setDirty(false)
    setErrors({})
    setFormValues(INITIAL_FORM_VALUES(data || {}, FIELD_DEFINITION))
  }, [data])

  const validateForm = () => {
    const formErrors = {}
    let isValid = true

    CONSULTANT_FIELD_TYPES.required.forEach((field) => {
      if (!formValues[field]) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`
        isValid = false
      } else if (field === 'personal_email' && !isValidEmail(formValues[field])) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is not valid`
        isValid = false
      }
    })

    setErrors(formErrors)
    return isValid
  }

  useEffect(() => {
    if (consultantRequest.data) {
      fetchData()
      alert('Operation successful')
    } else if (consultantRequest.error) {
      console.log(consultantRequest.error)
    }
  }, [consultantRequest.response, consultantRequest.error])

  const reqFunc = (choice) => {
    setDirty(true)
    const isValid = validateForm()
    if (isValid) {
      if (isEditMode) {
        const keys = Object.keys(formValues)
        const shouldUpdate = keys.some((key) => data[key] !== formValues[key])
        if (shouldUpdate || data?.record_status !== choice) {
          const consultantUpdateURL = UPDATE_CONSULTANT(data.pipeline_no)
          const body = { ...data, ...formValues, record_status: choice }
          consultantRequest.sendRequest(consultantUpdateURL, true, 'PUT', body)
        }
      } else {
        consultantRequest.sendRequest(CREATE_CONSULTANT, true, 'POST', formValues)
      }
    }
  }

  const handleInputChange = useCallback((e) => {
    const { name, value, checked } = e.target
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: ['is_signature', 'is_hotlist', 'state_experience', 'is_available', 'is_referred_consultant'].includes(name) ? checked : value,
    }))
  }, [])

  const handleSubmit = (e, choice = 'draft') => {
    e.preventDefault(choice)
    reqFunc(choice)
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Consultant Form"
      body={
        <>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
            <form style={formStyle}>
              {FORM_FIELDS.map((field) => (
                <RenderTextField
                  key={field[0]}
                  name={field[0]}
                  label={field[1]}
                  formValues={formValues}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  fields={CONSULTANT_FIELD_TYPES}
                />
              ))}
              <RenderTextField
                name={'job_title'}
                label={'Job Title'}
                formValues={formValues}
                handleInputChange={handleInputChange}
                errors={errors}
                fields={{ option: { job_title: jobTitle } }}
              />
            </form>
            <RenderTextField
              name={'certification'}
              label={'Certification'}
              formValues={formValues}
              handleInputChange={handleInputChange}
              fields={{ multiSelect: ['certification'], option: { certification } }}
              errors={errors}
            />
          </Paper>
        </>
      }
      actions={
        <>
          {hasAccess(access, 'edit') && (
            <>
              {dirty && Object.keys(errors).length > 0 && (
                <Alert severity="error">Add Required Fields on Consultant Form</Alert>
              )}
              {['Draft', 'Review'].map((field) => (
                <LoadingButton
                  key={field}
                  loading={consultantRequest.loading}
                  onClick={(e) => handleSubmit(e, field)}
                  type="submit"
                  variant="contained"
                  color={field === 'Draft' ? 'secondary' : 'warning'}
                >
                  {field.toUpperCase()}
                </LoadingButton>
              ))}
              {hasAccess(access, 'admin') && !isSelfRecord && isEditMode && (
                <>
                  {['Rejected', 'Approved'].map((field) => (
                    <LoadingButton
                      key={field}
                      loading={consultantRequest.loading}
                      onClick={(e) => handleSubmit(e, field)}
                      type="submit"
                      variant="contained"
                      color={field === 'Approved' ? 'success' : 'error'}
                    >
                      {field.toUpperCase()}
                    </LoadingButton>
                  ))}
                </>
              )}
            </>
          )}
        </>
      }
    />
  )
}

EditPage.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default EditPage
