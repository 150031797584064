const HEADINGS = [
  'Education and Experience Requirements',
  'Preferred Skills and Qualifications',
  'Required Skills and Qualifications',
  'Computer Program Requirements',
  'Duties and Responsibilities',
  'Must Have Skills',
  'Nice To Have',
  'Our Impact ',
  'Roles and Responsibilities',
  'Scrum Master Education',
  'Tasks will Include',
  'Years of Experience',
  'Basic Qualifications',
  'Demonstrated Experience',
  'Desired Skills',
  'Education Qualification',
  'Education Qualifications',
  'Educational Experience',
  'Experience Required',
  'General Experience',
  'Important Notes',
  'Job Description',
  'Job Requirements',
  'Job Responsibilities',
  'Key Responsibilities',
  'Minimum Education',
  'Minimum Experience',
  'Minimum Qualifications',
  'Must Have',
  'Must-Have Requirements',
  'Preferred Certifications',
  'Preferred Qualifications',
  'Project Overview',
  'Required Skills',
  'Role Description',
  'Skills Required',
  'Specialized Experience',
  'Support Hours',
  'Work Experience',
  'Your Impact',
  'Certification',
  'Clearance',
  'Duties',
  'Education',
  'Experience',
  'Preferred',
  'Qualifications',
  'Requirements',
  'Responsibilities',
  'Technology',
]

// NOTE: WHEN ADDING NEW HEADINGS, ADD THEM TO THE END OF THE ARRAY IF ITS SINGLE WORD
// MATCHES ARE FOUND BASED ON THE ORDER OF THE ARRAY
export const formatDescription = (text) => {
  let formattedText = text.replace(/(\r\n|\n\n|\r\r)/g, '<br>')

  HEADINGS.forEach((heading) => {
    const regex = new RegExp(`(${heading}:)`, 'ig') // Use 'ig' to match case-insensitive globally
    formattedText = formattedText.replace(regex, '<b>$1</b>')
  })

  return formattedText
}
