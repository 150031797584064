const TEMPLATE = 'CCMS Portal'
export const ANALYTIC = `Analytics | ${TEMPLATE}`
export const PMT = `PMT | ${TEMPLATE}`
export const ASSETMANAGER = `Asset Management | ${TEMPLATE}`
export const AUTH = `Login | ${TEMPLATE}`
export const CLIENT = `Clients | ${TEMPLATE}`
export const CONSULTANT = `Consultants | ${TEMPLATE}`
export const HOME = `Dashboard | ${TEMPLATE}`
export const JDREQUIREMENTS = `JD Requirements | ${TEMPLATE}`
export const USER = `User | ${TEMPLATE}`
export const VENDOR = `Vendor | ${TEMPLATE}`
export const PAGE_404 = `Page Not Found | ${TEMPLATE}`
export const PAGE_401 = `Unauthorized Page | ${TEMPLATE}`
export const USER_USAGE_LOGS = `User Usage Log | ${TEMPLATE}`
export const SUPPORT_TICKET = `Support Ticket | ${TEMPLATE}`
export const LMS = `Leave Management System | ${TEMPLATE}`
export const REIMBURSEMENT = `Reimbursement Management System | ${TEMPLATE}`
