import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export function getUsersList() {
  return makeAPIRequest('/user/all', buildOptions())
}

export function getProfile() {
  return makeAPIRequest('/profile', buildOptions())
}

export function getUsersChoice() {
  return makeAPIRequest('/list/user/choice', buildOptions())
}

export function delegateAccess(email) {
  return makeAPIRequest(`/delegate/${email}`, buildOptions())
}

export function getUserById(id) {
  return makeAPIRequest(`/user/${id}`, buildOptions())
}

export function createUser(body) {
  return makeAPIRequest('/user/create', buildOptions('POST', body))
}

export function updateUserById(id, body) {
  return makeAPIRequest(`/user/${id}`, buildOptions('PUT', body))
}
