import { Stack, Typography, Container, Card } from '@mui/material'
import useAxios from 'hooks/useAxios'
import { useEffect, useMemo, useState } from 'react'
import { MS_LICENSE } from 'services/url'
import TableView from 'sections/table/TableView'
import ExcelToJsonConverter from 'components/converter/ExcelToJsonConverter'
import { hasAccess } from 'utils'

export default function MsLicense({ access }) {
  const { sendRequest, data, error, method, loading } = useAxios()
  const [jsonResult, setJsonResult] = useState([])
  const columns = useMemo(
    () => [
      // { id: 'id', label: 'ID', alignRight: false },
      // { id: 'created_date', label: 'Created Date', alignRight: false },
      { id: 'user_principal_name', label: 'User Principal Name', alignRight: false },
      // { id: 'display_name', label: 'Display Name', alignRight: false },
      // { id: 'first_name', label: 'First Name', alignRight: false },
      // { id: 'last_name', label: 'Last Name', alignRight: false },
      { id: 'licenses', label: 'Licenses', alignRight: false },
      // { id: 'block_credential', label: 'Block Credential', alignRight: false },
      // { id: 'is_deleted', label: 'Is Deleted', alignRight: false },
      // { id: 'mobile_phone', label: 'Mobile Phone', alignRight: false },
      // { id: 'strong_password_required', label: 'Strong Password Required', alignRight: false },
      { id: 'title', label: 'Title', alignRight: false },
      // { id: 'usage_location', label: 'Usage Location', alignRight: false },
    ],
    []
  )

  useEffect(() => {
    if (jsonResult.length > 0) {
      sendRequest(MS_LICENSE(), true, 'POST', jsonResult)
    }
  }, [jsonResult])

  useEffect(() => {
    if (method === 'POST' && data) {
      setJsonResult([])
      alert('Uploaded Ms License successfully')
    }
    if (error) {
      alert('Something went wrong with MS License')
    }
  }, [data, error, method])

  useEffect(() => {
    sendRequest(MS_LICENSE())
  }, [])

  const renderMainContent = () => {
    if (!data || loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length === 0) {
      return (
        <>
          <Typography variant="h6" textAlign="center" p={1}>
            No Results Found on DB
          </Typography>
        </>
      )
    }

    return <TableView hidePagination showEdit={false} data={data} columns={columns} />
  }

  return (
    <>
      <Typography variant="h4" textAlign="center" p={1}>
        Microsoft Outlook License Details
      </Typography>
      <Typography variant="body1" textAlign="center">
        Find all email accounts with license type attached to them
      </Typography>
      <Container sx={{ py: 3, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {hasAccess(access, 'admin') && (
          <Stack sx={{ pb: 2 }}>
            <ExcelToJsonConverter cta="Upload Latest MS License" setJsonResult={setJsonResult} />
          </Stack>
        )}
        <Card>{renderMainContent()}</Card>
      </Container>
    </>
  )
}
