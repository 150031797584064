export default {
  hours: {
    label: 'No of Hours',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4, min: 4},
  },
  reason: {
    label: 'Reason',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false
  },
}
