import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'

const Header = React.memo(({ rowCount, headLabel, numSelected, onSelectAllClick, basicTable }) => (
  <TableHead>
    <TableRow>
      {
        !basicTable &&
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
      }

      {headLabel.map(({ id, align = 'left', width, minWidth, label }) => (
        <TableCell key={id} align={align} sx={{ width, minWidth }}>
          {label}
        </TableCell>
      ))}

      <TableCell />
    </TableRow>
  </TableHead>
))

Header.propTypes = {
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
}

export default Header
