import { useEffect, useState } from 'react'
import {
  Button,
  Alert,
  Paper,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from '@mui/material'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import CustomInput from 'sections/common/CustomInput'
import baseLmsUserModel from 'sections/models/lms_user'
import baseLeaveReqModel from 'sections/models/leave_request'
import compensationModel from 'sections/models/compensation'
import { LIST_COMPENSATION, LIST_USER_LEAVE_REQUEST } from 'services/url'
import useUser from 'hooks/useUser'
import { createLeaveRequest, createCompensation, updateLeaveRequest, putLmsPtoLock } from 'services/lms'
import { convertEmailToUsername } from 'utils/common'
import { validateForm } from 'sections/validator'
import { hasAccess } from 'utils'
import { LoadingButton } from '@mui/lab'

export default function EditPage({ access, data, fetchData, close }) {
  const user = useUser()
  const leaveRequest = useAxios()
  const compensationRequest = useAxios()
  const [leaveReqModel, setLeaveReqModel] = useState(JSON.parse(JSON.stringify(baseLeaveReqModel)))
  const lmsUserModel = JSON.parse(JSON.stringify(baseLmsUserModel))

  const isSelf = data.user_email === user.email
  const [leaveMaxHours, setLeaveMaxHours] = useState(1000)
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState({})
  const [leaveReq, setLeaveReq] = useState({
    lms_user: data.id,
    start_date: '',
    end_date: '',
    leave_type: '',
    hours: 0,
    reason: '',
  })
  const [compensation, setCompensation] = useState({ lms_user: data.id, hours: 0, reason: '' })

  const addCompensation = async () => {
    try {
      setLoading(true)
      await validateForm(compensationModel, compensation, setErrors)
      const response = await createCompensation(data.id, compensation)
      if (response.success) {
        alert('Operation Successful, Compensation Added')
        fetchData()
        close()
      } else {
        alert('Operation Failed, Something wrong with Request')
      }
    } catch (error) {
      console.error('validation failed')
    } finally {
      setLoading(false)
    }
  }

  const applyLeaveRequest = async () => {
    try {
      setLoading(true)
      await validateForm(leaveReqModel, leaveReq, setErrors)
      const response = await createLeaveRequest(leaveReq)
      if (response.success) {
        alert('Operation Successful, Leave has been submitted')
        fetchData()
        close()
      } else {
        alert('Operation Failed, Something wrong with Request')
      }
    } catch (error) {
      console.error('validation failed')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    resetForm()
    if (data?.id) {
      const temp = JSON.parse(JSON.stringify(leaveReqModel))
      leaveRequest.sendRequest(LIST_USER_LEAVE_REQUEST(data.id))
      compensationRequest.sendRequest(LIST_COMPENSATION(data.id))

      const hasPTO = leaveReqModel.leave_type.optionList.some((lab) => lab.label === 'PTO')
      const hasCompOff = leaveReqModel.leave_type.optionList.some((lab) => lab.label === 'Comp Off')

      if (!data.pto_lock || (!hasPTO && data.remaining_pto > 0)) {
        temp.leave_type.optionList.push({ label: 'PTO', value: 'pto' })
      }
      if (!hasCompOff && data.earned_compensation > 0) {
        temp.leave_type.optionList.push({ label: 'Comp Off', value: 'comp-off' })
      }
      setLeaveReqModel({...temp})
    }
  }, [data])

  useEffect(() => {
    if (leaveReq.leave_type === 'pto' && data.pto_lock) {
      setLeaveMaxHours(data.remaining_pto)
    } else if (leaveReq.leave_type === 'comp-off') {
      setLeaveMaxHours(data.earned_compensation)
    }
  }, [leaveReq.leave_type])

  const resetForm = () => {
    setDirty(false)
    setErrors({})
    setFormValues({ ...data })
  }

  const updateStatus = async (id, newData) => {
    // NOTE: I think we don't need validation
    // but future me may think its needed
    try {
      await updateLeaveRequest(id, newData)
      alert('Operation Successful')
      fetchData()
      close()
    } catch (error) {
      console.error(error)
      alert('Operation Failed, Contact Admin')
    } finally {
      setLoading(false)
    }
  }

  const updatePtoLock = async (e) => {
    try {
      setLoading(true)
      setFormValues((prev) => ({ ...prev, pto_lock: e.target.checked }))
      await putLmsPtoLock(data.id)
      fetchData()
      alert('Operation Success, PTO Details Updated')
      close()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    setFormValues((prev) => ({ ...prev, [name]: name === 'pto_lock' ? checked : value }))
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title={`Manage ${convertEmailToUsername(data?.user_email)}'s Leave`}
      body={
        <>
          <Alert severity="warning" sx={{ mb: 1, width: 'fit-content', mx: 'auto' }}>
            Hours field are restricted to increments of 4, allowing you to adjust the hours only in multiples of 4.
          </Alert>
          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            <Accordion key={'lms-record'}>
              <AccordionSummary expandIcon={<></>}>
                <Typography variant="h6" textAlign="center">
                  {isSelf ? 'My' : convertEmailToUsername(data?.user_email)} LMS Record Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* {
                  !isSelf &&
                  <FormHelperText sx={{textAlign: 'center', fontWeight: 'bold'}}>PTO Lock Status: {formValues.pto_lock ? 'LOCKED' : 'UNLOCKED'}</FormHelperText>
                } */}
                <Grid container item xs={12} spacing={1}>
                  {hasAccess(access, 'admin') &&
                    Object.keys(lmsUserModel)
                      .filter((field) => field === 'pto_lock')
                      .map((field) => (
                        <Grid item xs={12} sm={3} key={field} my={'auto'}>
                          <CustomInput
                            name={field}
                            {...lmsUserModel[field]}
                            formValue={data[field]}
                            handleInputChange={updatePtoLock}
                            errors={errors}
                          />
                        </Grid>
                      ))}
                  {Object.keys(lmsUserModel)
                    .filter((field) => field !== 'pto_lock')
                    .map((field) => (
                      <Grid item xs={12} sm={3} key={field} my={'auto'}>
                        <CustomInput
                          name={field}
                          {...lmsUserModel[field]}
                          formValue={formValues[field]}
                          handleInputChange={handleChange}
                          // NOTE: Disabling for all
                          isDisabled={isSelf || true}
                          errors={errors}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            <Accordion key={'lms-record'}>
              <AccordionSummary expandIcon={<></>}>
                <Typography variant="h6" textAlign="center">
                  {isSelf ? 'Apply Leave' : 'Add Compensation'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container item xs={12} spacing={1}>
                  {isSelf ? (
                    <>
                      {Object.keys(leaveReqModel)
                        .filter((field) => field !== 'hours')
                        .map((field) => (
                          <Grid item xs={12} sm={3} key={field} my={'auto'}>
                            <CustomInput
                              name={field}
                              {...leaveReqModel[field]}
                              formValue={leaveReq[field]}
                              handleInputChange={(e) =>
                                setLeaveReq((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                              }
                              errors={errors}
                            />
                          </Grid>
                        ))}
                      {leaveReq?.leave_type !== '' && (
                        <Grid item xs={12} sm={3} key={'hours'} my={'auto'}>
                          <CustomInput
                            name={'hours'}
                            {...leaveReqModel.hours}
                            inputProps={{
                              step: 4,
                              max: leaveMaxHours,
                            }}
                            formValue={leaveReq.hours}
                            handleInputChange={(e) =>
                              setLeaveReq((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                            }
                            errors={errors}
                          />
                          <FormHelperText>Hours are dynamically validated based on leave type</FormHelperText>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      {Object.keys(compensationModel).map((field) => (
                        <Grid item xs={12} sm={3} key={field} my={'auto'}>
                          <CustomInput
                            name={field}
                            {...compensationModel[field]}
                            formValue={compensation[field]}
                            handleInputChange={(e) =>
                              setCompensation((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                            }
                            errors={errors}
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
                {
                  <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                    {!isSelf && (
                      <FormHelperText sx={{ mr: 2, color: 'red' }}>
                        Compensation will be cleared end of month if not used.
                      </FormHelperText>
                    )}
                    <LoadingButton loading={loading} variant="contained" onClick={isSelf ? applyLeaveRequest : addCompensation}>
                      Submit
                    </LoadingButton>
                  </Stack>
                }
              </AccordionDetails>
            </Accordion>
          </Paper>

          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            <Accordion key={'lms-record'}>
              <AccordionSummary expandIcon={<></>}>
                <Typography variant="h6" textAlign="center">
                  Manage Leave History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {leaveRequest.data?.length > 0 ? (
                  leaveRequest.data.map((leave) => (
                    <Accordion key={leave.id} sx={{ border: '1px solid grey' }}>
                      <AccordionSummary expandIcon={<></>} sx={{ borderBottom: '1px solid grey' }}>
                        <Typography
                          variant="body1"
                          component="span"
                          fontWeight="bold"
                          color={
                            leave.status === 'approved'
                              ? 'green'
                              : ['rejected', 'cancelled'].includes(leave.status)
                              ? 'red'
                              : 'blue'
                          }
                        >
                          Leave Type: {leave.leave_type.toUpperCase()} : {leave.start_date} to {leave.end_date} :{' '}
                          {leave.status.toUpperCase()}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack flexDirection={'row'} justifyContent={'space-between'}>
                          <Stack>
                            <Typography variant="body1">
                              <b>No of hours: </b>
                              {leave.hours}
                            </Typography>
                            <Typography variant="body1">
                              <b>Reason: </b>
                              {leave.reason}
                            </Typography>
                            {['approved', 'rejected'].includes(leave.status) && (
                              <Typography variant="body1">
                                <b>{leave.status[0].toUpperCase() + leave.status.slice(1)} by: </b>
                                {leave.status_changed_by}
                              </Typography>
                            )}
                          </Stack>
                          <Stack>
                            {leave.status === 'pending' &&
                              (leave.author === user?.email ? (
                                <LoadingButton loading={loading}
                                  variant="contained"
                                  size="small"
                                  onClick={() =>
                                    updateStatus(leave.id, {
                                      ...leave,
                                      status: 'cancelled',
                                      status_changed_by: user.email,
                                    })
                                  }
                                  color="error"
                                >
                                  Cancel
                                </LoadingButton>
                              ) : (
                                <Stack flexDirection={'row'} justifyContent={'flex-end'} py={2} gap={2}>
                                  <LoadingButton loading={loading}
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                      updateStatus(leave.id, {
                                        ...leave,
                                        status: 'approved',
                                        status_changed_by: user.email,
                                      })
                                    }
                                  >
                                    Approve
                                  </LoadingButton>
                                  <LoadingButton loading={loading}
                                    variant="contained"
                                    size="small"
                                    onClick={() => updateStatus(leave.id, { ...leave, status: 'rejected' })}
                                    color="error"
                                  >
                                    Reject
                                  </LoadingButton>
                                </Stack>
                              ))}
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography variant="body1" textAlign="center">
                    No Leave Record Found
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            <Accordion key={'lms-record'}>
              <AccordionSummary expandIcon={<></>}>
                <Typography variant="h6" textAlign="center">
                  Compensation Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {compensationRequest.data?.length > 0 ? (
                  compensationRequest.data.map((compensation) => (
                    <Accordion key={compensation.id} sx={{ border: '1px solid grey' }}>
                      <AccordionSummary expandIcon={<></>} sx={{ borderBottom: '1px solid grey' }}>
                        <Typography variant="body1" component="span" fontWeight="bold">
                          Created: {compensation.created.split('T')[0]} & Added By:{' '}
                          {convertEmailToUsername(compensation.author)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack flexDirection={'row'} justifyContent={'space-between'}>
                          <Typography variant="body1">
                            <b>Reason: </b>
                            {compensation.reason}
                          </Typography>
                          <Typography variant="body1">
                            <b>No of hours: </b>
                            {compensation.hours}
                          </Typography>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography variant="body1" textAlign="center">
                    No Compensation Record Found
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </>
      }
      actions={<></>}
    />
  )
}
