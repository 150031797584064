import React, { useEffect, useState } from 'react'
import { Grid, Alert, Paper } from '@mui/material'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import { initialFormValues } from 'utils/common'

import { SUPPORT_TICKET_CREATE, SUPPORT_TICKET_UPDATE } from 'services/url'
import { validateData } from 'sections/validator/supportTicket'
import model from 'sections/models/support_ticket'
import CustomInput from 'sections/common/CustomInput'
import Comment from 'sections/common/Comment'
import useUser from 'hooks/useUser'
import { getUsersChoice } from 'services/user'
import { LoadingButton } from '@mui/lab'

const EditPage = ({ access, data, fetchData, close }) => {
  const isEditMode = data.id
  const ticketRequest = useAxios()
  const userData = useUser()
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState({})
  const [emailChoice, setEmailChoice] = useState([])

  const initFormData = () => {
    setDirty(false)
    setErrors({})
    setFormValues(initialFormValues(data || {}, model))
  }

  useEffect(() => {
    initFormData()
    getUsersChoice().then(res => setEmailChoice(res.emails))
  }, [data])

  useEffect(() => {
    handleRequestResponse()
  }, [ticketRequest.response])

  const handleChange = (field, value) => {
    setFormValues(prevData => {
      return { ...prevData, [field]: value }
    })
  }

  const handleRequestResponse = () => {
    if (ticketRequest.data) {
      fetchData()
      alert('Operation Successful')
      close()
    } else if (ticketRequest.error) {
      console.error('Something is wrong with Ticket Request')
    }
    setLoading(false)
  }

  const submitComment = (comment) => {
    handleChange('comments', comment)
    reqFunc({...formValues, comments: comment})
  }

  const reqFunc = (tempData = null) => {
    if (loading) return
    const reqData = tempData || formValues
    setDirty(true)
    setLoading(true)
    const [err, valid] = validateData(reqData, model)
    setErrors(err)
    if (valid) {
      if (isEditMode) {
        const shouldUpdate = Object.keys(reqData).some(key => data[key] !== reqData[key])
        if (shouldUpdate) {
          const updateUrl = SUPPORT_TICKET_UPDATE(data.id)
          ticketRequest.sendRequest(updateUrl, true, 'PUT', { ...data, ...reqData })
        }
      } else {
        ticketRequest.sendRequest(SUPPORT_TICKET_CREATE, true, 'POST', reqData)
      }
    }
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Support Ticket"
      body={
        <>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CustomInput
                  key={'title'}
                  name={'title'}
                  formValue={formValues.title}
                  {...model.title}
                  handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                  errors={errors}
                />
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={8}>
                  <CustomInput
                    key={'description'}
                    name={'description'}
                    formValue={formValues.description}
                    {...model.description}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomInput
                    key={'author'}
                    name={'author'}
                    formValue={formValues.author || userData.email}
                    {...model.author}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                  <CustomInput
                    key={'assignee'}
                    name={'assignee'}
                    formValue={formValues.assignee}
                    {...model.assignee}
                    optionList={emailChoice.map(email => ({ label: email, value: email }))}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                  <CustomInput
                    key={'status'}
                    name={'status'}
                    formValue={formValues.status}
                    {...model.status}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                  <CustomInput
                    key={'priority'}
                    name={'priority'}
                    formValue={formValues.priority}
                    {...model.priority}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                  <CustomInput
                    key={'cc'}
                    name={'cc'}
                    formValue={formValues.cc}
                    {...model.cc}
                    optionList={emailChoice.map(email => ({ label: email, value: email }))}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
              </Grid>
            </Grid>
          </Paper>
          {
            isEditMode &&
            <Paper elevation={3} style={{ marginTop: '10px', padding: '20px' }}>
              <Comment author={userData.first_name} comments={formValues.comments || data.comments || []} setComments={submitComment} />
            </Paper>
          }
        </>
      }
      actions={
        <>
          {dirty && Object.keys(errors).length > 1 && (
            <Alert severity="error">Fill in all required fields</Alert>
          )}
          <LoadingButton loading={loading} onClick={() => reqFunc()} color="primary" variant="contained">
            Submit
          </LoadingButton>
        </>
      }
    />
  )
}

export default EditPage
