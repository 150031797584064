import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import useAxios from 'hooks/useAxios'
import { LIST_VENDOR } from 'services/url'
import DetailView from 'pages/Vendor/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { LoadingButton } from '@mui/lab'
import { Helmet } from 'react-helmet-async'
import { VENDOR } from 'utils/helmet'

export default function VendorPage({ access }) {
  const [data, setData] = useState([])
  const [query, setQuery] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })

  const vendorRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  useEffect(() => {
    if (query === '') {
      fetchAllVendors()
    }
  }, [query])

  useEffect(() => {
    fetchAllVendors()
  }, [paginator.page, paginator.rowsPerPage])

  const fetchAllVendors = () => {
    const getVendorList = LIST_VENDOR(paginator.rowsPerPage, paginator.page, query ? `&name=${query}` : '')
    vendorRequest.sendRequest(getVendorList)
  }

  const handleNewVendor = () => setSelectedData({ name: '' })

  const handleCloseDetailPage = () => setSelectedData({})

  useEffect(() => {
    if (vendorRequest.data) {
      setData(vendorRequest.data.results)
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: vendorRequest.data.count }))
    } else if (vendorRequest.error) {
      console.error('Something is not correct with the vendor request')
    }
  }, [vendorRequest.response])

  const resetFilter = () => {
    setQuery('')
  }

  const handleSearch = () => {
    fetchAllVendors()
  }

  const renderSearchButtons = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2}>
      <TextField
        name="name"
        label="Name"
        multiline
        fullWidth
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          onClick={handleSearch}
          startIcon={<Iconify icon="eva:search-fill" />}
        >
          Search
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={resetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  )

  const renderMainContent = () => {
    if (vendorRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length > 0) {
      return (
        <TableView
          data={data}
          columns={columns}
          selected={selected}
          handleSelect={handleSelect}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }

  const columns = useMemo(() => [{ id: 'name', label: 'Name', alignRight: false }], [])

  return (
    <>
      <Helmet>
        <title> {VENDOR} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Vendors</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={vendorRequest.loading}
              variant="contained"
              onClick={fetchAllVendors}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleNewVendor}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Vendor
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons()}</Card>

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent()}
        </Card>

        <DetailView key={selectedData?.name} access={access} data={selectedData} fetchData={fetchAllVendors} close={handleCloseDetailPage} />
      </Container>
    </>
  )
}
