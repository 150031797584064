const ASSET_TYPE_CHOICE = [
  { label: 'Laptop', value: 'laptop' },
  { label: 'Ipad', value: 'ipad' },
  { label: 'Mobile', value: 'mobile' },
]

const OWNER_CHOICE = [
  { label: 'TSI', value: 'TSI' },
  { label: 'TSPL', value: 'TSPL' },
]

const STATUS_CHOICE = [
  { label: 'ACTIVE', value: 'active' },
  { label: 'STALE', value: 'stale' },
]

const BILLABLE_TYPE_CHOICE = [
  { label: 'NA', value: 'na' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
]

export const ASSET_FIELD_TYPES = {
  disable: [],
  boolean: ['is_knocked_off', 'is_physical'],
  datePicker: ['date_of_purchase', 'warranty_expiry_date', 'support_expiry_date', 'last_bill_date'],
  option: {
    asset_type: ASSET_TYPE_CHOICE,
    owner: OWNER_CHOICE,
    status: STATUS_CHOICE,
    billable_type: BILLABLE_TYPE_CHOICE,
  },
  required: [
    'support_expiry_date',
    'asset_type',
    'owner',
    'status',
    'license_type',
    'support_email',
    'support_number',
    'serial_number',
    'model_name',
    'date_of_purchase',
    'warranty_expiry_date',
    'model_number',
    'configuration',
    'service_record',
    'description',
    'price',
    'comment',
  ],
}

export const USAGE_FIELD_TYPES = {
  disable: ['acknowledged_date'],
  boolean: [],
  option: {
    client: [],
  },
  datePicker: ['start_date', 'end_date', 'acknowledged_date'],
  required: ['email', 'name', 'client', 'start_date', 'feed_back', 'end_date'],
}

export const FORM_FIELDS = [
  ['is_physical', 'Physical'],
  ['is_knocked_off', 'Knocked Off'],
  ['status', 'Status'],
  ['asset_type', 'Asset Type'],
  ['serial_number', 'Serial Number'],
  ['model_name', 'Model Name'],
  ['model_number', 'Model Nuber'],
  ['configuration', 'Configuration'],
  ['description', 'Description'],
  ['price', 'Price'],
  ['date_of_purchase', 'Date Of Purchase'],
  ['warranty_expiry_date', 'Warranty Expiry Date'],
  ['support_expiry_date', 'Support Expiry Date'],
  ['last_bill_date', 'Last Billed Date'],
  ['owner', 'Owner'],
  ['billable_type', 'Billable Type'],
  ['license_type', 'License Type'],
  ['support_email', 'Support Email'],
  ['support_number', 'Support Number'],
  ['service_record', 'Service Record'],
  ['comment', 'Comment'],
]

export const USAGE_FIELDS = [
  ['email', 'User Email'],
  ['name', 'Name'],
  ['client', 'Client'],
  ['start_date', 'Start Date'],
  ['end_date', 'End Date'],
  ['feed_back', 'Feed Backs'],
  ['acknowledged_date', 'Acknowledged Date'],
  ['known_issue', 'Known Issues'],
]

export const ASSET_FIELD_DEFINITION = {
  is_physical: 'boolean',
  is_knocked_off: 'boolean',
  status: 'string',
  asset_type: 'string',
  serial_number: 'string',
  model_name: 'string',
  model_number: 'string',
  configuration: 'string',
  description: 'string',
  price: 'string',
  date_of_purchase: 'date',
  warranty_expiry_date: 'date',
  support_expiry_date: 'date',
  last_bill_date: 'date',
  owner: 'string',
  billable_type: 'string',
  license_type: 'string',
  support_email: 'string',
  support_number: 'string',
  service_record: 'string',
  comment: 'string',
}

export const USAGE_FIELD_DEFINITION = {
  email: 'string',
  name: 'string',
  client: 'id',
  start_date: 'date',
  end_date: 'date',
  acknowledged_date: 'date',
  known_issues: 'string',
  feed_back: 'string',
}

export const INITIAL_FORM_VALUES = (data, definition) =>
  Object.keys(definition).reduce((acc, fieldName) => {
    const fieldType = definition[fieldName]

    switch (fieldType) {
      case 'string':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'boolean':
        acc[fieldName] = data[fieldName] || false
        break
      case 'integer':
        acc[fieldName] = data[fieldName] || 0
        break
      case 'date':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'id':
        acc[fieldName] = data[fieldName] || null
        break
      case 'array':
        acc[fieldName] = data[fieldName] || []
        break
      default:
        acc[fieldName] = data[fieldName] || ''
    }

    return acc
  }, {})

export const formStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '0px 16px',
  alignItems: 'center',
}
