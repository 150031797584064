import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Stack, Typography, Button, Card, TextField, Checkbox, FormLabel } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import { useLocation } from 'react-router-dom'
import useAxios from 'hooks/useAxios'
import { JD_REQUIREMENTS_LIST, JD_REQUIREMENTS_CREATE } from 'services/url'
import DetailView from 'pages/JdRequirement/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { LoadingButton } from '@mui/lab'
import { hasAccess, objectToQueryParams } from 'utils'
import ExcelToJsonConverter from 'components/converter/ExcelToJsonConverter'
import { Helmet } from 'react-helmet-async'
import { JDREQUIREMENTS } from 'utils/helmet'

export default function JdReqPage({ access }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const jobTitleFilter = queryParams.get('job_title') || ''

  const [data, setData] = useState([])
  const [query, setQuery] = useState({ job_title: jobTitleFilter, is_active: true })
  const [state, setState] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })

  const setStateValue = (name, val) => setState((prevState) => ({ ...prevState, [name]: val }))

  useEffect(() => {
    bulkUpload()
  }, [state.jsonResult])

  const jdReqRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  const bulkUpload = () => {
    if (state?.jsonResult?.length > 0) {
      jdReqRequest.sendRequest(JD_REQUIREMENTS_CREATE, true, 'POST', state.jsonResult)
    }
  }

  useEffect(() => {
    const handleJdReqData = () => {
      if (jdReqRequest.data) {
        setData(jdReqRequest.data.results)
        setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: jdReqRequest.data.count }))
      } else if (jdReqRequest.error) {
        handleJdReqError()
      }
    }
    const handleJdReqError = () => {
      console.error('Something is wrong with JdReq Request')
    }

    if (state?.jsonResult?.length > 0) {
      if (jdReqRequest.data) {
        setStateValue('jsonResult', [])
        fetchAllJdReqs()
      } else {
        handleJdReqError()
      }
    } else {
      handleJdReqData()
    }
  }, [jdReqRequest.response])

  useEffect(() => {
    if (query === '') {
      fetchAllJdReqs()
    }
  }, [query])

  useEffect(() => {
    fetchAllJdReqs()
  }, [paginator.page, paginator.rowsPerPage])

  const fetchAllJdReqs = () => {
    const getJdReqList = JD_REQUIREMENTS_LIST(paginator.rowsPerPage, paginator.page, objectToQueryParams(query))
    jdReqRequest.sendRequest(getJdReqList)
  }

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
  }, [])

  const resetFilter = () => {
    setQuery('')
  }

  const handleSearch = () => {
    fetchAllJdReqs()
  }

  const renderSearchButtons = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2}>
      <TextField
        label="Job Requirement Title"
        value={query.job_title}
        fullWidth
        sx={{ maxWidth: '50%' }}
        onChange={(e) => setQuery((prev) => ({ ...prev, job_title: e.target.value }))}
      />

      <Stack direction="row">
        <Stack direction="row" alignItems="center" pl={2}>
          <Checkbox
            checked={query.is_active}
            onChange={(e) => setQuery((prev) => ({ ...prev, is_active: e.target.checked }))}
          />
          <FormLabel>Active</FormLabel>
        </Stack>
      </Stack>

      <Stack direction="row">
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          onClick={handleSearch}
          startIcon={<Iconify icon="eva:search-fill" />}
        >
          Search
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={resetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  )

  const renderMainContent = () => {
    if (jdReqRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length > 0) {
      return (
        <TableView
          data={data}
          columns={columns}
          selected={selected}
          handleSelect={handleSelect}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }

  const columns = useMemo(
    () => [
      // { id: 'client', label: 'Client', alignRight: false },
      // { id: 'labour_category', label: 'Labour Category', alignRight: false },
      { id: 'job_title', label: 'Jd Requirement Title', alignRight: false },
      { id: 'start_date', label: 'JD Start Date', alignRight: false },
      { id: 'end_date', label: 'JD End Date', alignRight: false },
      { id: 'is_active', label: 'Active', alignRight: false },
    ],
    []
  )

  return (
    <>
      <Helmet>
        <title> {JDREQUIREMENTS} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">JD Requirements</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={jdReqRequest.loading}
              variant="contained"
              onClick={fetchAllJdReqs}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            {isValidEditAccess() && (
              <ExcelToJsonConverter
                cta="Upload JD Req"
                setJsonResult={(e) => setStateValue('jsonResult', e)}
                setLoading={(e) => setStateValue('loading', e)}
              />
            )}
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons()}</Card>

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent()}
        </Card>

        <DetailView access={access} data={selectedData} fetchData={fetchAllJdReqs} close={handleCloseDetailPage} />
      </Container>
    </>
  )

  function isValidEditAccess() {
    return hasAccess(access, 'edit')
  }
}
