import { createSlice } from '@reduxjs/toolkit'

import { reducers } from 'redux/reducers/userReducer'

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers,
})

export const { updateUser, logOutUser } = userSlice.actions

export const user = (state) => state.user

export default userSlice.reducer
