// TODO: THIS FILE NEEDS REVAMP FOR ROLES INSTEAD OF PAGE BASED ACCESS

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import DetailView from 'sections/common/DetailView'
import { TextField, Paper, MenuItem, Typography } from '@mui/material'
import { hasAccess } from 'utils'
import RoleAccess from 'pages/User/RoleAccess'
import useAxios from 'hooks/useAxios'
import { CREATE_USER, UPDATE_ROLE, UPDATE_USER } from 'services/url'
import { LoadingButton } from '@mui/lab'
import { isValidEmail } from 'utils/inputValidations'

const GENDER = [
  { label: 'Others', value: 'Others' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
]

const REQUIRED_FIELD = ['email', 'first_name', 'last_name', 'gender']

const formStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  gap: '20px',
  alignItems: 'center',
}

const EditPage = ({ access, data, fetchData, close }) => {
  const isEditMode = !!data?.id
  const [formValues, setFormValues] = useState({})
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const roleRequest = useAxios()
  const userRequest = useAxios()

  useEffect(() => {
    setDirty(false)
    setErrors({})
    setFormValues({
      email: data.email || '',
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      gender: data.gender || '',
    })
  }, [data])

  const validateForm = () => {
    const formErrors = {}
    let isValid = true

    REQUIRED_FIELD.forEach((field) => {
      if (!formValues[field].length) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
        isValid = false
      } else if (field === 'email' && !isValidEmail(formValues[field], ['triwavesolutions.com'])) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is not valid`
        isValid = false
      }
    })

    setErrors(formErrors)
    return isValid
  }

  useEffect(() => {
    if (userRequest.data) {
      fetchData()
      alert('User Operation Successfully Completed')
    }
  }, [userRequest.response])

  const reqFunc = () => {
    setDirty(true)
    const isValid = validateForm()
    if (isValid) {
      if (isEditMode) {
        const keys = Object.keys(formValues)
        if (formValues?.roles) keys.pop()
        const shouldUpdate = keys.some((key) => data[key] !== formValues[key])
        if (shouldUpdate) {
          const userUpdateURL = UPDATE_USER(data.id)
          userRequest.sendRequest(userUpdateURL, true, 'PUT', (data = { ...data, ...formValues }))
        }
        if (formValues?.roles) {
          const roleUpdateURL = UPDATE_ROLE(data.id)
          roleRequest.sendRequest(roleUpdateURL, true, 'PUT', (data = formValues.roles))
        }
      } else {
        userRequest.sendRequest(CREATE_USER, true, 'POST', (data = formValues))
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    reqFunc()
  }

  const inputProps = useCallback(
    (name, label) => ({
      label,
      name,
      fullWidth: true,
      value: formValues[name],
      onChange: handleInputChange,
      margin: 'normal',
      error: !!errors[name],
      hidden: !errors[name],
      helperText: errors[name],
    }),
    [formValues, handleInputChange, errors]
  )

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Assign Page Based Access"
      body={
        <>
          {hasAccess(access, 'admin') ? (
            <Paper elevation={3} style={{ padding: '20px' }}>
              <RoleAccess id={data.id} data={formValues} setData={setFormValues} />
            </Paper>
          ) : (
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography textAlign={'center'}>YOU DON'T HAVE ACCESS TO VIEW THIS PAGE</Typography>
            </Paper>
          )}
        </>
      }
      actions={
        hasAccess(access, 'admin') ? (
        <LoadingButton
          loading={userRequest.loading || roleRequest.loading}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isEditMode ? 'Save Changes' : 'Create Account'}
        </LoadingButton>
        ) : (<></>)
      }
    />
  )
}

EditPage.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default EditPage
