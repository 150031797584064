import { useState, useEffect } from 'react'
import { Paper, Typography, TextField, Container, Button, Checkbox, MenuItem, Stack, FormControlLabel } from '@mui/material'
import { getBreakDownList, getFilterValueList } from 'services/analytic'

const minWidth = '200px'

const ManageFilters = ({cohort, filter, setFilter}) => {
  const [loading, setLoading] = useState(false)

  const [column, setColumn] = useState([])

  const fetchColumn = async () => {
    setLoading(true)
    const response = await getBreakDownList(cohort)
    setColumn(response.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchColumn()
  }, [cohort])

  const manageSelection = (id, checked, value) => {
    let santizedValues = filter[id].value.split(',')
    if (checked) {
      if (santizedValues[0] === '') {
        santizedValues[0] = value
      } else {
        santizedValues.push(value)
      }
    } else {
      santizedValues = santizedValues.filter(item => item !== value)
    }
    // remove duplicates
    santizedValues = [...new Set(santizedValues)]
    // check if value is valid option
    santizedValues= santizedValues.filter(value => Object.keys(filter[id].option).includes(value))

    setFilter(prev => ({
        ...prev,
        [id]: {
            ...prev[id],
            value: santizedValues.join(',')
        }
    }))
  }

  const loadFilterOptions = () => {
    if (Object.values(filter).length > 0) {
      Object.fromEntries(
        Object.entries(filter).map(([key, value]) => fetchSetColumnChips(key, value.column)
      ))
    }
  }

  useEffect(() => {
    loadFilterOptions()
  }, [])

  const updateFilter = (id, obj) => {
    setFilter({ ...filter, [id]: obj })
  }

  const addFilterStack = () => {
    const index = Object.keys(filter).length > 0 ? Math.max(...Object.keys(filter)) + 1 : 0
    updateFilter(index, { column: '', value: '', option: '',variant: 'include' })
  }

  const removeFilterStack = (id) => {
    delete filter[id]
    setFilter({...filter})
  }

  const updateVariant = async (id, value) => {
    setFilter(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        variant: value
      }
    }))
  }

  const fetchSetColumnChips = async (id, value) => {
    try {
      setLoading(true)
      const response = await getFilterValueList(cohort, value)
      setFilter(prev => ({
        ...prev,
        [id]: {
          ...prev[id],
          option: response.data,
          column: value
        }
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper>
      <Container>
        <Stack py={2} direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography variant='h6'>Manage Filters</Typography>
          <Button
            variant='contained'
            size='small'
            onClick={addFilterStack}
          >
            Add Filter +
          </Button>
        </Stack>
        {
          Object.keys(filter).map(
            index => {
              return (
                <Stack flexDirection={'column'} py={2} gap={2} key={index}>
                  <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
                    <TextField
                      sx={{ minWidth }}
                      size='small'
                      select
                      value={filter[index].column}
                      onChange={(e) => fetchSetColumnChips(index, e.target.value)}
                    >
                      <MenuItem value={''}>-</MenuItem>
                      { column?.map((column) => (
                        <MenuItem key={column} value={column} disabled={Object.values(filter).filter(obj => obj.column === column ).length > 0}>
                          {column?.split('_').join(' ')}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      size='small'
                      select
                      value={filter[index].variant}
                      onChange={(e) => updateVariant(index, e.target.value)}
                    >
                      { ['include', 'exclude']?.map((column) => (
                        <MenuItem key={column} value={column}>
                          {column.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      size='small'
                      color='error'
                      variant='contained'
                      onClick={() => removeFilterStack(index)}
                    >Delete</Button>
                  </Stack>
                  <Stack flexDirection={'row'} flexWrap={'wrap'}>
                  { filter[index]?.option &&
                    Object.keys(filter[index]?.option)?.map((column, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            sx={{ p: 0, m: 0 }}
                            checked={filter[index].value.split(',').includes(column)}
                            onChange={(e) => manageSelection(index, e.target.checked, column)}
                          />
                        }
                        sx={{ borderRadius: 5, mx: 1, p: 1, backgroundColor: '#e5e5e5' }}
                        label={filter[index]?.option[column]?.split('_').join(' ')} />
                    )) }
                  </Stack>
                </Stack>
              )
            }
          )
        }
      </Container>
    </Paper>
  )
}

export default ManageFilters
