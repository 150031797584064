import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Stack, Paper, Typography } from '@mui/material'
import { hasAccess } from 'utils'

const backgroundColor = '#D2E9FC'

const DialogTitleStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center', // Add this line if needed to match your background color
}

const titleContainerStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor,
  zIndex: 2,
}

const paperStyles = {
  padding: 2,
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  backgroundColor,
}

const DetailView = ({ access, open, onClose, title, body, actions, maxWidth='lg' }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
    <Paper sx={paperStyles}>
      <DialogTitle sx={titleContainerStyle}>
        <Stack sx={DialogTitleStyle}>
          <Box />
          <Typography variant="h5">{title.toUpperCase()}</Typography>
          <Button variant="contained" color="error" onClick={onClose}>
            X
          </Button>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{px: 3, pb: 0}}>{body}</DialogContent>

      {hasAccess(access, 'edit') && <DialogActions>{actions}</DialogActions>}
    </Paper>
  </Dialog>
)

DetailView.propTypes = {
  access: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
}

export default DetailView
