export default {
  pto_lock: {
    label: 'PTO Lock',
    type: 'boolean',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  remaining_pto: {
    label: 'Earned PTO (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  eligible_monthly_pto: {
    label: 'Eligible Monthly PTO (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4},
  },
  earned_compensation: {
    label: 'Earned Compensation (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4},
  },
  pto_cap: {
    label: 'PTO Cap (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4},
  },
}
