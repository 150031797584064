import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import DetailView from 'sections/common/DetailView'
import { Paper, Alert, Grid, Stack, Button } from '@mui/material'
import { hasAccess } from 'utils'
import useAxios from 'hooks/useAxios'
import { LoadingButton } from '@mui/lab'
import RenderTextField from 'pages/Common/RenderTextField'
import {
  formStyle,
  VENDOR_FIELDS_DEFINITION,
  INITIAL_FORM_VALUES,
  VENDOR_CONSULTANT_FIELD_TYPES,
  VENDOR_CONSULTANT_FIELDS
} from 'pages/Consultant/utils'
import {
  GET_CONSULTANT_VENDOR,
  CREATE_VENDOR_CONSULTANT,
  SUBMIT_VENDOR_CONSULTANT
} from 'services/url'

const PayRate = ({ id, access, data, close, vendor, fetchData, pipelineNo }) => {
  const [formValues, setFormValues] = useState([INITIAL_FORM_VALUES({}, VENDOR_FIELDS_DEFINITION)])
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState([{}])

  const payRateRequest = useAxios()

  const handleInputChange = useCallback((e, rowId) => {
    const { name, value } = e.target
    const updatedFormValues = [...formValues]
    updatedFormValues[rowId] = {
      ...updatedFormValues[rowId],
      [name]: value
    }
    setFormValues(updatedFormValues)
  }, [formValues])

  const addPayRateRow = () => {
    setFormValues([...formValues, INITIAL_FORM_VALUES({}, VENDOR_FIELDS_DEFINITION)])
    setErrors([...errors, {}])
  }

  useEffect(() => {
    setDirty(false)
    setErrors([{}])
    payRateRequest.sendRequest(GET_CONSULTANT_VENDOR(id))
    VENDOR_CONSULTANT_FIELD_TYPES.option.vendor_id = vendor
  }, [])

  const validateForm = () => {
    const formErrors = []
    let isValid = true

    formValues.forEach((row, index) => {
      const rowErrors = {}
      VENDOR_CONSULTANT_FIELD_TYPES.required.forEach((field) => {
        if (!row[field]) {
          rowErrors[field] = `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`
          isValid = false
        }
      })
      formErrors[index] = rowErrors
    })
    setErrors(formErrors)
    return isValid
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setDirty(true)
    if (validateForm()) {
      const updatedFormValues = formValues.map((row) => {
        const updatedFormValue = {}
        Object.keys(row).forEach((key) => {
          if (VENDOR_FIELDS_DEFINITION[key] === 'date' && row[key] === '') {
            updatedFormValue[key] = null
          } else {
            updatedFormValue[key] = row[key]
          }
        })
        return updatedFormValue
      })

      updatedFormValues.forEach((row) => {
        if (row?.id) {
          payRateRequest.sendRequest(SUBMIT_VENDOR_CONSULTANT(row.id), true, 'PUT', {
            ...row,
            consultant_id: pipelineNo,
          })
            .then(() => {
              alert('Operation Successful')
            })
            .catch((error) => {
              console.error("Error updating vendor consultant:", error)
            })
        } else {
          const formattedFormValue = { ...row }
          delete formattedFormValue.id
          payRateRequest.sendRequest(CREATE_VENDOR_CONSULTANT, true, 'POST', { ...formattedFormValue, consultant_id: pipelineNo })
            .then(() => {
              alert('Operation Successful')
              close()
            })
            .catch((error) => {
              console.error("Error creating vendor consultant:", error)
            })
        }
      })
    }
  }

  useEffect(() => {
    setDirty(false)
    setErrors([{}])
    if (payRateRequest.data) {
      const ifArray = Array.isArray(payRateRequest.data) ? payRateRequest.data.length > 0 : false
      setFormValues(
        ifArray
          ? payRateRequest.data.map((row) => INITIAL_FORM_VALUES(row, VENDOR_FIELDS_DEFINITION))
          : [INITIAL_FORM_VALUES(payRateRequest.data || {}, VENDOR_FIELDS_DEFINITION)]
      )
    } else if (payRateRequest.error) {
      console.error(payRateRequest.error)
    }
  }, [payRateRequest.response])

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="PayRate And Vendor Details"
      body={
        <>
          <Paper elevation={3} sx={{ padding: '20px', marginBottom: 1 }}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={addPayRateRow}>
                Add PayRate
              </Button>
            </Stack>
            <form style={formStyle} onSubmit={handleSubmit}>
              <Stack direction="column" spacing={2}>
                {formValues.map((row, rowId) => (
                  <Stack direction={'row'} spacing={2} sx={{ alignItems: 'baseline' }}>
                    {
                      VENDOR_CONSULTANT_FIELDS.map(field => (
                        <RenderTextField
                          key={field[0]}
                          name={field[0]}
                          label={field[1]}
                          formValues={row}
                          handleInputChange={(e) => handleInputChange(e, rowId)}
                          errors={errors}
                          fields={VENDOR_CONSULTANT_FIELD_TYPES}
                        />
                      ))
                    }
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                      <Button variant="outlined" onClick={close} size="medium">
                        Cancel
                      </Button>
                      {hasAccess(access, 'edit') && (
                        <>
                          {dirty && errors.some((error) => Object.keys(error).length > 0) && (
                            <Alert severity="error">Add Required Fields on Form</Alert>
                          )}
                          <LoadingButton
                            type="submit"
                            size={'medium'}
                            variant={'contained'}
                            loading={payRateRequest.loading}
                            onClick={(e) => handleSubmit(e)}
                          >
                            Save
                          </LoadingButton>
                        </>
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </form>
          </Paper>
        </>
      }
    />
  )
}

PayRate.propTypes = {
  access: PropTypes.number,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  pipelineNo: PropTypes.string.isRequired,
  jobTitle: PropTypes.array.isRequired,
  vendor: PropTypes.array.isRequired,
}

export default PayRate
