import { useEffect, useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
//
import Header from 'layouts/dashboard/header'
import Nav from 'layouts/dashboard/nav'
import { BaseModal, BlockUsageModal } from 'components/modal'
// hook
import useAxios from 'hooks/useAxios'
import useUser from 'hooks/useUser'

import { updateUserData } from 'redux/dispatcher/userDispatch'
import { PROFILE } from 'services/url'
import channel from 'broadCast'
import SupportIcon from 'components/support-icon'
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  backgroundColor: '#f3f3f3',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)
  const [block, setBlock] = useState(false)
  const profile = useAxios()

  useEffect(() => {
    channel.onmessage = (event) => {
      switch (event.data) {
        case 'anotherTabOpened':
          // set it here to make things clear for tracking
          sessionStorage.setItem('stopTracking', 'true')
          setBlock(true)
          break
        default:
          break
      }
    }
  }, [channel])

  const navigate = useNavigate()
  const userData = useUser()
  // length > 1 because of the default menu home page
  const loggedUser = userData ? Object.keys(userData).length > 1 : false

  useEffect(() => {
    profile.sendRequest(PROFILE)
  }, [])

  useEffect(() => {
    if (!loggedUser) navigate('/login', { replace: true })
  }, [loggedUser])

  useEffect(() => {
    if (profile.data) {
      const random = userData?.avatar ? userData?.avatar : Math.ceil(Math.random() * 4)
      const userState = { ...profile?.data, avatar: random }
      updateUserData(userState)
    } else if (profile.error) {
      console.error(profile.error)
    }
  }, [profile.response])

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <SupportIcon />
      <Main>
        <BlockUsageModal open={block} />
        <BaseModal />
        <Outlet />
      </Main>
    </StyledRoot>
  )
}
