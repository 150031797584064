import React from 'react'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Box,
  Chip,
  Typography,
  Rating
} from '@mui/material'
import { dateStyle, labelStyle } from 'utils/cssStyles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const CustomInput = ({ formValue, name, label, type, isRequired, isDisabled, optionList, isMultiSelect, restriction = {}, handleInputChange, errors, inputProps = {} }) => {
  const value = formValue ?? ''
  const booleanField = type === 'boolean'
  const datePickerField = type === 'date'
  const multiline = type === 'string' || type === 'text'
  const ratingField = type === 'rating'
  const numberField = type === 'number'

  const onBlur = (e) => {
    let value = null
    value = e.target.value
    // NOTE: LOGIC TO UPDATE VALUE ON OUT OF FOCUS
    // if (numberField && inputProps?.step) {
    //   value = Math.ceil(value / inputProps?.step) * inputProps?.step
    // }
    if (numberField && inputProps?.max) {
      value = value > inputProps?.max ? inputProps?.max : value
    }
    handleInputChange({ target: { name, value } })
  }

  const handleChange = (e) => {
    let isRestricted = false
    const maxLength = restriction?.maxLength || 1000
    let value = null
    value = e.target.value

    // Start Restriction Validations
    if (value.length > maxLength) isRestricted = true
    // End Restriction Validations

    if (!isRestricted) {
      handleInputChange({ target: { name, value } })
    }
  }

  return booleanField ? (
    <FormControlLabel
      control={
        <Checkbox
          checked={formValue}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
  ) : ratingField ? (
    <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ display: 'flex', alignItems: 'center', py: 1, pl: 1 }}>
      <Rating
        name={name}
        value={value}
        onChange={(event, value) => handleInputChange({ target: { name, value } })}
        precision={1}
      />
    </Stack>
  ) : datePickerField ? (
    <Stack>
      <InputLabel style={labelStyle} htmlFor={name}>{label}</InputLabel>
      <input
        id={name}
        type="date"
        name={name}
        style={{
          ...dateStyle,
          border: errors[name] ? '0.8px solid red' : '0.4px solid rgb(222, 222, 222)',
        }}
        value={value}
        onChange={handleChange}
      />
      <Typography style={{ color: 'red', fontSize: '12px' }}>{errors[name]}</Typography>
    </Stack>
  ) : isMultiSelect ? (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        required={isRequired}
        name={name}
        multiple
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
        error={errors ? !!errors[name] : false}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map(value => (
              <Chip key={value} label={optionList.find(item => item.value === value)?.label} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {optionList &&
          optionList.sort((a, b) => a.label.localeCompare(b.label)).map((optionList) => (
            <MenuItem key={optionList.value} value={optionList.value}>
              {optionList.label}
            </MenuItem>
          ))}
      </Select>
      {errors && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  ) : numberField ? (
    <TextField
      label={label}
      required={isRequired}
      name={name}
      type={type || "text"}
      multiline={multiline}
      fullWidth
      disabled={isDisabled}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      margin="normal"
      error={errors ? !!errors[name] : false}
      helperText={errors ? errors[name] : ''}
      select={!!optionList}
      inputProps={{
        min: 0,
        ...inputProps
      }}
    />
  ) : (
    <TextField
      label={label}
      required={isRequired}
      name={name}
      type={type || "text"}
      multiline={multiline}
      fullWidth
      disabled={isDisabled}
      value={value}
      onChange={handleChange}
      margin="normal"
      error={errors ? !!errors[name] : false}
      helperText={errors ? errors[name] : ''}
      select={!!optionList}
    >
      {optionList &&
        optionList.sort((a, b) => a.label.localeCompare(b.label)).map((optionList) => (
          <MenuItem key={optionList.value} value={optionList.value}>
            {optionList.label}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default CustomInput
