import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

export default function CustomCard({ title, gist, tags, description, setSelected }) {
  return (
    <Card
      sx={{ maxWidth: 320, m: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      onClick={() => setSelected({ title, description })}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2">{gist}</Typography>
      </CardContent>
      <CardActions>
        {tags.slice(0, 3).map((tag, index) => (
          <Chip key={`${index - tag}`} label={tag} />
        ))}
      </CardActions>
    </Card>
  )
}

CustomCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  gist: PropTypes.string,
  tags: PropTypes.array,
  setSelected: PropTypes.func,
}
