export default {
  is_suspended: {
    label: 'Suspend User',
    type: 'boolean',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  email: {
    label: 'Email Address',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  first_name: {
    label: 'First Name',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  last_name: {
    label: 'Last Name',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  gender: {
    label: 'Gender',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [
      { label: 'Others', value: 'Others' },
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
    ],
    isMultiSelect: false,
  },
  manager: {
    label: 'Manager',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [],
    isMultiSelect: false,
  },
  hr: {
    label: 'HR Partner',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [],
    isMultiSelect: false,
  },
  location: {
    label: 'Location',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [
      {label: 'INDIA', value: 'india'},
      {label: 'US', value: 'us'}
    ],
    isMultiSelect: false,
  },
  employee_type: {
    label: 'Employee Type',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [
      {label: 'Part Time', value: 'part_time'},
      {label: 'Full Time', value: 'full_time'},
      {label: 'Contractor', value: 'contractor'}
    ],
    isMultiSelect: false,
  },
  employee_status: {
    label: 'Employee Status',
    type: 'string',
    isRequired: true,
    isDisabled: true,
    optionList: [
      {label: 'Probation', value: 'probation'},
      {label: 'Confirmed', value: 'confirmed'}
    ],
    isMultiSelect: false,
  },
  eligible_monthly_pto: {
    label: 'Eligible Monthly PTO (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4},
  },
  pto_cap: {
    label: 'Maximum PTO earnable (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: {step: 4},
  },
  date_of_birth: {
    label: 'DOB',
    type: 'date',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false
  },
  date_of_joining: {
    label: 'Date of Joining',
    type: 'date',
    isRequired: true,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false
  },
}
