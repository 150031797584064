import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxios from 'hooks/useAxios'
import { JD_REQUIREMENTS_WIDGET } from 'services/url'
import { Box, Card, CardContent, Typography } from '@mui/material'

const Departments = () => {
  const navigate = useNavigate()
  const departmentRequest = useAxios()

  const handleDepartmentClick = (jobTitle) => {
    navigate(`/jd-requirement?job_title=${encodeURIComponent(jobTitle)}`)
  }

  useEffect(() => {
    departmentRequest.sendRequest(JD_REQUIREMENTS_WIDGET)
  }, [])

  return (
    <Card sx={{ my: 2, p: 1 }} variant="outlined">
      <Typography variant="h6" pb={2} textAlign={'center'}>
        RECENT DEPARTMENTS
      </Typography>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1}>
        {departmentRequest.data?.map((department, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{ cursor: "pointer" }}
            style={{ borderColor: index % 3 === 0 ? '#06F2F2' : '#0696F1' }}
            onClick={() => handleDepartmentClick(department.job_title)}
          >
            <CardContent sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
              <Typography variant="body2" style={{ fontSize: '16px', textAlign: 'center' }}>
                {department.job_title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Card>
  )
}

export default Departments
