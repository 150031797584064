import DetailView from 'sections/common/DetailView'
import projectModel from 'sections/models/project'
import {
  Stack,
  Paper,
  Grid,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { initialFormValues } from 'utils/common'
import CustomInput from 'sections/common/CustomInput'
import { LoadingButton } from '@mui/lab'
import { getUsersChoice } from 'services/user'
import { hasAccess } from 'utils'
import { createProject, getProjectMembers, updateProject, updateProjectMembers } from 'services/pmt'
import { validateForm } from 'sections/validator'

const PROJECT_USERS = {
  label: '',
  type: 'array',
  isRequired: false,
  isDisabled: false,
  optionList: null,
  isMultiSelect: true,
}

// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// FIXME: NONE OF API CALL IS BEING GOING TO CATCH
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------

const EditPage = ({ access, data, fetchData, close }) => {
  const isEditMode = !!data?.id
  const [errors, setErrors] = useState({})
  const [dirty, setDirty] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({})
  const [emailChoice, setEmailChoice] = useState([])
  const [projectUsers, setProjectUsers] = useState([])

  const initFormData = () => {
    setDirty(false)
    setErrors({})
    setFormValue(initialFormValues(data, projectModel))
  }

  useEffect(() => {
    initFormData()
    getUsersChoice().then((res) => setEmailChoice(res.emails))
    if (isEditMode) {
      getProjectMembers(data.id).then((res) => setProjectUsers(res.data))
    }
  }, [])

  const handleProjectDetails = async () => {
    try {
      setLoading(true)
      await validateForm(projectModel, formValue, setErrors)
      if (isEditMode) {
        await updateProject(data.id, formValue)
      } else {
        await createProject(formValue)
      }
      alert('Operation Successful, Project Details has been submitted')
      fetchData()
      close()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleProjectMembers = async () => {
    try {
      setLoading(true)
      // await validateForm(projectModel, formValue, setErrors)
      await updateProjectMembers(data.id, projectUsers)
      alert('Operation Successful, Project Members has been submitted')
      fetchData()
      close()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const isEditAllowed = hasAccess(access, 'edit')

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Project Details"
      body={
        <>
          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            <Stack container sx={{ p: 1 }}>
              <Grid item xs={12} sm={3} my={'auto'}>
                <CustomInput
                  name={'name'}
                  {...projectModel.name}
                  formValue={formValue.name}
                  handleInputChange={(e) => setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                  isDisabled={!isEditAllowed}
                  errors={errors}
                />
              </Grid>
              <Grid container item xs={12} spacing={2} display="flex" flexDirection="row" alignItems="center">
                <Grid item xs={12} sm={6}>
                  <CustomInput
                    name="owner"
                    {...projectModel.owner}
                    formValue={formValue.owner}
                    optionList={emailChoice.map((email) => ({ label: email, value: email }))}
                    handleInputChange={(e) => setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                    isDisabled={!isEditAllowed}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomInput
                    name="type"
                    {...projectModel.type}
                    formValue={formValue.type}
                    handleInputChange={(e) => setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                    isDisabled={!isEditAllowed}
                    errors={errors}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} my={'auto'}>
                <CustomInput
                  name={'description'}
                  {...projectModel.description}
                  formValue={formValue.description}
                  handleInputChange={(e) => setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                  isDisabled={!isEditAllowed}
                  errors={errors}
                />
              </Grid>
            </Stack>
            {isEditAllowed && (
              <Stack sx={{ p: 2, pt: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <LoadingButton
                  loading={loading}
                  onClick={handleProjectDetails}
                  variant="contained"
                  sx={{ width: 'fit-content' }}
                >
                  SUBMIT
                </LoadingButton>
              </Stack>
            )}
          </Paper>
          {isEditMode && (
            <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
              <Stack sx={{ p: 2 }}>
                <Typography textAlign={'center'} variant="h5">
                  Project Members
                </Typography>
                <CustomInput
                  {...PROJECT_USERS}
                  key={'users'}
                  formValue={projectUsers}
                  optionList={emailChoice.map((email) => ({ label: email, value: email }))}
                  handleInputChange={(e) => setProjectUsers(e.target.value)}
                  isDisabled={!isEditAllowed}
                  errors={errors}
                />
              </Stack>
              {isEditAllowed && (
                <Stack sx={{ p: 2, pt: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    loading={loading}
                    onClick={handleProjectMembers}
                    variant="contained"
                    sx={{ width: 'fit-content' }}
                  >
                    UPDATE
                  </LoadingButton>
                </Stack>
              )}
            </Paper>
          )}
        </>
      }
      actions={<></>}
    />
  )
}

export default EditPage
