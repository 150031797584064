import { useEffect, useState, useMemo } from 'react'
import {
  Paper,
  Stack,
} from '@mui/material'

import useAxios from 'hooks/useAxios'
import { LIST_PUBLIC_HOLIDAYS } from 'services/url'
import { uploadPublicHolidays } from 'services/lms'

import { hasAccess } from 'utils'

import TableView from 'sections/table/TableView'
import ExcelToJsonConverter from 'components/converter/ExcelToJsonConverter'
import DetailView from 'sections/common/DetailView'

export default function PublicHoliday({ show, access, close }) {
  const isAdmin = hasAccess(access, 'admin')
  const [uploading, setUploading] = useState(false)
  const columns = useMemo(() => [{ id: 'event', label: 'Event', alignRight: true, align: 'start' }, { id: 'date_str', label: 'Date', alignRight: true, align: 'start' }], [])
  const {sendRequest, data, error, loading} = useAxios()

  useEffect(() => {
    sendRequest(LIST_PUBLIC_HOLIDAYS('2024'))
  }, [])

  const uploadPublicHoliday = async (e) => {
    if (loading || uploading) return
    try {
      setUploading(true)
      uploadPublicHolidays('2024', e)
      alert('Successfully uploaded Public Holidays')
      close()
    } catch (error) {
      console.log(error)
    } finally {
      setUploading(false)
    }
  }

  return (
    <DetailView
      access={access}
      open={show}
      onClose={close}
      title={`Public Holidays for 2024`}
      maxWidth={'sm'}
      body={
        <>
          <Paper elevation={3} sx={{ px: 2, mb: 1 }}>
            {
              data &&
              <TableView
                hidePagination
                data={data}
                columns={columns}
                showEdit={false}
                selected={[]}
                handleSelect={() => {}}
                paginator={{}}
                setPaginator={() => {}}
                handleEdit={() => {}}
              />
            }
          </Paper>
        </>
      }
      actions={
        <>
          {
            isAdmin && (
              <ExcelToJsonConverter
                cta="Upload Public Holidays"
                setJsonResult={(e) => uploadPublicHoliday(e)}
                setLoading={setUploading}
              />
            )
          }
        </>
      }
    />
  )
}
