import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
// @mui
import { useTheme, styled } from '@mui/material/styles'
import { Card, CardHeader, Stack, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material'
// xlsx
import * as XLSX from 'xlsx'
// utils
import { fNumber } from 'utils/formatNumber'
// components
import { useChart } from 'components/chart'
// ----------------------------------------------------------------------

const CHART_HEIGHT = 300
const LEGEND_HEIGHT = 50

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT + 35,
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    position: 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array,
  breakDown: PropTypes.string,
}

export default function AppCurrentVisits({ title, subheader, chartData, breakDown, ...other }) {
  const BREAK_DOWN = breakDown?.replace(/_/g, ' ').toUpperCase()
  const theme = useTheme()
  const [showChart, setShowChart] = useState(true)
  const sortedData = chartData
    .map((item) => ({ ...item, label: item.label.replace(/_/g, ' ').toUpperCase() }))
    .sort((a, b) => b.value - a.value)

  const chartColors = [
    theme.palette.primary.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ]

  const chartLabels = sortedData.map((i) => i.label)
  const chartSeries = sortedData.map((i) => i.value)

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: true } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: true } } },
    },
  })

  const handleDownloadExcel = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(sortedData)
    ws.A1 = { t: 's', v: BREAK_DOWN || 'LABEL' }
    ws.B1 = { t: 's', v: 'COUNT' }
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1')
    XLSX.writeFile(wb, `${title}.xlsx`)
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction="row" justifyContent="center" spacing={2} marginBottom={1}>
        <Button variant="contained" onClick={() => setShowChart(!showChart)}>
          {showChart ? 'Show Table' : 'Show Chart'}
        </Button>
        <Button variant="contained" onClick={handleDownloadExcel}>
          Download Excel
        </Button>
      </Stack>
      <Stack display={"flex"} direction="row" spacing={2} justifyContent={"space-around"}>
        {showChart ? (
          <StyledChartWrapper dir="ltr" sx={{ width: '100%' }}>
            <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={CHART_HEIGHT - 24} />
          </StyledChartWrapper>
        ) : (
          <Stack sx={{ overflowY: 'auto', maxHeight: CHART_HEIGHT, marginBottom: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{BREAK_DOWN || 'LABEL'}</TableCell>
                  <TableCell>COUNT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label.toUpperCase().replace(/_/g, ' ')}</TableCell>
                    <TableCell>{fNumber(item.value)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
