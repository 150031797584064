import React, { useEffect, useState } from 'react'
import DetailView from 'sections/common/DetailView'
import {
  Stack,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Tooltip,
  IconButton,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Iconify from 'components/iconify'
import useAxios from 'hooks/useAxios'
import { getUsersForManager, postHandleTimeSheet } from 'services/pmt'
import useUser from 'hooks/useUser'

const LOCATION = [
  { label: 'WFH', value: 'wfh' },
  { label: 'On Premise', value: 'on-premise' },
  { label: 'On Shore', value: 'on-shore' },
]
const REASON = [
  { label: 'Internal Work', value: 'internal-work' },
  { label: 'Meeting', value: 'meeting' },
  { label: 'Others', value: 'others' },
]

const URL = (week, user = '') => `/timesheet${user ? `/${user}` : ''}?${week}`

const TimeSheet = ({ access, weekDropDown, projects, isSelf, close }) => {
  const user = useUser()
  const { data, error, sendRequest } = useAxios()
  const [showAlert, setShowAlert] = useState(false)
  const [entries, setEntries] = useState([
    {
      projectId: '',
      hours: { mon: '', tue: '', wed: '', thu: '', fri: '', sat: '', sun: '' },
      reason: '',
      location: '',
    },
  ])
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedWeek, setSelectedWeek] = useState(`from=${weekDropDown.at(-1)[0]}&to=${weekDropDown.at(-1).at(-1)}`)

  const [rangedDates, setRangedDates] = useState([])
  const [userChoice, setUserChoice] = useState([])
  const [isApprovedEntries, setIsApprovedEntries] = useState(false)
  const [isSubmittedEntries, setIsSubmittedEntries] = useState(false)
  const [totalHours, setTotalHours] = useState(0)
  const [validWeek, setValidWeek] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)

  useEffect(() => {
    fetchTSData()
  }, [selectedWeek, selectedUser])

  useEffect(() => {
    if (entries) {
      setIsApprovedEntries(entries.some((entry) => entry.approved_by))
      setIsSubmittedEntries(entries.some((entry) => entry.submitted))
      setTotalHours(
        entries.reduce((acc, entry) => {
          const hoursArray = Object.values(entry.hours).map(Number)
          const weeklyTotal = hoursArray.reduce((sum, hours) => sum + hours, 0)
          return acc + weeklyTotal
        }, 0)
      )
    }
  }, [entries])

  const saveTimeSheet = () => {
    const isValid = entries.every((entry) =>
      Object.entries(entry).every(([key, value]) => ['submitted', 'approved_by'].includes(key) || Boolean(value))
    )
    if (isValid && entries.length > 0) {
      setShowAlert(true)
      setLoading(true)
      sendRequest(URL(selectedWeek), true, 'POST', entries)
    } else {
      alert('All fields are required')
    }
  }

  const fetchTSData = (week = selectedWeek, user = selectedUser) => {
    setLoading(true)
    sendRequest(URL(week, user))
  }

  const fetchUserChoice = async () => {
    const response = await getUsersForManager()
    setUserChoice(response.data)
    setSelectedUser(response.data[0].id)
    fetchTSData(selectedWeek, response.data[0].id)
  }

  useEffect(() => {
    if (!isSelf) fetchUserChoice()
    else fetchTSData()
  }, [])

  useEffect(() => {
    if (data?.timesheet) {
      setEntries(data.timesheet)
      setValidWeek(data?.allow_action)
      setRangedDates(data.dates_between || [])
      if (data.timesheet.length > 0) setShowSubmit(true)
      if (showAlert) {
        setShowAlert(false)
        alert('Operation Successful, Timesheet Saved')
        close()
      }
    }
    if (data || error) setLoading(false)
  }, [data, error])

  const handleProjectChange = (index) => (event) => {
    const newEntries = [...entries]
    newEntries[index].projectId = event.target.value
    setEntries(newEntries)
  }

  const handleHoursChange = (index, day) => (event) => {
    const newEntries = [...entries]
    newEntries[index].hours[day] = event.target.value
    setEntries(newEntries)
  }

  const handleInputChange = (index, field) => (event) => {
    const newEntries = [...entries]
    newEntries[index][field] = event.target.value
    setEntries(newEntries)
  }

  const handleUserTS = async (status) => {
    setLoading(true)
    const userId = isSelf ? user.id : selectedUser
    const response = await postHandleTimeSheet({ user: userId, status }, selectedWeek)
    setLoading(false)
    if (response.success) {
      alert('Operation Successful!!')
      close()
    }
  }

  const addEntry = () => {
    if (loading) return
    setEntries([
      ...entries,
      {
        projectId: '',
        hours: { mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, sun: 0 },
        reason: '',
        location: '',
        isEdit: true,
      },
    ])
  }

  const removeEntry = (index) => {
    if (loading) return
    if (entries.length === 1) return
    const newEntries = [...entries]
    newEntries.splice(index, 1)
    setEntries(newEntries)
  }

  return (
    <DetailView
      access={access}
      open
      onClose={close}
      title="Project Details"
      body={
        <>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Grid container>
              <Grid item xs={8} display={'flex'} flexDirection={'row'}>
                <FormControl fullWidth sx={{ mr: 2 }}>
                  <InputLabel id="select-week-label">Selected Week</InputLabel>
                  <Select
                    size="small"
                    labelId="select-week-label"
                    id="select-week"
                    value={selectedWeek}
                    label="Week"
                    onChange={(e) => setSelectedWeek(e.target.value)}
                  >
                    {weekDropDown.map((week, index) => (
                      <MenuItem key={index} value={`from=${week[0]}&to=${week.at(-1)}`}>
                        {week[0]} to {week.at(-1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  {!isSelf && (
                    <>
                      <InputLabel id="select-user">Selected User</InputLabel>
                      <Select
                        size="small"
                        labelId="select-user"
                        id="select-week"
                        value={selectedUser}
                        label="Week"
                        onChange={(e) => setSelectedUser(e.target.value)}
                      >
                        {userChoice
                          .filter((choice) => choice.email !== user.email)
                          .map((user, index) => (
                            <MenuItem key={index} value={user.id}>
                              {user.email}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          {(isSelf || userChoice.length > 0) && (
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid container>
                  {entries.length > 0 ? (
                    <>
                      <Grid container mb={3} flexDirection={'row-reverse'}>
                        <Grid>
                          {' '}
                          <Typography variant="subtitle1">Total Hours: {totalHours}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mb={1}>
                        <Grid item xs={3} md={2}>
                          Project
                        </Grid>
                        <Grid item xs={3} md={2}>
                          Location
                        </Grid>
                        <Grid item xs={3} md={2}>
                          Reasons
                        </Grid>
                        {rangedDates.map((date, index) => (
                          <Grid item xs key={`date-range-${index}`}>
                            {date}
                          </Grid>
                        ))}
                        <Grid item xs />
                      </Grid>
                    </>
                  ) : (
                    <Typography sx={{ mx: 'auto' }}>No Entry Found in this Date Range</Typography>
                  )}
                  {entries.map((entry, index) => (
                    <Grid container spacing={1} key={index} mb={2}>
                      <Grid item xs={3} md={2}>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          id={`project-select-${index}`}
                          value={entry.projectId}
                          readOnly={!entry.isEdit}
                          onChange={handleProjectChange(index)}
                        >
                          {(entry.isEdit || projects.some(project => project.id === entry.projectId)) ? (
                            projects.map((project) => (
                              <MenuItem key={project.id} value={project.id}>
                                {project.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={entry.projectId} value={entry.projectId}>
                              NA
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            id={`location-select-${index}`}
                            value={entry.location}
                            readOnly={!entry.isEdit}
                            onChange={handleInputChange(index, 'location')}
                          >
                            {LOCATION.map((location) => (
                              <MenuItem key={location.value} value={location.value}>
                                {location.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            id={`reason-select-${index}`}
                            value={entry.reason}
                            readOnly={!entry.isEdit}
                            onChange={handleInputChange(index, 'reason')}
                          >
                            {REASON.map((reason) => (
                              <MenuItem key={reason.value} value={reason.value}>
                                {reason.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                        <Grid item xs key={`${day}-${index}`}>
                          <TextField
                            fullWidth
                            size="small"
                            type="number"
                            label={day}
                            variant="outlined"
                            value={entry.hours[day.toLowerCase()]}
                            onChange={handleHoursChange(index, day.toLowerCase())}
                            disabled={isApprovedEntries || isSubmittedEntries || !isSelf || !validWeek}
                          />
                        </Grid>
                      ))}
                      <Grid item xs>
                        {isSelf && validWeek && !isSubmittedEntries && !isApprovedEntries && (
                          <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => removeEntry(index)}>
                              <Iconify icon="eva:trash-2-fill" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: isSelf ? 'row' : 'row-reverse',
                    }}
                  >
                    {isApprovedEntries ? (
                      <Typography sx={{ mx: 'auto', fontWeight: 800 }} color={'green'}>
                        Time Sheet has been APPROVED
                      </Typography>
                    ) : isSelf ? (
                      isSubmittedEntries ? (
                        <Typography sx={{ mx: 'auto', fontWeight: 800 }} color={'green'}>
                          Time Sheet has been SUBMITTED
                        </Typography>
                      ) : (
                        validWeek && (
                          <>
                            <LoadingButton loading={loading} size="small" onClick={addEntry}>
                              Add Row
                            </LoadingButton>
                            {entries.length > 0 && (
                              <Stack direction="row" gap={2}>
                                <LoadingButton
                                  loading={loading}
                                  size="small"
                                  variant="contained"
                                  onClick={saveTimeSheet}
                                >
                                  Save
                                </LoadingButton>
                                {showSubmit && (
                                  <LoadingButton
                                    loading={loading}
                                    sx={{ color: 'white', backgroundColor: '#35BD4B' }}
                                    size="small"
                                    color="success"
                                    variant="contained"
                                    onClick={() => handleUserTS('submit')}
                                  >
                                    Submit
                                  </LoadingButton>
                                )}
                              </Stack>
                            )}
                          </>
                        )
                      ) // FIXME: below validWeek should be calculated based on Manager / HR
                    ) : validWeek && entries.length > 0 ? (
                      <Stack direction="row" gap={2}>
                        <LoadingButton
                          loading={loading}
                          color="error"
                          size="small"
                          variant="contained"
                          onClick={() => handleUserTS('reject')}
                        >
                          Reject
                        </LoadingButton>
                        <LoadingButton
                          loading={loading}
                          sx={{ color: 'white', backgroundColor: '#35BD4B' }}
                          size="small"
                          color="success"
                          variant="contained"
                          onClick={() => handleUserTS('approve')}
                        >
                          Approve
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </>
      }
      actions={<></>}
    />
  )
}

export default TimeSheet
