import { Helmet } from 'react-helmet-async'
import { useEffect, useState, useCallback } from 'react'
// @mui
import { Grid, Button, Container, Stack, Card, Typography } from '@mui/material'
// components
import { getCohortList } from 'services/analytic'
import Iconify from 'components/iconify'
import { AnalyticCard, AnalyticSearch } from 'sections/analytics'
import useAxios from 'hooks/useAxios'
import { ANALYTIC_LIST } from 'services/url'
import DetailView from 'pages/Analytic/DetailView'
import { ANALYTIC } from 'utils/helmet'
// service
// ----------------------------------------------------------------------

export default function AnalyticsPage({ access }) {
  const analyticRequest = useAxios()
  const [analytics, setAnalytics] = useState(null)
  const [filteredAnalytics, setFilteredAnalytics] = useState(null)
  const [selectedData, setSelectedData] = useState({})
  const [cohort, setCohort] = useState([])

  const applyFilter = (name) => {
    if (name.length > 0 && analytics !== null) {
      setFilteredAnalytics(analytics.filter((analytic) => name === analytic.name))
    } else {
      setFilteredAnalytics(analytics)
    }
  }

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
  }, [])

  const fetchData = async () => {
    analyticRequest.sendRequest(ANALYTIC_LIST)
  }

  const createNewEntry = () => setSelectedData({ name: '' })

  useEffect(() => {
    if (analyticRequest.data) {
      setAnalytics(analyticRequest.data)
    } else if (analyticRequest.error) {
      console.error('Something went wrong with Analytic List')
    }
  }, [analyticRequest.response])

  useEffect(() => {
    fetchData()
    getCohortList().then((res) => setCohort(res.data))
  }, [])

  useEffect(() => {
    setFilteredAnalytics(analytics)
  }, [analytics])

  return (
    <>
      <Helmet>
        <title> {ANALYTIC} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Analytics List
          </Typography>
          <Button onClick={createNewEntry} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Report
          </Button>
        </Stack>
        {filteredAnalytics?.length > 0 && (
          <>
            <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
              <AnalyticSearch analytics={analytics} applyFilter={applyFilter} />
            </Stack>
            <Grid container spacing={3}>
              {filteredAnalytics.map((analytic) => (
                <AnalyticCard key={analytic.id} analytic={analytic} setSelectedData={setSelectedData} />
              ))}
            </Grid>
          </>
        )}
      </Container>
      <DetailView
        access={access}
        cohort={cohort}
        data={selectedData}
        fetchData={fetchData}
        close={handleCloseDetailPage}
      />
    </>
  )
}
