import { Paper, Typography, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

import ccms from 'pages/KeyNotes/notes/ccms'
// import triwave from 'pages/KeyNotes/notes/triwave'
import CustomCard from 'pages/KeyNotes/card'
import { useState } from 'react'
import DetailView from 'sections/common/DetailView'

const StyledSection = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}))

export default function KeyNote({ access }) {
  const { type } = useParams()
  const [selected, setSelected] = useState('')

  return (
    <StyledSection>
      <Typography variant="h4" textAlign="center" p={1}>
        {type === 'ccms' ? 'Client Consultant Management System KeyNotes' : 'Triwave Solutions Team KeyNotes'}
      </Typography>
      <Typography variant="body1" textAlign="center">
        You can find highlights and {type === 'ccms' ? 'keys features' : 'details for day to day operations'}
      </Typography>
      <Container maxWidth="lg" sx={{ py: 3, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {ccms.map((entry, index) => (
          <CustomCard
            key={index}
            title={entry.title}
            gist={entry.gist}
            tags={entry.tags}
            description={entry.description}
            setSelected={setSelected}
          />
        ))}
      </Container>
      <DetailView
        key={selected}
        access={access}
        open={!!selected}
        onClose={() => setSelected('')}
        title={selected.title || ''}
        body={
          <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: selected?.description }}></div>
          </Paper>
        }
        actions={<></>}
      />
    </StyledSection>
  )
}
