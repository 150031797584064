import { useEffect, useState } from 'react'

import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'

import { showHideModal } from 'redux/dispatcher/modalDispatch'
import useModal from 'hooks/useModal'

const style = {
  p: 4,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overflow: 'scroll',
  overflowY: 'hidden',
  maxHeight: '90%',
  overflowX: 'hidden',
}

export default function BaseModal() {
  const modalData = useModal()

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    showHideModal(false)
  }

  useEffect(() => {
    setOpen(modalData.show)
  }, [modalData])

  return (
    <div>
      <Modal
        aria-labelledby="base-modal-title"
        aria-describedby="base-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="base-modal-title" variant="h6" component="h2">
              {modalData.title}
            </Typography>
            <Typography
              id="base-modal-description"
              sx={{ mt: 2, mb: 5 }}
              dangerouslySetInnerHTML={{
                __html: modalData.body,
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
