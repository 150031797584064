import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Paper, Alert, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DetailView from 'sections/common/DetailView'
import { hasAccess } from 'utils'
import useAxios from 'hooks/useAxios'
import { CREATE_VENDOR, UPDATE_VENDOR } from 'services/url'
import model from 'sections/models/vendor'
import contactModel from 'sections/models/contact'
import CustomInput from 'sections/common/CustomInput'
import useUser from 'hooks/useUser'
import { initialFormValues } from 'utils/common'
import { validateData } from 'sections/validator/clientVendor'
import ContactSection from 'pages/Common/ContactSection'

const VENDOR_FIELDS = Object.entries(model)
const contactStr = JSON.stringify(
  Object.keys(contactModel).reduce((acc, key) => {
    acc[key] = ''
    return acc
  }, {})
)

const EditPage = ({ access, data, fetchData, close }) => {
  const isEditMode = !!data?.id
  const userData = useUser()
  const [formValues, setFormValues] = useState({
    name: '',
    contacts: [JSON.parse(contactStr)],
    location: '',
    sector: '',
    rating: 0
  })

  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const vendorRequest = useAxios()

  const initFormData = () => {
    setDirty(false)
    setErrors({})
    if (isEditMode) setFormValues(initialFormValues(data, model))
  }

  useEffect(() => {
    initFormData()
  }, [data])

  useEffect(() => {
    handleRequestResponse()
  }, [vendorRequest.response])

  const handleRequestResponse = () => {
    if (vendorRequest.data) {
      fetchData()
      alert('Operation successful')
    } else if (vendorRequest.error) {
      console.error('Something is wrong with Vendor Request')
    }
  }

  const reqFunc = () => {
    setDirty(true)
    const [err, valid] = validateData(formValues, model)
    setErrors(err)
    if (valid) {
      if (isEditMode) {
        const shouldUpdate = Object.keys(formValues).some(key => data[key] !== formValues[key])
        if (shouldUpdate) {
          const vendorUpdateURL = UPDATE_VENDOR(data.id)
          vendorRequest.sendRequest(vendorUpdateURL, true, 'PUT', { ...data, ...formValues })
        }
      } else {
        vendorRequest.sendRequest(CREATE_VENDOR, true, 'POST', formValues)
      }
    }
  }

  const handleAddContact = () => {
    setFormValues(prevData => ({
      ...prevData,
      contacts: [...prevData.contacts, JSON.parse(contactStr)],
    }))
  }

  const handleRemoveContact = index => {
    setFormValues(prevData => {
      const updatedContacts = [...prevData.contacts]
      updatedContacts.splice(index, 1)
      return { ...prevData, contacts: updatedContacts }
    })
  }

  const handleChange = (field, value, index = 0) => {
    setFormValues(prevData => {
      if (field === 'contacts') {
        const updatedContacts = [...prevData.contacts]
        updatedContacts[index][value.field] = value.value
        return { ...prevData, contacts: updatedContacts }
      }
      return { ...prevData, [field]: value }
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    reqFunc()
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Vendor Form"
      body={
        <>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
            {VENDOR_FIELDS.map(([field, fieldDetail]) => (field !== 'contacts') && (
              <CustomInput
                key={field}
                name={field}
                formValue={formValues[field]}
                {...fieldDetail}
                handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                errors={errors}
              />
            ))}
          </Paper>
          <ContactSection
            formValues={formValues.contacts}
            handleChange={handleChange}
            handleRemoveContact={handleRemoveContact}
            request={vendorRequest}
            errors={errors}
          />
        </>
      }
      actions={<>{isValidAccess() && renderActions()}</>}
    />
  )

  function isValidAccess() {
    return hasAccess(access, 'edit')
  }

  function renderActions() {
    return (
      <>
        {dirty && Object.keys(errors).length > 1 && (
          <Alert severity="error">Add Required Fields on Vendor / Contact Form</Alert>
        )}
        {(!isEditMode || isValidAdminAccess() || isAuthor()) && (
          <>
            <Button onClick={handleAddContact}>Add New Contact</Button>
            <LoadingButton
              loading={vendorRequest.loading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="secondary"
            >
              SAVE
            </LoadingButton>
          </>
        )}
      </>
    )
  }

  function isValidAdminAccess() {
    return hasAccess(access, 'admin')
  }

  function isAuthor() {
    return data.author === userData.email
  }
}

EditPage.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default EditPage
