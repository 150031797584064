import React, { useState, useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import DashboardLayout from 'layouts/dashboard'
import SimpleLayout from 'layouts/simple'
import LoginPage from 'pages/Auth/LoginPage'
import Page404 from 'pages/Page404'
import Page401 from 'pages/Page401'
import DashboardAppPage from 'pages/Home/DashboardAppPage'
import UserListPage from 'pages/User/ListPage'
import ConsultantListPage from 'pages/Consultant/ListPage'
import ClientListPage from 'pages/Client/ListPage'
import VendorListPage from 'pages/Vendor/ListPage'
import JdRequirementPage from 'pages/JdRequirement/ListPage'
import AnalyticsPage from 'pages/Analytic/ListPage'
import AssetManagerPage from 'pages/AssetManager/ListPage'
import UserLogs from 'pages/UserLogs/ListPage'
import SupportTicketsPage from 'pages/SupportHub/ListPage'
import LeaveManagementPage from 'pages/Lms/ListPage'
import ProjectManagementPage from 'pages/Pmt/ListPage'
import KeyNote from 'pages/KeyNotes'
import MsLicense from 'pages/MsLicense'
import Reimbursement from 'pages/Reimbursement'
// --------------------------------------------------------------------
import useAxios from 'hooks/useAxios'
import { MENU } from 'services/url'
import { combinedMenu } from 'utils/common'
import navConfig from 'layouts/dashboard/nav/config'
import { user } from 'redux/userSlice'
import { useSelector } from 'react-redux'
import { updateUserData } from 'redux/dispatcher/userDispatch'
import { PAGE_IDS } from 'utils'
// ----------------------------------------------------------------------

const ProtectedRoute = ({ element, identifier }) => {
  const users = useSelector(user)
  const accessObject = users?.menu?.find((item) => item.identifier === identifier)
  const access = accessObject ? accessObject.access : null
  if (access !== null) {
    return React.cloneElement(element, { access })
  }

  return <Navigate to="/401" />
}

export default function Router() {
  const [routesConfig, setRoutesConfig] = useState(null)
  const menu = useAxios()
  const users = useSelector(user)

  useEffect(() => {
    if (menu.data) {
      const removeIcon = navConfig.map(({ icon, ...rest }) => rest)
      const preparedMenu = combinedMenu(removeIcon, menu.data)
      updateUserData({ ...users, menu: preparedMenu })
    } else if (menu.error) {
      console.error(menu.error)
    }
  }, [menu.response])

  useEffect(() => {
    menu.sendRequest(MENU)
  }, [])

  useEffect(() => {
    if (users?.menu?.length > 0) {
      const newRoutesConfig = [
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/home" />, index: true },
            {
              path: 'home',
              element: <ProtectedRoute element={<DashboardAppPage />} identifier={PAGE_IDS.HOME} />,
            },
            {
              path: 'keynote/:type',
              element: <ProtectedRoute element={<KeyNote />} identifier={PAGE_IDS.KeyNote} />,
            },
            {
              path: 'ms-license',
              element: <ProtectedRoute element={<MsLicense />} identifier={PAGE_IDS.MsLicense} />,
            },
            {
              path: 'users',
              element: <ProtectedRoute element={<UserListPage />} identifier={PAGE_IDS.User} />,
            },
            {
              path: 'consultants',
              element: <ProtectedRoute element={<ConsultantListPage />} identifier={PAGE_IDS.Consultant} />,
            },
            {
              path: 'clients',
              element: <ProtectedRoute element={<ClientListPage />} identifier={PAGE_IDS.Client} />,
            },
            {
              path: 'vendors',
              element: <ProtectedRoute element={<VendorListPage />} identifier={PAGE_IDS.Vendor} />,
            },
            {
              path: 'jd-requirement',
              element: <ProtectedRoute element={<JdRequirementPage />} identifier={PAGE_IDS.JDs} />,
            },
            {
              path: 'analytic',
              element: <ProtectedRoute element={<AnalyticsPage />} identifier={PAGE_IDS.Analytics} />,
            },
            {
              path: 'assets',
              element: <ProtectedRoute element={<AssetManagerPage />} identifier={PAGE_IDS['Asset Manager']} />,
            },
            {
              path: 'user/log',
              element: <ProtectedRoute element={<UserLogs />} identifier={PAGE_IDS['User Logs']} />,
            },
            {
              path: 'support',
              element: <ProtectedRoute element={<SupportTicketsPage />} identifier={PAGE_IDS['Support Ticket']} />,
            },
            {
              path: 'leave-management-system',
              element: <ProtectedRoute element={<LeaveManagementPage />} identifier={PAGE_IDS['Manage Leave']} />,
            },
            {
              path: 'project-management-system',
              element: <ProtectedRoute element={<ProjectManagementPage />} identifier={PAGE_IDS.PMT} />,
            },
            {
              path: 'reimb-management-system',
              element: <ProtectedRoute element={<Reimbursement />} identifier={PAGE_IDS.REIMBURSEMENT} />,
            },
          ],
        },
        {
          element: <SimpleLayout />,
          children: [
            { element: <Navigate to="/dashboard" />, index: true },
            { path: '401', element: <Page401 /> },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" /> },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ]
      setRoutesConfig(newRoutesConfig)
    }
  }, [users?.menu])

  const routes = useRoutes(routesConfig || []) // Ensure it's not null

  return routes
}
