// Library Import
import { signInWithCustomToken, signInWithEmailAndPassword, signOut } from 'firebase/auth'

// Local Import
import { auth } from './index'

export const emailSignIn = (email, password) => signInWithEmailAndPassword(auth, email, password)

export const delegatedAccess = (customToken) => signInWithCustomToken(auth, customToken)

export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      console.log('Sign-out successful')
    })
    .catch((error) => {
      console.error('An error happened ', error)
    })
}
