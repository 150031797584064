import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material'
//
import { NAV_WIDTH } from 'layouts/utils'

// hooks
import useResponsive from 'hooks/useResponsive'
import useUser from 'hooks/useUser'
// components
import Scrollbar from 'components/scrollbar'
import NavSection from 'components/nav-section'
import Logo from 'components/logo/Logo'
import FooterSection from 'pages/Common/FooterSection'
// ----------------------------------------------------------------------

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: '#283149',
}))

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation()
  const userData = useUser()

  const isDesktop = useResponsive('up', 'lg')

  const avatar = `/assets/images/avatars/${userData?.gender?.toLowerCase()}/avatar_${userData?.avatar}.jpg`

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <Box sx={{ mx: 2.5, mt: 2 }}>
          <Link underline="none">
            <StyledAccount>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <Logo />
                <Typography variant="h5" sx={{ color: 'white' }}>
                  CCMS
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>

        {userData?.menu && <NavSection data={userData.menu} />}
      </Scrollbar>
      <FooterSection />
    </>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              borderRadius: '0 32px 32px 0',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              borderRadius: '0 32px 32px 0',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
