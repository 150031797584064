import PropTypes from 'prop-types'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, IconButton, Alert } from '@mui/material'
// utils
import { bgBlur } from 'utils/cssStyles'
// components
import Iconify from 'components/iconify'
//
import AccountPopover from 'layouts/dashboard/header/AccountPopover'
import LanguagePopover from 'layouts/dashboard/header/LanguagePopover'
import { NAV_WIDTH } from 'layouts/utils'
// import Searchbar from 'layouts/dashboard/header/Searchbar'
import NotificationsPopover from 'layouts/dashboard/header/NotificationsPopover'
import SvgColor from 'components/svg-color'

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 92

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  // backgroundColor: '#f3f3f3',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  borderRadius: '32px',
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        {process.env?.REACT_APP_ENV !== 'production' && (
          <Alert severity="warning" style={{ width: 'fit-content' }}>
            "{process.env?.REACT_APP_ENV.toUpperCase()}" environment
          </Alert>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 1,
            sm: 4,
          }}
          sx={{
            p: 1,
            mx: 2,
            borderRadius: 2,
          }}
        >
          <NotificationsPopover />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 1,
            sm: 4,
          }}
          sx={{
            backgroundColor: '#283149',
            padding: 1,
            borderRadius: 2,
          }}
        >
          <IconButton
            sx={{ width: 40, height: 40, color: 'white' }}
            onClick={() => {
              window.location.href = '/user/log'
            }}
          >
            <Iconify icon="eva:clock-fill" />
          </IconButton>
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  )
}
