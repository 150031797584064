import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography, Paper } from '@mui/material'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import { JD_REQUIREMENTS_UPDATE } from 'services/url'
import { formatDescription } from 'pages/JdRequirement/utils'
import CustomInput from 'sections/common/CustomInput'
import { redirectToConsultant } from 'utils/common'

const model = {
  start_date: {
    label: 'Jd Requirement Start Date',
    type: 'date',
    is_required: true,
    optionList: null,
    isDisabled: false,
    isMultiSelect: false,
  },
  end_date: {
    label: 'Jd Requirement End Date',
    type: 'date',
    is_required: true,
    optionList: null,
    isDisabled: false,
    isMultiSelect: false,
  },
}

const EditPage = ({ access, data, fetchData, close }) => {
  const { sendRequest, data: reqData, error } = useAxios()
  const [dateField, setDateField] = useState({ start_date: '', end_date: '' })
  const [description, setDescription] = useState(data?.job_description)

  useEffect(() => {
    handleRequestResponse()
  }, [reqData, error])

  const handleRequestResponse = () => {
    if (reqData) {
      fetchData()
      close()
    } else if (error) {
      console.error('Something is wrong with JdReq Request')
    }
  }

  const updateOperation = () => {
    const jdReqUpdateURL = JD_REQUIREMENTS_UPDATE(data.id)
    const body = { ...data, ...dateField }
    sendRequest(jdReqUpdateURL, true, 'PUT', body)
  }

  useEffect(() => {
    const text = data?.job_description

    setDateField({ start_date: data?.start_date || '', end_date: data?.end_date || '' })

    if (text) {
      setDescription(formatDescription(text))
    }
  }, [data])

  const removeUnderScore = (value) => value?.replace(/_/g, ' ')

  if (!data) {
    return null
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="JD Requirements"
      body={
        <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
          <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
            {Object.entries(model).map(([key, value]) => (
              <Grid item md={6}>
                <CustomInput
                  keys={key}
                  name={key}
                  formValue={dateField[key]}
                  {...value}
                  errors={{}}
                  handleInputChange={(e) => setDateField((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                />
              </Grid>
            ))}
            <Grid item md={6}>
              <Typography variant="body1">
                <b>Client:</b> {removeUnderScore(data.client)}
              </Typography>
            </Grid>
            {data.labour_category ? (
              <Grid item md={6}>
                <Typography variant="body1">
                  <b>Labour Category:</b> {removeUnderScore(data.labour_category)}
                </Typography>
              </Grid>
            ) : (
              <Grid item md={6} />
            )}
            <Grid item md={6}>
              <Typography variant="body1">
                <b>Job Title:</b> {removeUnderScore(data.job_title)}
              </Typography>
            </Grid>
            {data.bill_rate ? (
              <Grid item md={6}>
                <Typography variant="body1">
                  <b>Bill Rate:</b> ${data.bill_rate}/hr
                </Typography>
              </Grid>
            ) : (
              <Grid item md={6} />
            )}

            <Grid item xs={12} mt={1}>
              <Typography variant="h6" gutterBottom>
                Job Description:
              </Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      }
      actions={
        <>
          <Button
            onClick={() => redirectToConsultant(data.job_title.split(' ')[0])}
            color="primary"
            variant="contained"
          >
            Find Matching Consultant
          </Button>
          <Button onClick={updateOperation} color="primary" variant="contained">
            Update
          </Button>
        </>
      }
    />
  )
}

export default EditPage
