import PropTypes from 'prop-types'

// @mui
import { styled } from '@mui/material/styles'
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material'
// components
import Iconify from 'components/iconify'

// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
})

// ----------------------------------------------------------------------

AnalyticsSearch.propTypes = {
  analytics: PropTypes.array.isRequired,
  applyFilter: PropTypes.func,
}

export default function AnalyticsSearch({ analytics, applyFilter }) {
  return (
    <Autocomplete
      sx={{ width: 280 }}
      autoHighlight
      popupIcon={null}
      PopperComponent={StyledPopper}
      options={analytics}
      getOptionLabel={(analytics) => analytics.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search boards..."
          onBlur={(e) => applyFilter(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
