import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Paper, Alert, Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DetailView from 'sections/common/DetailView'
import { hasAccess } from 'utils'
import useAxios from 'hooks/useAxios'
import { CREATE_ASSET, UPDATE_ASSET, GET_USAGE_HISTORY, UPDATE_USAGE_HISTORY } from 'services/url'
import { isValidEmail } from 'utils/inputValidations'
import RenderTextField from 'pages/Common/RenderTextField'
import {
  formStyle,
  ASSET_FIELD_TYPES,
  FORM_FIELDS,
  ASSET_FIELD_DEFINITION,
  INITIAL_FORM_VALUES,
  USAGE_FIELD_TYPES,
  USAGE_FIELDS,
} from 'pages/AssetManager/utils'
import useUser from 'hooks/useUser'
import { listClient } from 'services/client'

const USAGE_OBJ = JSON.stringify({
  email: '',
  name: '',
  start_date: '',
  end_date: '',
  feed_back: '',
})

const USAGE_PAPER_STYLE = (errors) => ({
  padding: '20px',
  marginBottom: 10,
  border: errors ? '4px solid red' : '',
})

const EditPage = ({ access, data, fetchData, close }) => {
  const isEditMode = !!data?.id
  const userData = useUser()
  const [formValues, setFormValues] = useState({})
  const [usage, setUsage] = useState([])
  const [usageInitial, setUsageInitial] = useState([])
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [fieldType, setFieldType] = useState(USAGE_FIELD_TYPES)
  const assetRequest = useAxios()
  const usageRequest = useAxios()

  useEffect(() => {
    initFormData()
    // TODO: Calling entire client here,
    // but we shouldn't be doing this. when time optimise this logic
    listClient().then((res) => {
      setFieldType((prevData) => ({
        ...prevData,
        option: {
          ...prevData.option,
          client: res.data.map((c) => ({ label: c.name, value: c.id })),
        },
      }))
    })
  }, [data])

  const initFormData = () => {
    setDirty(false)
    setErrors({})
    setFormValues({ ...INITIAL_FORM_VALUES(data, ASSET_FIELD_DEFINITION) })
    if (isEditMode && data.id) {
      usageRequest.sendRequest(GET_USAGE_HISTORY(data.id))
    }
  }

  useEffect(() => {
    if (assetRequest.response) {
      handleRequestResponse()
    }
    if (usageRequest.response) {
      handleUsageResponse()
    }
  }, [assetRequest.response, usageRequest.response])

  const handleUsageResponse = () => {
    if (usageRequest.data) {
      setUsage(usageRequest.data)
      setUsageInitial(usageRequest.data)
      if (usageRequest.method !== 'GET') alert('Asset Usage successful')
    } else if (usageRequest.error) {
      console.error('Something is wrong with Usage Request')
    }
  }

  const handleRequestResponse = () => {
    if (assetRequest.data) {
      fetchData()
      if (assetRequest.method !== 'GET') {
        fetchData()
        close()
        alert('Asset Details Updated Successful')
      }
    } else if (assetRequest.error) {
      console.error('Something is wrong with Asset Request')
    }
  }

  const validateForm = () => {
    const formErrors = { usage: false }
    let isValid = true

    const validateField = (field, value, errorMessage, index) => {
      if (!value) {
        if (index !== undefined) {
          formErrors.usage = true
        } else {
          formErrors[field] = errorMessage
        }
        isValid = false
      } else if (field === 'email' && !isValidEmail(value)) {
        if (index !== undefined) {
          formErrors.usage = true
        } else {
          formErrors[field] = errorMessage
        }
        isValid = false
      }
    }

    ASSET_FIELD_TYPES.required.forEach((field) => {
      validateField(
        field,
        formValues[field],
        `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`
      )
    })
    if (isEditMode && usage.length > 0) {
      USAGE_FIELD_TYPES.required.forEach((field) => {
        usage.forEach((history, index) => {
          validateField(
            field,
            history[field],
            `${field.charAt(0).toUpperCase() + field.replace(/_/g, ' ').slice(1)} is required`,
            index
          )
        })
      })
    }

    setErrors(formErrors)
    return isValid
  }

  const reqFunc = () => {
    setDirty(true)
    const isValid = validateForm()
    if (isValid) {
      if (isEditMode) {
        const formShouldUpdate = Object.keys(formValues).some((key) => data[key] !== formValues[key])
        if (formShouldUpdate) {
          const assetUpdateURL = UPDATE_ASSET(data.id)
          const body = { ...data, ...formValues }
          assetRequest.sendRequest(assetUpdateURL, true, 'PUT', body)
        }
        const usageShouldUpdate = Object.keys(usage).some((key) => usageInitial[key] !== usage[key])
        if (usageShouldUpdate) {
          usageRequest.sendRequest(UPDATE_USAGE_HISTORY(data.id), true, 'PUT', usage)
        }
      } else {
        assetRequest.sendRequest(CREATE_ASSET, true, 'POST', formValues)
      }
    }
  }

  const handleAddUsage = () => {
    setUsage((prevUsage) => [...prevUsage, JSON.parse(USAGE_OBJ)])
  }

  const handleRemoveUsage = (index) => {
    setUsage((prevUsage) => prevUsage.filter((_, i) => i !== index))
  }

  const handleUsageChange = (e, index) => {
    const { name, value } = e.target
    setUsage((prevUsage) => prevUsage.map((history, i) => (i === index ? { ...history, [name]: value } : history)))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    reqFunc()
  }

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: name.includes('is_') ? checked : value,
    }))
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Asset Form"
      body={
        <>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: 10 }}>
            <form style={formStyle}>
              {FORM_FIELDS.map((field) => (
                <RenderTextField
                  key={field[0]}
                  name={field[0]}
                  label={field[1]}
                  formValues={formValues}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  fields={ASSET_FIELD_TYPES}
                />
              ))}
            </form>
          </Paper>
          {usage.length > 0 && (
            <Paper elevation={3} style={USAGE_PAPER_STYLE(errors.usage)}>
              {usage.map((history, index) => (
                <Stack
                  key={index}
                  sx={{
                    border: '1px solid grey',
                    padding: '10px',
                    marginBottom: 2,
                    borderRadius: 2,
                  }}
                  direction="column"
                  spacing={2}
                >
                  {USAGE_FIELDS.map((field) => (
                    <RenderTextField
                      key={field[0]}
                      name={field[0]}
                      label={field[1]}
                      formValues={history}
                      handleInputChange={(e) => handleUsageChange(e, index)}
                      errors={false}
                      fields={fieldType}
                      index={index}
                    />
                  ))}
                  <Stack flexDirection={'row-reverse'} gap={5}>
                    <LoadingButton
                      loading={assetRequest.loading || usageRequest.loading}
                      onClick={() => handleRemoveUsage(index)}
                      type="button"
                      variant="contained"
                      color="error"
                    >
                      REMOVE
                    </LoadingButton>
                  </Stack>
                </Stack>
              ))}
            </Paper>
          )}
        </>
      }
      actions={<>{isValidAccess() && renderActions()}</>}
    />
  )

  function isValidAccess() {
    return hasAccess(access, 'edit')
  }

  function renderActions() {
    return (
      <>
        {dirty && Object.keys(errors).length > 1 && (
          <Alert severity="error">Add Required Fields on Asset / Usage Form</Alert>
        )}
        {(!isEditMode || isValidAdminAccess() || isAuthor()) && (
          <>
            {isEditMode && <Button onClick={handleAddUsage}>Add New Usage</Button>}
            <LoadingButton
              loading={assetRequest.loading || usageRequest.loading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="secondary"
            >
              SAVE
            </LoadingButton>
          </>
        )}
      </>
    )
  }

  function isValidAdminAccess() {
    return hasAccess(access, 'admin')
  }

  function isAuthor() {
    return data.author === userData.email
  }
}

EditPage.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default EditPage
