import { isValidEmail, isValidDateFormat } from '../../utils/inputValidations'

function validateField(value, { isRequired, type, min, max, label, inputProps }) {
  if (!isRequired) {
    return true
  }
  const minimum = min || (inputProps?.min || 0)
  const maximum = max || (inputProps?.max || 1000)
  const step = inputProps?.step || value
  switch (type) {
    case 'email':
      return isValidEmail(value) || `${label} must be a valid email`
    case 'rating':
      return (value >= min && value <= max) || `${label} must be between ${min} and ${max}`
    case 'string':
      return (value?.trim() !== '') || `${label} is required`
    case 'date':
      return isValidDateFormat(value) || `${label} must be in DD-MM-YYYY format`
    case 'number':
      return minimum <= value && value <= maximum && value % step === 0 || `${label} should be ${minimum}-${maximum}${step !== value ? `, Steps ${step}` : ''}`
    default:
      return true
  }
}

export const validateForm = (model, data, setErrors) => {
  return new Promise((resolve, reject) => {
    const formErrors = {}

    Object.entries(model).forEach(([fieldName, fieldData]) => {
      const fieldValue = data[fieldName]
      const isValid = validateField(fieldValue, fieldData)
      if (isValid !== true) {
        formErrors[fieldName] = isValid
      }
    })

    const errorKeys = Object.keys(formErrors)
    if (errorKeys.length === 0) {
      setErrors({})
      resolve()
    } else {
      setErrors(formErrors)
      reject(errorKeys)
    }
  })
}
