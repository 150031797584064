import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Iconify from 'components/iconify'

// ----------------------------------------------------------------------

export default function SimpleToolbar({ numSelected, totalCount, title, icon, func }) {
  return (
    <Toolbar
      sx={{
        height: 80,
        display: 'flex',
        justifyContent: 'space-between',
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
          <Tooltip title={title}>
            <IconButton onClick={func}>
              <Iconify icon={icon} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Typography component="div" variant="subtitle1">
            Total Count: {totalCount}
          </Typography>
        </>
      )}
    </Toolbar>
  )
}

SimpleToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  func: PropTypes.func,
}
