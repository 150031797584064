import React from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { Button } from '@mui/material'

export default function ExcelToJsonConverter({ cta, setJsonResult, setLoading = () => {} }) {
  const handleFileChange = async (e) => {
    try {
      setLoading(true)
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false })

        const transformedData = jsonData.map((item) => {
          const transformedItem = {}
          Object.keys(item).forEach((key) => {
            const transformedKey = key.toLowerCase().replace(/\s+/g, '_')
            transformedItem[transformedKey] = item[key]
          })
          return transformedItem
        })

        setJsonResult(transformedData)
      }

      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error('Error reading file:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <input
        accept=".xlsx,.xls,.csv"
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span">
          {cta || 'Upload Excel or CSV File'}
        </Button>
      </label>
    </div>
  )
}

ExcelToJsonConverter.propTypes = {
  cta: PropTypes.string,
  setJsonResult: PropTypes.func,
  setLoading: PropTypes.func,
}
