import axios from 'axios'
import { endPoint } from 'config'
import { buildOptions, showAlertModal } from 'utils'
import { auth } from '../firebase'

export const loadRefreshToken = async () => {
  const config = {}
  // Wait for the Firebase authentication initialization process to complete
  await new Promise((resolve) => {
    // eslint-disable-next-line
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve()
    })
  })

  // get currentUser from firebase
  const user = auth.currentUser
  if (user) {
    const idTokenResult = await user.getIdTokenResult()
    const refreshToken = idTokenResult.token

    config.Authorization = refreshToken
  } else {
    console.error('Unauthorized, No user is currently logged in.')
  }
  return config
}

export async function makeAPIRequest(url, options = buildOptions(), loadToken = true) {
  try {
    const reqEndpoint = endPoint + url
    const response = await axios({
      url: reqEndpoint,
      data: options.data,
      method: options.method,
      headers: loadToken ? await loadRefreshToken() : {},
    })
    // if (options.method === 'POST' || options.method === 'PUT') {
    //   alert('Operation Successful')
    // }
    return response
  } catch (error) {
    if (error.response.status === 401) {
      showAlertModal({ message: 'Unauthorized Activity' })
    }
    const err = error?.response?.data
    if (!err?.success) {
      showAlertModal(err)
    }
    return err
  }
}

// Add a response Interceptor
axios.interceptors.response.use((res) => res.data)
