import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
// @mui
import { Box, List, ListItemText, Collapse, ListItemButton, Stack } from '@mui/material'
import { StyledNavItem, StyledNavItemIcon } from 'components/nav-section/styles'
import navConfig from 'layouts/dashboard/nav/config'
// ----------------------------------------------------------------------

const BORDER = '1px dotted #c3c3c3'

NavSection.propTypes = {
  data: PropTypes.array,
}

export default function NavSection({ data = [], ...other }) {
  // Separate items with and without categories
  const itemsWithCategory = data.filter((item) => item.category)
  const itemsWithoutCategory = data.filter((item) => !item.category)

  // Group items by category
  const categories = itemsWithCategory.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = []
    }
    acc[item.category].push(item)
    return acc
  }, {})

  return (
    <Box {...other}>
      <List sx={{ p: 2 }}>
        {itemsWithoutCategory.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
        {Object.keys(categories)
          .sort()
          .map((category) => (
            <NavCategory key={category} category={category} items={categories[category]} />
          ))}
      </List>
    </Box>
  )
}

// ----------------------------------------------------------------------

NavCategory.propTypes = {
  category: PropTypes.string,
  items: PropTypes.array,
}

function NavCategory({ category, items }) {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <Stack sx={{ border: BORDER, borderRadius: 1, my: 2 }}>
      <StyledNavItem onClick={handleToggle}>
        <ListItemButton sx={open ? { borderBottom: BORDER } : {}}>
          <ListItemText disableTypography primary={category} />
        </ListItemButton>
      </StyledNavItem>
      <Collapse in={open}>
        <List>
          {items.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        </List>
      </Collapse>
    </Stack>
  )
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
}

function NavItem({ item }) {
  const { title, path, identifier } = item
  const location = useLocation()
  const isActive = location.pathname === path

  const matchingItem = identifier && navConfig.find((item) => item.identifier === identifier)

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
          color: '#2065d1',
        },
      }}
    >
      <StyledNavItemIcon>{isActive ? matchingItem?.filledIcon : matchingItem?.outlinedIcon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  )
}
