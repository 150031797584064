import React, { useEffect, useState, useMemo } from 'react'
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import useAxios from 'hooks/useAxios'
import useHandleSelect from 'hooks/useHandleSelect'
import { LoadingButton } from '@mui/lab'
import { Helmet } from 'react-helmet-async'
import { USER_USAGE_LOGS } from 'utils/helmet'
import { LIST_USER_LOG } from 'services/url'
import { isValidEmail } from 'utils/inputValidations'
import { formatTimeSpend } from 'pages/UserLogs/utils'
import { hasAccess } from 'utils'
import useUser from 'hooks/useUser'
import InputAdornment from '@mui/material/InputAdornment'
import CustomInput from 'sections/common/CustomInput'
import { getUsersChoice } from 'services/user'

export default function UserLogPage({ access }) {
  const userData = useUser()
  const isAdmin = hasAccess(access, 'admin')
  const [data, setData] = useState([])
  const [query, setQuery] = useState(userData.email)
  const [isValid, setIsValid] = useState(null)
  const [emailChoice, setEmailChoice] = useState([])
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })

  const userLogRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  const columns = useMemo(() => [
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'time_spent', label: 'Time Spent', alignRight: false }
  ], [])

  const fetchAllUserLogs = () => {
    if (query !== '') {
      const getUserLogList = LIST_USER_LOG(paginator.rowsPerPage, paginator.page, query)
      userLogRequest.sendRequest(getUserLogList)
    }
  }

  useEffect(() => {
    if (isAdmin) {
      getUsersChoice().then(res => setEmailChoice(res.emails))
    }
  }, [])

  useEffect(() => {
    if (userLogRequest.data) {
      if (userLogRequest.data.results?.length > 0) {
        const formattedRes = userLogRequest.data.results.map(obj => ({...obj, time_spent: formatTimeSpend(obj.time_spent)}))
        setData(formattedRes)
      } else {
        setData([])
      }
      setPaginator(prevPaginator => ({ ...prevPaginator, totalCount: userLogRequest.data.count }))
    } else if (userLogRequest.error) {
      console.error('Something went wrong with User Log System')
    }
  }, [userLogRequest.response])

  useEffect(() => {
    fetchAllUserLogs()
  }, [paginator.page, paginator.rowsPerPage])

  const handleSearch = () => {
    fetchAllUserLogs()
    if (query.length === 0) setIsValid(null)
    setIsValid(query.length > 0)
  }

  const renderMainContent = () => {
    if (userLogRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length === 0) {
      return (
        <>
          <Typography variant="h6" textAlign="center" p={1}>
            No Results Found on DB
          </Typography>
          <Typography variant="body1" textAlign="center" p={5}>
            Update your search fields or Hit Reset Button
          </Typography>
        </>
      )
    }

    return (
      <TableView
        showEdit={false}
        data={data}
        columns={columns}
        selected={selected}
        handleSelect={handleSelect}
        paginator={paginator}
        setPaginator={setPaginator}
        handleEdit={() => {}}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{USER_USAGE_LOGS}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">User Usage Log</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={userLogRequest.loading}
              variant="contained"
              onClick={fetchAllUserLogs}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
          </Stack>
        </Stack>

        {
          isAdmin &&
          <Card sx={{ mb: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CustomInput
                  key={'email'}
                  name={'email'}
                  formValue={query}
                  label={'Select User'}
                  type={'string'}
                  isMultiSelect={false}
                  optionList={emailChoice.map(email => ({ label: email, value: email }))}
                  handleInputChange={(e) => setQuery(e.target.value)}
                />
              </Stack>
              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={handleSearch}
                  startIcon={<Iconify icon="eva:search-fill" />}
                >
                  Search
                </Button>
              </Stack>
            </Stack>
          </Card>
        }

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent()}
        </Card>
      </Container>
    </>
  )
}
