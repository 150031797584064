import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Stack,
  Accordion,
  FormHelperText,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import { ASSET_DECALARATION } from 'services/url'

const Acknowledgement = ({ access, open, close }) => {
  const { sendRequest, method, data, errors } = useAxios()
  const [usage, setUsage] = useState([])
  const [acknowledge, setAcknowledge] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    sendRequest(ASSET_DECALARATION())
  }, [])

  useEffect(() => {
    if (data) {
      if (method === 'PUT') {
        setLoading(false)
        alert('Declaration Submitted Successfully')
        return
      }
      setUsage(data)
      setAcknowledge(
        data.map((usage) => ({
          id: usage.id,
          agreed: !!usage.acknowledged_date,
          block: !!usage.acknowledged_date,
        }))
      )
    }
    if (errors) {
      setLoading(false)
      console.error(errors)
    }
  }, [data, errors])

  const handleCheckboxChange = (index) => (event) => {
    const updatedAcknowledge = [...acknowledge]
    updatedAcknowledge[index].agreed = event.target.checked
    setAcknowledge(updatedAcknowledge)
  }

  const handleAgreeClick = (index) => () => {
    setLoading(true)
    sendRequest(ASSET_DECALARATION(usage[index].id), true, 'PUT')
  }

  return (
    <DetailView
      access={access}
      open={open}
      onClose={close}
      title="Asset Declaration"
      body={
        <>
          {usage.map((data, index) => (
            <Paper key={data.id} elevation={3} sx={{ px: 2, mb: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<></>}>
                  <Typography variant="h6" textAlign="center">
                    {data.asset.asset_type.toUpperCase()} - {data.asset.model_name} - {data.asset.serial_number}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" textAlign="start">
                    I acknowledge that the company assets assigned to me are to be used solely for office purposes. I
                    agree to handle these assets with care and to return them in good working condition upon request or
                    at the end of my tenure. I understand that I am responsible for any damage or loss of these assets
                    due to negligence or misuse and may be liable for the cost of repair or replacement. By agreeing
                    below, I confirm my understanding and acceptance of these terms and conditions.
                  </Typography>
                  <Typography variant="body1" py={2}>
                    {data.known_issue && 'Known issues with this laptop are listed here:'} {data.known_issue} If you
                    identify any {data.known_issue && 'additional '} issues, please inform HR to have them recorded.
                    Note that this process is a one-time occurrence, and once acknowledged, the listed issues for the
                    asset will not be updated.
                  </Typography>
                  <Typography
                    variant="body1"
                    py={2}
                    sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                  >
                    I agree to follow as instructed
                    <Checkbox
                      disabled={acknowledge[index].block}
                      checked={acknowledge[index].agreed}
                      onChange={handleCheckboxChange(index)}
                    />
                  </Typography>
                  <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                    {acknowledge[index].block ? (
                      <Typography variant={'body2'} sx={{ mr: 2, fontWeight: 800, color: 'green' }}>
                        AGREED on {data.acknowledged_date}
                      </Typography>
                    ) : (
                      <>
                        <FormHelperText sx={{ mr: 2, color: 'red' }}>
                          Clicking on AGREE means you have read the above statement and agreed to follow as instructed.
                        </FormHelperText>
                        <LoadingButton
                          disabled={acknowledge[index].block || !acknowledge[index].agreed}
                          loading={loading}
                          variant="contained"
                          onClick={handleAgreeClick(index)}
                        >
                          AGREE
                        </LoadingButton>
                      </>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Paper>
          ))}
        </>
      }
      actions={<></>}
    />
  )
}

Acknowledgement.propTypes = {
  access: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

export default Acknowledgement
