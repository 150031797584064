import React from 'react'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Box,
  Chip,
  Typography,
} from '@mui/material'

const dateStyle = {
  height: '56px',
  borderRadius: '6px',
  padding: '2px 12px',
  border: '0.4px solid rgb(222, 222, 222) !important',
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const labelStyle = {
  marginBottom: '-8px',
  zIndex: 1,
  marginLeft: '10px',
  backgroundColor: 'white',
  width: 'fit-content',
  padding: '0px 2px',
  fontSize: '12px',
  color: '#555555',
}

const RenderTextField = ({ name, label, formValues, handleInputChange, errors, fields }) => {
  const multiSelect = fields?.multiSelect?.includes(name)
  const select = fields?.option[name]
  const booleanField = fields?.boolean?.includes(name)
  const disabledField = fields?.disable?.includes(name)
  const datePickerField = fields?.datePicker?.includes(name)
  const isRequired = fields?.required?.includes(name)
  const TYPE = select ? 'select' : datePickerField ? 'date' : 'text'
  const value = select ? formValues[name] || '' : formValues[name]

  return booleanField ? (
    <FormControlLabel
      control={<Checkbox checked={formValues[name]} onChange={handleInputChange} name={name} />}
      label={label}
    />
  ) : datePickerField ? (
    <Stack>
      <label style={labelStyle} htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        type={TYPE}
        disabled={disabledField}
        name={name}
        style={{
          ...dateStyle,
          border: errors[name] ? '0.8px solid red' : '0.4px solid rgb(222, 222, 222)',
        }}
        value={value}
        onChange={handleInputChange}
      />
      <Typography style={{ color: 'red', fontSize: '12px' }}>{errors[name]}</Typography>
    </Stack>
  ) : multiSelect ? (
    // NOTE: This will return array only while save convert to String
    // For here its handled in backend
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        required={isRequired}
        name={name}
        multiline
        multiple // Enable multiple selection
        value={value}
        onChange={handleInputChange}
        disabled={disabledField}
        error={errors ? !!errors[name] : false}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={select.find((item) => item.value === value)?.label} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {select &&
          select
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </Select>
      {errors && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  ) : (
    <TextField
      label={label}
      required={isRequired}
      name={name}
      type={TYPE}
      multiline={TYPE === 'text'}
      fullWidth
      disabled={disabledField}
      value={value}
      onChange={handleInputChange}
      margin="normal"
      error={errors ? !!errors[name] : false}
      helperText={errors ? errors[name] : ''}
      {...(select && { select: true })}
    >
      {Array.isArray(select) &&
        select
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
    </TextField>
  )
}

export default RenderTextField
