import { useState } from 'react'
import PropTypes from 'prop-types'

import Popover from '@mui/material/Popover'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'

import Label from 'components/label'
import Iconify from 'components/iconify'

// ----------------------------------------------------------------------

export default function Row({ selected, row, handleClick, handleEdit, columns, customHandler, basicTable, showEdit }) {
  const [open, setOpen] = useState(null)

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget)
  }

  const handleEditClose = (action = handleEdit) => {
    setOpen(null)
    action(row)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const renderCellContent = (key, value) => {
    let color = null
    if (typeof value === 'boolean') {
      color = value ? 'success' : 'error'
      return (
        <Label color={color}>
          {value ? 'YES' : 'NO'}
        </Label>
      )
    }
    if (key.includes('status')) {
      const val = value.toLowerCase()
      if (['approved', 'completed'].includes(val)) color = 'success'
      else if (['on-hold', 'rejected'].includes(val)) color = 'error'
      else color = 'info'
      return (
        <Label color={color}>
          {value.toUpperCase()}
        </Label>
      )
    }
    if (key.includes('priority')) {
      const color = value === 'low' ? 'success' : value === 'medium' ? 'warning' : 'error'
      return <Label color={color}>{value.toUpperCase()}</Label>
    }

    return value
  }

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        {
          !basicTable &&
          <TableCell padding="checkbox">
            <Checkbox disableRipple checked={selected} onChange={() => handleClick(row.id)} />
          </TableCell>
        }

        {columns.map(({id:key, align}) => (
          <TableCell key={row.id + key} align={align}>
            {renderCellContent(key, row[key])}
          </TableCell>
        ))}

        <TableCell align="right">
          {
            showEdit &&
            <IconButton onClick={handleOpenMenu}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          }
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 'fit-content' },
        }}
      >
        <MenuItem onClick={() => handleEditClose()}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {customHandler?.map((item, index) => (
          <MenuItem key={index} onClick={() => handleEditClose(item.action)} >
            <Iconify icon={item.icon} sx={{ mr: 2 }} />
            {item.label}
          </MenuItem>
        ))}

        <MenuItem onClick={handleCloseMenu} sx={{ color: 'error.main' }} disabled>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  )
}

Row.propTypes = {
  handleClick: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.any,
  handleEdit: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.string),
  customHandler: PropTypes.func,
}
