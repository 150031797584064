/* eslint-disable */
// component
import SvgColor from 'components/svg-color'
import { PAGE_IDS } from 'utils'

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const navConfig = [
  {
    title: 'Home',
    identifier: PAGE_IDS['HOME'],
    path: '/home',
    outlinedIcon: icon('ic_home_outlined'),
    filledIcon: icon('ic_home_filled'),
  },
  {
    title: 'Key Notes',
    identifier: PAGE_IDS['KeyNote'],
    path: '/keynote/ccms',
    outlinedIcon: icon('ic_key_note_outlined'),
    filledIcon: icon('ic_key_note_filled'),
    category: 'Basic',
  },
  {
    title: 'Support Hub',
    identifier: PAGE_IDS['Support Ticket'],
    path: '/support',
    outlinedIcon: icon('ic_support_outlined'),
    filledIcon: icon('ic_support_filled'),
    category: 'Basic',
  },
  {
    title: 'LMS',
    identifier: PAGE_IDS['Manage Leave'],
    path: '/leave-management-system',
    outlinedIcon: icon('ic_lms_outlined'),
    filledIcon: icon('ic_lms_filled'),
    category: 'Utilities',
  },
  {
    title: 'PMT',
    identifier: PAGE_IDS['PMT'],
    path: '/project-management-system',
    outlinedIcon: icon('ic_pmt_outlined'),
    filledIcon: icon('ic_pmt_filled'),
    category: 'Utilities',
  },
  {
    title: 'Reimbursement',
    identifier: PAGE_IDS['REIMBURSEMENT'],
    path: '/reimb-management-system',
    outlinedIcon: icon('ic_reimb_outlined'),
    filledIcon: icon('ic_reimb_filled'),
    category: 'Utilities',
  },
  {
    title: 'User Logs',
    identifier: PAGE_IDS['User Logs'],
    path: '/user/log',
    outlinedIcon: icon('ic_user_logs_outlined'),
    filledIcon: icon('ic_user_logs_filled'),
    category: 'Basic',
  },
  {
    title: 'user',
    identifier: PAGE_IDS['User'],
    path: '/users',
    outlinedIcon: icon('ic_user_outlined'),
    filledIcon: icon('ic_user_filled'),
    category: 'Admin',
  },
  {
    title: 'MS License',
    identifier: PAGE_IDS['MsLicense'],
    path: '/ms-license',
    outlinedIcon: icon('ic_ms_license_outlined'),
    filledIcon: icon('ic_ms_license_filled'),
    category: 'Admin',
  },
  {
    title: 'JDs',
    identifier: PAGE_IDS['JDs'],
    path: '/jd-requirement',
    outlinedIcon: icon('ic_requirements_outlined'),
    filledIcon: icon('ic_requirements_filled'),
    category: 'CCMS',
  },
  {
    title: 'consultants',
    identifier: PAGE_IDS['Consultant'],
    path: '/consultants',
    outlinedIcon: icon('ic_consultant_outlined'),
    filledIcon: icon('ic_consultant_filled'),
    category: 'CCMS',
  },
  {
    title: 'clients',
    identifier: PAGE_IDS['Client'],
    path: '/clients',
    outlinedIcon: icon('ic_client_outlined'),
    filledIcon: icon('ic_client_filled'),
    category: 'CCMS',
  },
  {
    title: 'Vendors',
    identifier: PAGE_IDS['Vendor'],
    path: '/vendors',
    outlinedIcon: icon('ic_contractor_outlined'),
    filledIcon: icon('ic_contractor_filled'),
    category: 'CCMS',
  },
  {
    title: 'Analytics',
    identifier: PAGE_IDS['Analytics'],
    path: '/analytic',
    outlinedIcon: icon('ic_analytics_outlined'),
    filledIcon: icon('ic_analytics_filled'),
    category: 'Admin',
  },
  {
    title: 'Asset Manager',
    identifier: PAGE_IDS['Asset Manager'],
    path: '/assets',
    outlinedIcon: icon('ic_assets_outlined'),
    filledIcon: icon('ic_assets_filled'),
    category: 'Utilities',
  },
]

export default navConfig
