import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Marquee from 'react-fast-marquee'
import { Card, Typography } from '@mui/material'
import { CONSULTANT_CORE_STRENGTH } from 'services/url'
import useAxios from 'hooks/useAxios'
import { redirectToConsultant } from 'utils/common'

CoreStrength.propTypes = {
  consultants: PropTypes.array,
}

export default function CoreStrength() {
  const { data: consultantData, sendRequest } = useAxios()

  useEffect(() => {
    sendRequest(CONSULTANT_CORE_STRENGTH)
  }, [])

  const colors = ['#007bff', '#28a745', '#ffc107', '#17a2b8', '#dc3545', '#06C7F2']

  return (
    <Marquee pauseOnHover loop={0}>
      {consultantData &&
        consultantData.value.length > 0 &&
        consultantData.value.map((consultant, index) => (
          <Card
            key={consultant.id}
            sx={{
              padding: 3,
              margin: 2,
              maxWidth: '500px',
              borderLeft: `5px solid ${colors[index % colors.length]}`,
              cursor: 'pointer',
            }}
            onClick={() => redirectToConsultant(consultant.full_name)}
          >
            <Typography variant="h6" component="h2">
              {consultant.full_name}
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {consultant.core_strength}
            </Typography>
          </Card>
        ))}
    </Marquee>
  )
}
