import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import useAxios from 'hooks/useAxios'
import { capitalizeEachWord } from 'utils/common'
import { GET_LATEST_CLIENT } from 'services/url'
import { Box, Card, CardContent, Divider, List, ListItem, Typography } from '@mui/material'

const LatestClient = () => {
const navigate = useNavigate()
const handleClientClick = (clientName) => {
  navigate(`/clients?client_name=${encodeURIComponent(clientName)}`)
}

    const clientRequest = useAxios()
    useEffect(() => {
        clientRequest.sendRequest(GET_LATEST_CLIENT)
      }, [])

    return (
      <>
        <Card variant="outlined" style={{ borderRadius: 5, maxHeight: '280px', overflowY: 'auto' }}>
        <Typography variant="body1" sx={{ textAlign: 'center', mt: 1, fontWeight: 800 }}>FEW IMP CLIENTS</Typography>
          <CardContent>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="subtitle2" color="textSecondary">
                Client name
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Sector
              </Typography>
            </Box>
            <Divider />
            <List>
              {clientRequest.data?.map((client, index) => (
                <ListItem key={index} divider>
                <Box display="flex" justifyContent="space-between" width="100%" onClick={() => handleClientClick(client.name)}>
                    <Typography variant="body1" color="textPrimary">
                      {capitalizeEachWord(client.name)}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      { capitalizeEachWord(client.sector)}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </>
    )
}

export default LatestClient
