import { useEffect, useState } from 'react'

import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'

const style = {
  p: 4,
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overflow: 'scroll',
  overflowY: 'hidden',
  maxHeight: '90%',
  overflowX: 'hidden',
}

export default function BlockUsageModal({ open }) {
  return (
    <div>
      <Modal
        aria-labelledby="base-modal-title"
        aria-describedby="base-modal-description"
        open={open}
        onClose={() => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="base-modal-title" variant="h6" component="h2">
              WE HAVE NOTICED YOU HAVE OPENED NEW TAB
            </Typography>
            <Typography
              id="base-modal-description"
              sx={{ mt: 2, mb: 5 }}
            >
              This is not Allowed, as your time logs are being tracked
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
