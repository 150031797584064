import { Paper, Card, Stack, Button } from "@mui/material"
import CustomInput from "sections/common/CustomInput"
import DetailView from "sections/common/DetailView"
import Iconify from 'components/iconify'
import { useEffect, useState } from "react"
import { getUsersChoice, delegateAccess } from "services/user"
import { LoadingButton } from "@mui/lab"
import { delegatedAccess } from "../../firebase/auth-service"

export default function DelegateAccess({open, onClose}) {
  const [loading, setLoading] = useState(false)
  const [emailChoice, setEmailChoice] = useState([])
  const [selectedEmail, setSelectedEmail] = useState('')

  useEffect(() => {
    setLoading(true)
    getUsersChoice().then(res => {
      setEmailChoice(res.emails)
      setLoading(false)
    })
  }, [])

  const handleChange = (e) => setSelectedEmail(e.target.value)
  const submit = async () => {
    setLoading(true)
    const response = await delegateAccess(selectedEmail)
    if (!response.success) {
      return
    }
    await delegatedAccess(response.data)
    alert(`You have logged In as ${selectedEmail}`)
    window.location.reload()
    setLoading(false)
  }

  return (
    <DetailView
      access={2}
      open={open}
      onClose={onClose}
      title="Delegate Access Form"
      body={
        <>
          <Card sx={{ px: 5 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2} gap={2}>
              <CustomInput
                key="email"
                name="email"
                formValue={selectedEmail}
                label="Email"
                type="string"
                isRequired={false}
                isDisabled={false}
                optionList={emailChoice.map(email => ({ label: email, value: email }))}
                isMultiSelect={false}
                handleInputChange={handleChange}
              />
              <LoadingButton
                loading={loading}
                sx={{ mx: 1 }}
                color="warning"
                variant="contained"
                onClick={submit}
                startIcon={<Iconify icon="formkit:submit" />}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Card>
        </>
      }
      actions={
        <></>
      }
    />
  )
}
