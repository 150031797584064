import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

export const createProject = (body) => makeAPIRequest('/project', buildOptions('POST', body), true)

export const updateProject = (projectId, body) =>
  makeAPIRequest(`/project/${projectId}`, buildOptions('PUT', body), true)

export const getProjectMembers = (projectId) =>
  makeAPIRequest(`/project/${projectId}/members`, buildOptions('GET'), true)

export const updateProjectMembers = (projectId, body) =>
  makeAPIRequest(`/project/${projectId}/members`, buildOptions('PUT', body), true)

export const getUsersForManager = () => makeAPIRequest('/timesheet/users', buildOptions('GET'), true)

export const postHandleTimeSheet = (body, query) =>
  makeAPIRequest(`/timesheet/handle-status?${query}`, buildOptions('POST', body), true)

export const getWeekDropDown = () => makeAPIRequest('/timesheet/weeks', buildOptions('GET'), true)
