import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Stack, Typography, Button, Card, ToggleButtonGroup, ToggleButton } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import useAxios from 'hooks/useAxios'
import useUser from 'hooks/useUser'
import { LIST_CONSULTANT, GET_JOB_TITLE, GET_CERTIFICATION, GET_CLIENT, GET_VENDOR } from 'services/url'
import DetailView from 'pages/Consultant/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { CATEGORY } from 'pages/Consultant/utils'
import FilterForm from 'pages/Consultant/FilterForm'
import { hasAccess } from 'utils'
import { LoadingButton } from '@mui/lab'
import { Helmet } from 'react-helmet-async'
import { CONSULTANT } from 'utils/helmet'
import useQueryParamsState from 'hooks/useQueryParamsState'
import { objectToQueryString } from 'utils/common'
import Submission from './Submission'
import PayRate from './PayRate'
import Evaluation from './Evaluation'

export default function ListPage({ access }) {
  const columns = useMemo(
    () => [
      { id: 'pipeline_no', label: 'PR', alignRight: false },
      { id: 'full_name', label: 'Full Name', alignRight: false },
      { id: 'job_title_name', label: 'Job Title', alignRight: false },
      { id: 'work_authorization', label: 'Work Auth', alignRight: false },
      { id: 'record_status', label: 'Status', alignRight: false },
      { id: 'is_signature', label: 'Signature', alignRight: false },
      { id: 'is_hotlist', label: 'HotList', alignRight: true },
      { id: 'city', label: 'City', alignRight: true },
      { id: 'state', label: 'State', alignRight: true },
    ],
    []
  )

  const userData = useUser()

  const [data, setData] = useState([])
  const [jobTitle, setJobTitle] = useState([])
  const [certification, setCertification] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })
  const [submissionId, setSubmissionId] = useState({})
  const [payRateId, setPayRateId] = useState({})
  const [evaluatorId, setEvaluatorId] = useState({})
  const [client, setClient] = useState([])
  const [vendor, setVendor] = useState([])

  const [search, setSearch] = useQueryParamsState({
    filter: '',
    general_search: '',
    job_title: '',
    work_authorization: '',
    state: '',
    certification: '',
  })

  const consultantRequest = useAxios()
  const jobTitleRequest = useAxios()
  const certificationRequest = useAxios()
  const clientRequest = useAxios()
  const vendorRequest = useAxios()

  const handleSelect = useHandleSelect(setSelected)

  const fetchListOfConsultant = () => {
    const getConsultantList = LIST_CONSULTANT(paginator.rowsPerPage, paginator.page, objectToQueryString(search))
    consultantRequest.sendRequest(getConsultantList)
  }

  const handleNewConsultant = useCallback(() => {
    setSelectedData({ personal_email: '' })
  }, [])

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
    setSubmissionId({})
    setPayRateId({})
    setEvaluatorId({})
  }, [])

  useEffect(() => {
    fetchListOfConsultant()
  }, [paginator.page, paginator.rowsPerPage, search.filter])

  useEffect(() => {
    if (consultantRequest.data) {
      setData(consultantRequest.data.results)
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: consultantRequest.data.count }))
    } else if (consultantRequest.error) {
      console.error('Something is not correct with the consultant request')
    }
  }, [consultantRequest.data, consultantRequest.error])

  useEffect(() => {
    if (certificationRequest.data) {
      setCertification(certificationRequest.data.map((cert) => ({ label: cert.name, value: cert.id })))
    } else if (certificationRequest.error) {
      console.error('Something wrong with certification List Loading')
    }
  }, [certificationRequest.data, certificationRequest.error])

  useEffect(() => {
    if (jobTitleRequest.data) {
      setJobTitle(jobTitleRequest.data.map((job) => ({ label: job.name, value: job.id })))
    } else if (jobTitleRequest.error) {
      console.error('Something wrong with Job Title Loading')
    }
  }, [jobTitleRequest.data, jobTitleRequest.error])

  useEffect(() => {
    if (clientRequest.data) {
      setClient(clientRequest.data.map((client) => ({ label: client.name, value: client.id })))
    } else if (clientRequest.error) {
      console.error(clientRequest.error)
    }
  }, [clientRequest.data, clientRequest.error])

  useEffect(() => {
    if (vendorRequest.data) {
      setVendor(vendorRequest.data.map((vendor) => ({ label: vendor.name, value: vendor.id })))
    } else if (vendorRequest.error) {
      console.error(vendorRequest.error)
    }
  }, [vendorRequest.data, vendorRequest.error])

  useEffect(() => {
    certificationRequest.sendRequest(GET_CERTIFICATION)
    jobTitleRequest.sendRequest(GET_JOB_TITLE)
    clientRequest.sendRequest(GET_CLIENT)
    vendorRequest.sendRequest(GET_VENDOR)
  }, [])

  const reloadPage = () => {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    window.location.replace(url)
  }

  const customHandler = [
    {
      label: 'Pay Rate',
      icon: 'mingcute:card-pay-fill',
      action: (data) => {
        setPayRateId(data)
      },
    },
    {
      label: 'Submission',
      icon: 'eva:checkmark-circle-2-outline',
      action: (data) => {
        setSubmissionId(data)
      },
    },
    {
      label: 'Evaluator',
      icon: 'carbon:rule-test',
      action: (data) => {
        setEvaluatorId(data)
      },
    },
  ]

  return (
    <>
      <Helmet>
        <title> {CONSULTANT} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h4" m={1}>
            Consultants
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={consultantRequest.loading}
              variant="contained"
              onClick={fetchListOfConsultant}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            {hasAccess(access, 'edit') && (
              <Button
                color="inherit"
                variant="contained"
                onClick={handleNewConsultant}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Consultant
              </Button>
            )}
          </Stack>
        </Stack>

        <Card
          sx={{
            m: 'auto',
            my: 4,
            width: 'fit-content',
            maxWidth: '100%',
            overflowX: 'auto',
            textWrap: 'nowrap',
          }}
        >
          <ToggleButtonGroup
            exclusive
            color="primary"
            value={search.filter}
            onChange={(event, value) => value && setSearch((prev) => ({ ...prev, filter: value }))}
          >
            {CATEGORY.map((option) => (
              <ToggleButton key={option} value={option}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Card>

        <Card sx={{ m: 'auto', my: 4, p: 2 }}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mx: 'auto' }}>
            <Typography variant={'h6'}>Filter Section</Typography>
            {!showFilter && (
              <Button onClick={() => setShowFilter(!showFilter)}>
                <Iconify
                  icon="icon-park-outline:drop-down-list"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </Button>
            )}
          </Stack>
          {showFilter && (
            <Stack sx={{ display: 'flex', flexDirection: 'column', mx: 'auto' }}>
              <FilterForm setQuery={setSearch} jobTitle={jobTitle} certification={certification} />
              <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 2 }}>
                <LoadingButton
                  sx={{ mr: 2 }}
                  loading={consultantRequest.loading}
                  variant="outlined"
                  onClick={reloadPage}
                >
                  Reset
                </LoadingButton>
                <LoadingButton loading={consultantRequest.loading} variant="contained" onClick={fetchListOfConsultant}>
                  Search
                </LoadingButton>
              </Stack>
            </Stack>
          )}
        </Card>

        <Card>
          <SimpleToolbar
            numSelected={selected.length}
            totalCount={paginator.totalCount}
            {...(hasAccess(access, 'admin')
              ? {
                  icon: 'mdi:approve',
                  title: 'Bulk Approve',
                  func: () => alert('not ready now'),
                }
              : {})}
          />
          {consultantRequest.loading ? (
            <Typography variant="h6" textAlign="center" p={5}>
              Loading...
            </Typography>
          ) : data.length > 0 ? (
            <TableView
              data={data}
              columns={columns}
              selected={selected}
              handleSelect={handleSelect}
              paginator={paginator}
              setPaginator={setPaginator}
              handleEdit={setSelectedData}
              customHandler={customHandler}
            />
          ) : (
            <>
              <Typography variant="h6" textAlign="center" p={1}>
                No Results Found on DB
              </Typography>
              <Typography variant="body1" textAlign="center" p={5}>
                Update your search fields or Hit Reset Button
              </Typography>
            </>
          )}
        </Card>

        <DetailView
          access={access}
          isSelfRecord={selectedData.author === userData.email}
          data={selectedData}
          jobTitle={jobTitle}
          certification={certification}
          fetchData={fetchListOfConsultant}
          close={handleCloseDetailPage}
        />
        {submissionId.id && (
          <Submission
            id={submissionId.id}
            access={access}
            data={data}
            jobTitle={jobTitle}
            client={client}
            vendor={vendor}
            fetchData={fetchListOfConsultant}
            close={handleCloseDetailPage}
          />
        )}
        {payRateId.id && (
          <PayRate
            id={payRateId.id}
            pipelineNo={payRateId.pipeline_no}
            access={access}
            data={data}
            vendor={vendor}
            fetchData={fetchListOfConsultant}
            close={handleCloseDetailPage}
          />
        )}
        {evaluatorId.id && (
          <Evaluation
            id={evaluatorId.id}
            pipelineNo={evaluatorId.pipeline_no}
            access={access}
            isOpen={data}
            jobTitle={jobTitle}
            fetchData={fetchListOfConsultant}
            close={handleCloseDetailPage}
          />
        )}
      </Container>
    </>
  )
}
